import { getSectionTitle } from '../../requestListsLib';
import React, { useCallback, useEffect } from 'react';
import {
  clearValidation,
  setActiveScreenType,
  setProductItemsContent,
  setProductItemsError,
} from '../../requestListAdministrationSlice';
import { useSelector } from 'react-redux';
import RequestListValidationResults from './RequestListValidationResults';
import { ActiveScreenType } from '../../../common/administrationItemsLib';
import ValidationSection from '../../../common/ValidationSection';
import { ReduxState, useAppDispatch } from '../../../../../store';

const RequestListValidationSection: React.FC = () => {
  const selectedRequestList = useSelector(
    (state: ReduxState) => state.requestListAdministration.selectedRequestList,
  );
  const productItemsErrors = useSelector(
    (state: ReduxState) => state.requestListAdministration.productItemsErrors,
  );

  const dispatch = useAppDispatch();

  /**
   * Content of the text area is set to error data if any
   */
  useEffect(() => {
    if (productItemsErrors.length > 0) {
      dispatch(setProductItemsContent(productItemsErrors));
      dispatch(setProductItemsError(''));
    }
  }, [dispatch, productItemsErrors]);

  const onExitEditMode = useCallback(() => {
    dispatch(clearValidation());
    dispatch(setActiveScreenType(ActiveScreenType.DETAIL));
  }, [dispatch]);

  if (selectedRequestList) {
    return (
      <ValidationSection title={getSectionTitle(selectedRequestList)} onClose={onExitEditMode}>
        <RequestListValidationResults />
      </ValidationSection>
    );
  } else {
    return null;
  }
};

export default RequestListValidationSection;
