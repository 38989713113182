import React, { useCallback, useMemo } from 'react';
import { COLORS, Label } from '@ydistri/ds';
import { ColumnsType } from 'antd/es/table';
import { SimpleTable } from '../../../../../../Statistics/components/SimpleTable';
import { styled } from 'styled-components';

interface SupplyInfoTooltipData {
  label: string;
  value: string;
  className?: string;
  coverage?: React.ReactNode;
}

const columnsAttributes: ColumnsType<SupplyInfoTooltipData> = [
  {
    dataIndex: 'label',
    key: 'label',
    width: '13rem',
    render: (value: string) => <Label>{value}</Label>,
  },
  {
    dataIndex: 'value',
    key: 'value',
    align: 'right',
  },
];

const TooltipTable = styled(SimpleTable<SupplyInfoTooltipData>)`
  .highlighted td {
    color: ${COLORS.PRIMARY};
  }
`;

interface AvailableSupplyTooltipProps {
  availableSupplyNow?: number;
  availableSupplyNowAfterMath?: number;
  openPurchaseOrders?: number;
  incoming?: number;
  outgoing?: number;
}

const AvailableSupplyTooltip: React.FC<AvailableSupplyTooltipProps> = ({
  availableSupplyNow,
  availableSupplyNowAfterMath,
  openPurchaseOrders,
  incoming,
  outgoing,
}: AvailableSupplyTooltipProps) => {
  const tooltipData = useMemo((): SupplyInfoTooltipData[] => {
    const result: SupplyInfoTooltipData[] = [];

    result.push({
      label: 'Available supply:',
      value: (availableSupplyNow ?? 0).toString(),
      className: 'highlighted',
    });

    if (openPurchaseOrders && openPurchaseOrders > 0) {
      result.push({
        label: 'Open purchase orders:',
        value: openPurchaseOrders.toString(),
      });
    }

    if (incoming && incoming > 0) {
      result.push({
        label: 'Incoming: ',
        value: incoming.toString(),
      });
    }

    if (outgoing && outgoing > 0) {
      result.push({
        label: 'Outgoing:',
        value: outgoing.toString(),
      });
    }

    result.push({
      label: 'Total:',
      value: (availableSupplyNowAfterMath ?? 0).toString(),
      className: 'highlighted',
    });

    return result;
  }, [availableSupplyNow, incoming, openPurchaseOrders, outgoing, availableSupplyNowAfterMath]);

  const classNameProvider = useCallback(
    (record: SupplyInfoTooltipData) => record.className ?? '',
    [],
  );

  return (
    <TooltipTable
      id="SupplyInfoTooltip"
      dataSource={tooltipData}
      columns={columnsAttributes}
      showHeader={false}
      rowClassName={classNameProvider}
    />
  );
};

export default AvailableSupplyTooltip;
