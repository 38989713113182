import {
  CategoryFieldConfigurationResponse,
  ConfigurationFieldType,
  TargetListResponse as RequestListResponse,
} from '@ydistri/api-sdk';
import {
  ValidatorMessage,
  ValidatorMessageSeverity,
  ValidatorResponse,
  ValidatorSection,
} from './validatorLib';
import { createDebugLog } from '../../../lib/utils/logging';
import { initialValidatorInfoCategories } from './validatorInfoLib';
import {
  CategoryTree,
  CategoryTreeNode,
} from '../../../components/global/CategoryTree/apiCategoryTree';
import { EntityListConfiguration } from '../ConfigurationCategories/SegmentPotentialDefinition/ConfigurationEntityLists/entityListLib';

const debugLog = createDebugLog('Validator', 'validateCategories');

enum Messages {
  NO_SOURCE,
  NO_TARGET,
}

const section = ValidatorSection.CATEGORIES;

type PartialMessage = Omit<ValidatorMessage, 'section'>;

const addMessage = (condition: boolean, messages: ValidatorMessage[], message: PartialMessage) => {
  if (!condition) return;
  messages.push({
    section,
    ...message,
  });
};

const MESSAGES = {
  noSource: {
    status: ValidatorMessageSeverity.ERROR,
    message:
      'There is no category that can be used as source (Standard + Forced redistribution settings are off).',
    key: Messages.NO_SOURCE,
  },
  noTarget: {
    status: ValidatorMessageSeverity.ERROR,
    message: 'There is no category that can be used as target.',
    key: Messages.NO_TARGET,
  },
} as const;

export const validateCategories = (
  categoryTree: CategoryTree,
  requestListConfig: RequestListResponse[],
  rootCategoryConfig: CategoryFieldConfigurationResponse[],
): ValidatorResponse<typeof section> => {
  const rsp: ValidatorResponse<typeof section> = {
    section,
    messages: [],
    info: { ...initialValidatorInfoCategories },
    overviewMessage: '',
  };

  if (requestListConfig.length > 0) {
    rsp.info.isRequestList = true;
  }
  rsp.info.totalExceptions = categoryTree[1]?.childExceptionCount ?? 0;

  const checkField = (fieldType: ConfigurationFieldType, value: string) => {
    if (fieldType === ConfigurationFieldType.PsEnableWholeSection && value === '1') {
      rsp.info.isStandard = true;
    } else if (fieldType === ConfigurationFieldType.PsUseForcedRedistribution && value === '1') {
      rsp.info.isForced = true;
    } else if (fieldType === ConfigurationFieldType.PtTargetStockoutsOnly && value === '1') {
      rsp.info.isStockout = true;
    } else if (fieldType === ConfigurationFieldType.PtMinLayerList && !!value && value !== '[]') {
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- we know the result of JSON.parse
      const hasMinLayer = (JSON.parse(value) as EntityListConfiguration[]).find(
        ml => ml.IsEnabled === 1,
      );
      if (hasMinLayer) {
        rsp.info.isMinLayer = true;
      }
    } else if (
      fieldType === ConfigurationFieldType.PtMonthsOfSupplyToGetByForecast &&
      parseInt(value) > 0
    ) {
      rsp.info.isForecast = true;
    } else if (fieldType === ConfigurationFieldType.PtForcingLevelTypeId && parseInt(value) > 100) {
      rsp.info.isForcedIncreased = true;
    }
  };

  rootCategoryConfig.forEach(c => checkField(c.fieldType, c.value));

  const rootNode = categoryTree[1];

  if (rootNode) {
    let exceptionCountToCheck = rsp.info.totalExceptions;
    const nodesToCheck: CategoryTreeNode[] = [rootNode];

    const checkNode = (node: CategoryTreeNode) => {
      if (exceptionCountToCheck === 0) return;
      debugLog(`checking node ${node.id} [ exceptions left = ${exceptionCountToCheck} ]`);
      node.exceptions.forEach(e => {
        exceptionCountToCheck--;
        checkField(e.fieldType, e.value);
      });
      if (node.children.length > 0) {
        node.children.forEach(c => {
          const n = categoryTree[c];
          if (n && (n.exceptions.length > 0 || n.childExceptionCount > 0)) nodesToCheck.push(n);
        });
      }
    };

    while (nodesToCheck.length > 0) {
      const n = nodesToCheck.shift();
      if (n) checkNode(n);
    }
  }

  const targetEntities: string[] = [];

  if (rsp.info.isForecast) targetEntities.push('forecasts');
  if (rsp.info.isMinLayer) targetEntities.push('minimum layers');
  if (rsp.info.isRequestList) targetEntities.push('request list');

  const hasSource = rsp.info.isStandard || rsp.info.isForced;
  const hasTarget = targetEntities.length > 0;

  addMessage(!hasSource, rsp.messages, MESSAGES.noSource);
  addMessage(!hasTarget, rsp.messages, MESSAGES.noTarget);

  const infoMessages: string[] = [];
  let baseMessage = '';
  if (hasSource && hasTarget) {
    if (rsp.info.isStandard) {
      baseMessage = `Standard${rsp.info.isForced ? ` and forced` : ''}`;
    } else if (rsp.info.isForced) {
      baseMessage = `Forced`;
    }

    const targetEntityString = targetEntities
      .map((item, index) => {
        if (index === targetEntities.length - 1 && index > 0) return ' and ' + item;
        if (index < targetEntities.length - 1 && index > 0) return ', ' + item;
        return item;
      })
      .join('');

    baseMessage += ` redistribution replenishing ${targetEntityString}.`;
    infoMessages.push(baseMessage);
  }

  if (rsp.info.isForcedIncreased) {
    infoMessages.push('Forced redistribution is increased on target.');
  }

  if (rsp.info.totalExceptions > 0) {
    infoMessages.push(`There are ${rsp.info.totalExceptions} exceptions in total.`);
  } else {
    infoMessages.push('There are no exceptions on categories.');
  }

  rsp.overviewMessage = infoMessages.join(' ');

  return rsp;
};
