import { DetailSaleRowData, saleTypeDefault, saleTypeTuple, SkuResponseExt } from './salesTypes';
import { TParsedTransactions, Units } from '../charts/saleChartsTypes';
import { capitalizeFirstLetter } from '@ydistri/utils';
import { AttributeDefinitionType, SkuResponse } from '@ydistri/api-sdk';

export const parsedMonthlyTransactionsToTableData = (
  salesData: TParsedTransactions[],
  units: Units,
  addFullYears: boolean = true,
): DetailSaleRowData[] => {
  //map sales data to table column data
  const monthlyData: DetailSaleRowData[] = salesData.map(sales => {
    const salesForThisMonth = { ...saleTypeDefault };
    let saleMonthTotal = 0;

    saleTypeTuple.forEach(saleType => {
      const salesIndexName = capitalizeFirstLetter(saleType);
      const salesOfType = sales.data[salesIndexName];
      const saleOfThisType = units === Units.VALUE ? salesOfType?.value : salesOfType?.quantity;

      if (saleOfThisType) {
        salesForThisMonth[saleType] = saleOfThisType;
        saleMonthTotal += saleOfThisType;
      }
    });

    return {
      key: sales.dates.dateFrom.getTime(),
      isFullYear: false,
      month: sales.dates.dateFrom,
      monthString: sales.dates.stringFrom,
      ...salesForThisMonth,
      saleTotal: saleMonthTotal,
    };
  });

  //sort by month so new data are at the top
  monthlyData.sort((left, right) => right.month.getTime() - left.month.getTime());

  //if addFullYears is true, we need to group the data by year
  //AntDesign table can use tree structure to display data grouped data
  if (addFullYears) {
    const yearSummaries = new Map<number, DetailSaleRowData>();
    monthlyData.forEach(monthData => {
      const year = monthData.month.getFullYear();
      const yearSummary = yearSummaries.get(year);

      if (yearSummary) {
        yearSummary.saleTotal += monthData.saleTotal;
        saleTypeTuple.forEach(saleType => {
          yearSummary[saleType] += monthData[saleType];
        });
        if (yearSummary.children) {
          yearSummary.children.push(monthData);
        }
      } else {
        yearSummaries.set(year, {
          ...monthData,
          key: year.toString(),
          isFullYear: true,
          children: [monthData],
        });
      }
    });

    const result: DetailSaleRowData[] = [];
    yearSummaries.forEach(yearSummary => result.push(yearSummary));
    return result;
  }

  return monthlyData;
};

const getNumericSkuAttributeValue = (
  sku: SkuResponse,
  attributeType: AttributeDefinitionType,
): number | undefined => {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- sku.attributes can be null
  if (sku.attributes) {
    const attribute = sku.attributes.find(attribute => attribute.type === attributeType);
    if (attribute) {
      const result = parseInt(attribute.value);
      if (Number.isNaN(result)) {
        return undefined;
      } else {
        return result;
      }
    }
  } else {
    return undefined;
  }
};

/**
 * Extract data from the SKU Attributes and place them directly in the
 * SKU object for easier access.
 * @param sku array of SKU objects
 */
export const extendSkuResponse = (sku: SkuResponse[]): SkuResponseExt[] => {
  return sku.map(singleSku => {
    const frequency = getNumericSkuAttributeValue(
      singleSku,
      AttributeDefinitionType.Frequency6Month,
    );
    const saleValueTotal = getNumericSkuAttributeValue(
      singleSku,
      AttributeDefinitionType.SaleValueTotal,
    );
    const saleQuantityTotal = getNumericSkuAttributeValue(
      singleSku,
      AttributeDefinitionType.SaleQuantityTotal,
    );

    return { ...singleSku, frequency6Months: frequency, saleValueTotal, saleQuantityTotal };
  });
};
