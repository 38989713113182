import React, { useEffect } from 'react';

import RequestListAdministrationCatalog from './catalog/RequestListAdministrationCatalog';
import { Helmet } from 'react-helmet-async';
import RequestListAdministrationContent from './content/RequestListAdministrationContent';
import { useParams } from 'react-router';
import { defaultRequestListParams, useGetRequestListsQuery } from './apiRequestLists';
import { setSelectedRequestList } from './requestListAdministrationSlice';
import { setSubpage } from '../../../routes/routerSlice';
import { ProjectAdministrationSubpage } from '../projectAdministrationLib';
import { MainContentWrapper } from '@ydistri/ds';
import { useAppDispatch } from '../../../store';

const RequestListsAdministration: React.FC = () => {
  const { id } = useParams();

  const { data: requestLists } = useGetRequestListsQuery(defaultRequestListParams);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setSubpage(ProjectAdministrationSubpage.REQUESTLISTS));
  }, [dispatch]);

  useEffect(() => {
    if (id && requestLists) {
      const requestedTargetListId = parseInt(id);
      const requestedTargetList = requestLists.find(
        targetList => targetList.targetListId === requestedTargetListId,
      );
      if (requestedTargetList) {
        dispatch(setSelectedRequestList(requestedTargetList));
      }
    }
  }, [dispatch, id, requestLists]);

  return (
    <>
      <Helmet title="Request Lists Administration" />
      <MainContentWrapper>
        <RequestListAdministrationCatalog />
        <RequestListAdministrationContent />
      </MainContentWrapper>
    </>
  );
};

export default RequestListsAdministration;
