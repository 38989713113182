import React, { useCallback, useMemo } from 'react';
import { computeRemSize, Row, Text } from '@ydistri/ds';
import { rtkQueryPatchStatusToConfigurationFieldStatus } from '@ydistri/utils';
import { ConfigurationRuleScopeMode, ConfigurationRuleSetupType } from '@ydistri/api-sdk';
import { useSelector } from 'react-redux';
import { setModalChanged, updateScopeConfiguration } from './scopeConfigurationSlice';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useMainScope } from './useMainScope';
import { useScope } from './useScope';
import { ReduxState, useAppDispatch } from '../../../store';
import { ScopeConfigurationIconReplacement, ScopeEntity, ViewMode } from './scopeLib';
import { Checkbox, Popover } from 'antd';
import { ImCross } from 'react-icons/im';
import { AiOutlineCheck } from 'react-icons/ai';
import { transformToConfigurationRule, usePutConfigurationRuleMutation } from './apiScopes';
import { useTemplateOrCalculation } from '../../../hooks/useTemplateOrCalculation';
import ScopeConfigurationField from './ScopeConfigurationField';
import ScopeConfigurationFieldPopover from './ScopeConfigurationFieldPopover';
import { useCalculationConfiguration } from './useCalculationConfiguration';
import { ScopeErrorType } from './scopeLib2';
import ScopeConfigurationFieldRowWarning from './ScopeConfigurationFieldRowWarning';

const iconLeftMargin = { marginLeft: '0.8rem' };

interface ScopeConfigurationFieldRowProps {
  scopeId: number;
  viewMode: ViewMode;
  field: ConfigurationRuleSetupType;
  disabled?: boolean;
  title: string;
  iconReplacement?: ScopeConfigurationIconReplacement;
  iconReplacementTooltip?: string;
}

const isConfigurationRuleScopeModeAll = (scope: ScopeEntity | undefined) => {
  return scope?.selectionOption === ConfigurationRuleScopeMode.All;
};

const ScopeConfigurationFieldRow: React.FC<ScopeConfigurationFieldRowProps> = ({
  scopeId,
  viewMode,
  field,
  disabled,
  title,
  iconReplacement,
  iconReplacementTooltip,
}) => {
  const dispatch = useAppDispatch();
  const playgroundScope = useMainScope();
  const scopeById = useScope(scopeId);
  const templateOrCalculation = useTemplateOrCalculation();

  const [putConfigurationRuleMutation, putConfigurationStatus] = usePutConfigurationRuleMutation();
  const modalScope = useSelector((state: ReduxState) => state.scope.modalScope);
  const fieldIsOverwritten = useSelector((state: ReduxState) => {
    const configuration = state.scope.exceptionOverlapData.overlap[scopeId]?.configurations[field];
    if (configuration) {
      return configuration.array.length > 0;
    } else {
      return false;
    }
  });
  const scopeOverlapErrors = useSelector(
    (state: ReduxState) => state.scope.exceptionOverlapData.scopesWithErrors[scopeId],
  );

  const { useClosing, useSuperTarget } = useCalculationConfiguration();

  const status = useMemo(
    () => rtkQueryPatchStatusToConfigurationFieldStatus(putConfigurationStatus),
    [putConfigurationStatus],
  );

  const isNewExceptionModal =
    [ViewMode.NEW_EXCEPTION_MODAL].includes(viewMode) && scopeId === modalScope?.id;

  const scope = isNewExceptionModal ? modalScope : scopeById;

  const departmentsOnly =
    isConfigurationRuleScopeModeAll(scope?.entities.Regions) &&
    isConfigurationRuleScopeModeAll(scope?.entities.Stores) &&
    isConfigurationRuleScopeModeAll(scope?.entities.StoreTypes) &&
    !isConfigurationRuleScopeModeAll(scope?.entities.Departments);

  const departmentClosingSuperTargetWarning =
    ((field === ConfigurationRuleSetupType.IsClosing && useClosing) ||
      (field === ConfigurationRuleSetupType.IsSuperTarget && useSuperTarget)) &&
    departmentsOnly;

  const playgroundValue = playgroundScope?.configuration[field];
  const value = iconReplacement ? null : scope?.configuration[field];

  const canSetClosingOrSuperTargetType = useMemo(() => {
    if (
      useClosing &&
      useSuperTarget &&
      [ConfigurationRuleSetupType.IsClosing, ConfigurationRuleSetupType.IsSuperTarget].includes(
        field,
      )
    ) {
      const thisValue = value;
      let otherValue = null;
      if (field === ConfigurationRuleSetupType.IsClosing) {
        otherValue = scope?.configuration[ConfigurationRuleSetupType.IsSuperTarget];
      } else if (field === ConfigurationRuleSetupType.IsSuperTarget) {
        otherValue = scope?.configuration[ConfigurationRuleSetupType.IsClosing];
      }

      if (thisValue !== null && otherValue !== null) {
        return 'error';
      }

      if (thisValue === null && otherValue !== null) {
        return 'disabled';
      }

      return 'ok';
    }
    return null;
  }, [useClosing, useSuperTarget, field, value, scope?.configuration]);

  const onCheckboxChange = useCallback(
    (e: CheckboxChangeEvent) => {
      // finalValue will be used in case checkbox is checked
      let finalValue = playgroundValue;
      if (
        [ConfigurationRuleSetupType.IsClosing, ConfigurationRuleSetupType.IsSuperTarget].includes(
          field,
        )
      ) {
        //for closing / super target setting, we set it to true by default
        finalValue = true;
      }

      if (viewMode === ViewMode.EXCEPTION && scopeById) {
        const configurationRule = transformToConfigurationRule({
          ...scopeById,
          configuration: {
            ...scopeById.configuration,
            [field]: e.target.checked ? finalValue : null,
          },
        });

        putConfigurationRuleMutation({
          id: scopeId,
          templateId: templateOrCalculation.id,
          data: configurationRule,
        });
      } else {
        dispatch(
          updateScopeConfiguration({
            configuration: { [field]: e.target.checked ? finalValue : null },
          }),
        );
        dispatch(setModalChanged(true));
      }
    },
    [
      dispatch,
      field,
      playgroundValue,
      putConfigurationRuleMutation,
      scopeById,
      scopeId,
      templateOrCalculation.id,
      viewMode,
    ],
  );

  const onChangeSwitchHandler = useCallback(() => {
    if ([ViewMode.GENERAL_CONFIGURATION, ViewMode.EXCEPTION].includes(viewMode) && scopeById) {
      const configurationObject = {
        ...scopeById.configuration,
        [field]: !value,
      };

      const configurationRule = transformToConfigurationRule({
        ...scopeById,
        configuration: configurationObject,
      });

      putConfigurationRuleMutation({
        id: scopeId,
        templateId: templateOrCalculation.id,
        data: configurationRule,
      });
    } else {
      dispatch(updateScopeConfiguration({ configuration: { [field]: !value } }));
    }
  }, [
    dispatch,
    field,
    putConfigurationRuleMutation,
    scopeById,
    scopeId,
    templateOrCalculation.id,
    value,
    viewMode,
  ]);

  const onChangeInputHandler = useCallback(
    (newValue: string) => {
      if ([ViewMode.GENERAL_CONFIGURATION, ViewMode.EXCEPTION].includes(viewMode) && scopeById) {
        if (newValue === '') {
          newValue = '0';
        }
        const configurationRule = transformToConfigurationRule({
          ...scopeById,
          configuration: {
            ...scopeById.configuration,
            [field]: parseInt(newValue),
          },
        });

        putConfigurationRuleMutation({
          id: scopeId,
          templateId: templateOrCalculation.id,
          data: configurationRule,
        });
      } else {
        dispatch(updateScopeConfiguration({ configuration: { [field]: parseInt(newValue) } }));
      }
    },
    [
      dispatch,
      field,
      putConfigurationRuleMutation,
      scopeById,
      scopeId,
      templateOrCalculation.id,
      viewMode,
    ],
  );

  const disabledFinal = disabled ?? value === null;

  const warningType = useMemo(() => {
    if (departmentClosingSuperTargetWarning) return 'departmentClosingSuperTargetWarning';
    if (canSetClosingOrSuperTargetType === 'error') return 'closingAndSuperTargetAtOnce';
    if (
      field === ConfigurationRuleSetupType.IsSuperTarget &&
      (scopeOverlapErrors?.[ScopeErrorType.IS_CLOSING_OVERLAPS_SUPERTARGET] ?? []).length > 0
    )
      return ScopeErrorType.IS_CLOSING_OVERLAPS_SUPERTARGET;
    if (
      field === ConfigurationRuleSetupType.IsClosing &&
      (scopeOverlapErrors?.[ScopeErrorType.IS_SUPERTARGET_OVERLAPS_CLOSING] ?? []).length > 0
    )
      return ScopeErrorType.IS_SUPERTARGET_OVERLAPS_CLOSING;
    return null;
  }, [
    canSetClosingOrSuperTargetType,
    departmentClosingSuperTargetWarning,
    field,
    scopeOverlapErrors,
  ]);

  return (
    <Row $minHeight="2rem" $gap="0.5rem" $alignItems="center" $flexWrap="wrap">
      <Row $minWidth={computeRemSize(20)}>
        {viewMode !== ViewMode.GENERAL_CONFIGURATION && (
          <Checkbox
            onChange={onCheckboxChange}
            checked={value !== null}
            disabled={
              iconReplacement !== undefined ||
              templateOrCalculation.type === 'Calculation' ||
              canSetClosingOrSuperTargetType === 'disabled' ||
              (value === null && departmentClosingSuperTargetWarning)
            }
          />
        )}
      </Row>
      <Row $minWidth={computeRemSize(220)} $alignItems="center">
        {warningType !== null && (
          <ScopeConfigurationFieldRowWarning
            scopeId={scopeId}
            value={value}
            warningType={warningType}
            title={title}
          />
        )}
        {warningType === null && (
          <Text $type={value !== null ? 'default' : 'light'} $size="large" $semiBold>
            {title}
          </Text>
        )}
      </Row>
      <Row $minWidth={computeRemSize(100)}>
        {iconReplacement ? (
          <Popover content={iconReplacementTooltip}>
            {iconReplacement === 'cross' && (
              <ImCross color="grey" size={computeRemSize(18)} style={iconLeftMargin} />
            )}
            {iconReplacement === 'check' && (
              <AiOutlineCheck color="green" size={computeRemSize(18)} style={iconLeftMargin} />
            )}
          </Popover>
        ) : (
          <ScopeConfigurationFieldPopover field={field} scopeId={scopeId}>
            <ScopeConfigurationField
              viewMode={viewMode}
              value={value}
              field={field}
              disabledFinal={disabledFinal}
              onChangeSwitchHandler={onChangeSwitchHandler}
              onChangeInputHandler={onChangeInputHandler}
              status={status}
              fieldIsOverwritten={fieldIsOverwritten}
            />
          </ScopeConfigurationFieldPopover>
        )}
      </Row>
    </Row>
  );
};

export default ScopeConfigurationFieldRow;
