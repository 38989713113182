import { InfiniteScrollTable, Panel, Text, YColumnsType } from '@ydistri/ds';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ProductResponse } from '@ydistri/api-sdk';
import { GetProductsPayload, useGetProductsQuery } from '../../../apiDetail';
import { styled } from 'styled-components';
import ProductTooltip from './ProductTooltip';

//This is to display the tooltip over the whole row's height
//The tooltip does not display with cell's default padding when the mouse is at
//the edge over the padding
const ProductName = styled.div`
  padding: 8px;
`;

const ProductsTable = styled(InfiniteScrollTable<ProductResponse, GetProductsPayload>)`
  .ant-table-row {
    cursor: pointer;
  }

  //see ProductName for explanation

  #products .ant-table-tbody > tr > td {
    padding: 0;
  }

  .selected {
    background-color: ${p => p.theme.item.selected.backgroundColor};
  }
`;

interface ProductsListProps {
  categoryId: number;
  searchQuery?: string;
  onProductSelected?: (product: ProductResponse) => void;
  selectedProductId?: number;
}

const defaultParams: Partial<GetProductsPayload> = {
  skip: 0,
  top: 50,
};

const ProductsList: React.FC<ProductsListProps> = ({
  categoryId,
  searchQuery = '',
  onProductSelected,
  selectedProductId,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [tableHeight, setTableHeight] = useState<number>(100);

  const [params, setParams] = useState<GetProductsPayload>(() => {
    const initialParams = {
      ...defaultParams,
      categoryId,
    };
    if (searchQuery.length > 0) {
      initialParams.search = searchQuery;
    }

    return initialParams;
  });

  const { data, isFetching } = useGetProductsQuery(params);

  const columns = useMemo((): YColumnsType<ProductResponse>[] => {
    return [
      {
        title: 'Name',
        dataIndex: 'name',
        apiColumnName: 'Name',
        render: (text: string, record) => {
          return (
            <ProductTooltip product={record}>
              <ProductName>
                <Text>{text}</Text>
              </ProductName>
            </ProductTooltip>
          );
        },
      },
    ];
  }, []);

  useEffect(() => {
    setParams(prevValue => {
      const newValue = {
        ...prevValue,
        ...defaultParams,
        categoryId,
      };

      if (searchQuery.length > 0) {
        newValue.search = searchQuery;
      } else {
        delete newValue.search;
      }

      return newValue;
    });
  }, [categoryId, searchQuery]);

  const resizeHandler = useCallback(() => {
    const TABLE_SHIFT: number = selectedProductId ? 100 : 20;
    setTableHeight((ref.current?.clientHeight ?? 60) - TABLE_SHIFT);
  }, [selectedProductId]);

  useEffect(() => {
    resizeHandler();
    window.addEventListener('resize', () => resizeHandler);

    return () => {
      window.removeEventListener('resize', () => resizeHandler);
    };
  }, [ref, resizeHandler]);

  const getRowKey = useCallback((row: ProductResponse) => row.id, []);

  const tableOnRow = useCallback(
    (record: ProductResponse) => {
      return {
        onClick: () => {
          if (onProductSelected) {
            onProductSelected(record);
          }
        },
      };
    },
    [onProductSelected],
  );

  const getRowClassName = useCallback(
    (record: ProductResponse) => {
      if (record.id === selectedProductId) {
        return 'selected';
      }

      return '';
    },
    [selectedProductId],
  );

  return (
    <Panel ref={ref} $noAutoHeight={true} $grow={1}>
      <ProductsTable
        id="products"
        height={`${tableHeight}px`}
        setParams={setParams}
        dataSource={data}
        loading={isFetching}
        columns={columns}
        rowKey={getRowKey}
        showHeader={false}
        onRow={tableOnRow}
        rowClassName={getRowClassName}
        searchValue={searchQuery}
      />
    </Panel>
  );
};

export default ProductsList;
