import React, { useEffect, useState } from 'react';
import { ForecastMonthlyOverviewResponse, SaleMonthlyOverviewResponse } from '@ydistri/api-sdk';
import { DEFAULT_SALE_MONTH_COUNT, DetailType } from '../../../../../../../Detail/detailTypes';
import { SkusCollection } from '../../../../../../../../swagger/collections';
import { NO_VALUE } from '../../../../../../../../lib/utils/utilsTypes';
import DetailSales from '../../../../../../../Detail/sections/DetailContentBodySection/components/DetailSales/DetailSales';
import { Text } from '@ydistri/ds';
import { SKU_ID_LABEL } from '../../../../../../../../components/global/_constants';

interface SkuSalePopoverChartProps {
  skuId: number;
}

const SkuSalePopoverChart: React.FC<SkuSalePopoverChartProps> = ({ skuId }) => {
  const [loadingSalesData, setLoadingSalesData] = useState(true);
  const [salesData, setSalesData] = useState<SaleMonthlyOverviewResponse[]>();
  const [forecastData, setForecastData] = useState<ForecastMonthlyOverviewResponse[]>([]);

  useEffect(() => {
    if (skuId === NO_VALUE) {
      return;
    }

    Promise.all([
      SkusCollection.getSkuMonthlySaleWithinMonthsRange(skuId, DEFAULT_SALE_MONTH_COUNT),
      SkusCollection.getSkuMonthlyForecast(skuId),
    ])
      .then(([{ data: salesData }, { data: forecastData }]) => {
        setSalesData(salesData.data);
        setForecastData(forecastData.data);
      })
      .catch(() => {
        //no data found, we must erase previous data so we do not show data from different sku
        setSalesData([]);
        setForecastData([]);
      })
      .finally(() => {
        // eslint-disable-next-line @ydistri/react/no-useless-setstate-in-effect -- needed
        setLoadingSalesData(false);
      });
  }, [skuId]);

  if (skuId !== NO_VALUE) {
    return (
      <>
        <DetailSales
          salesData={salesData}
          forecastData={forecastData}
          loadingSalesData={loadingSalesData}
          hasForecasts={true}
          skuChart={true}
          showAverageValueInChartTooltip={true}
          forcedDetailType={DetailType.SKU}
        />
        <Text $type="light">
          {SKU_ID_LABEL}: {skuId}
        </Text>
      </>
    );
  }
};

export default SkuSalePopoverChart;
