import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { setSubpage } from '../../../routes/routerSlice';
import { useParams } from 'react-router';
import { getDefaultEntityListsParams, useGetEntityListsQuery } from './apiEntityLists';
import {
  EntityListTypeSupported,
  pageTitleByEntityListType,
  projectAdministrationSubpageByEntityListType,
} from './entityListsLib';
import { setSelectedEntityList } from './entityListAdministrationSlice';
import EntityListAdministrationCatalog from './catalog/EntityListAdministrationCatalog';
import EntityListAdministrationContent from './content/EntityListAdministrationContent';
import { MainContentWrapper } from '@ydistri/ds';
import { useAppDispatch } from '../../../store';

interface EntityListsAdministrationProps {
  entityListType: EntityListTypeSupported;
}

const EntityListsAdministration: React.FC<EntityListsAdministrationProps> = ({
  entityListType,
}) => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { data: entityLists } = useGetEntityListsQuery(getDefaultEntityListsParams(entityListType));

  useEffect(() => {
    dispatch(setSubpage(projectAdministrationSubpageByEntityListType[entityListType]));
  }, [dispatch, entityListType]);

  useEffect(() => {
    if (id && entityLists) {
      const requestedEntityListId = parseInt(id);
      const requestedEntityList = entityLists.find(el => el.entityListId === requestedEntityListId);
      if (requestedEntityList) {
        dispatch(setSelectedEntityList({ entityListType, data: requestedEntityList }));
      }
    }
  }, [dispatch, id, entityLists, entityListType]);

  return (
    <>
      <Helmet title={pageTitleByEntityListType[entityListType]} />
      <MainContentWrapper>
        <EntityListAdministrationCatalog entityListType={entityListType} />
        <EntityListAdministrationContent entityListType={entityListType} />
      </MainContentWrapper>
    </>
  );
};

export default EntityListsAdministration;
