import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { GenericConfirmationModal, Section } from '@ydistri/ds';
import { ActiveScreenType } from '../../../common/administrationItemsLib';
import { useSelector } from 'react-redux';
import { addToast } from '@ydistri/utils';
import EntityListGrid from './EntityListGrid';
import { ReduxState, useAppDispatch } from '../../../../../store';
import { skipToken } from '@reduxjs/toolkit/query';
import { CalculationType } from '@ydistri/api-sdk';
import {
  EntityListUpdateRequest,
  getDefaultEntityListsParams,
  useDeleteEntityListMutation,
  useGetEntityListCalculationsQuery,
  useGetEntityListImportSummaryQuery,
  useGetEntityListsQuery,
  useUpdateEntityListMutation,
} from '../../apiEntityLists';
import {
  setActiveScreenType,
  setImportStep,
  setSelectedEntityList,
} from '../../entityListAdministrationSlice';
import {
  EntityListTypeSupported,
  getSectionTitle,
  IMPORT_STEPS,
  titleByEntityListType,
} from '../../entityListsLib';
import EntityListDetails from '../../components/EntityListDetails';
import CreateEntityListModal from '../../modals/CreateEntityListModal';
import AdministrationItemActions from './AdministrationItemActions';

interface EntityListItemsSectionProps {
  entityListType: EntityListTypeSupported;
}

const EntityListItemsSection: React.FC<EntityListItemsSectionProps> = ({ entityListType }) => {
  const dispatch = useAppDispatch();

  const activeScreenTypeForced = useSelector(
    (state: ReduxState) => state.entityListsAdministration[entityListType].activeScreenTypeForced,
  );
  const selectedEntityList = useSelector(
    (state: ReduxState) => state.entityListsAdministration[entityListType].selectedEntityList,
  );
  const showArchivedItems = useSelector(
    (state: ReduxState) => state.entityListsAdministration[entityListType].showArchivedItems,
  );
  const [isBusy, setIsBusy] = useState(false);
  const [showDeleteEntityListConfirmation, setShowDeleteEntityListConfirmation] = useState(false);
  const [deleteEntityList] = useDeleteEntityListMutation();
  const [updateEntityList] = useUpdateEntityListMutation();
  const [showEditModal, setShowEditModal] = useState(false);
  const { data: entityLists } = useGetEntityListsQuery(getDefaultEntityListsParams(entityListType));

  const { data: entityListCalculations = [] } = useGetEntityListCalculationsQuery(
    selectedEntityList?.entityListId ?? skipToken,
  );
  const { data: entityListImportSummary, isFetching: isFetchingSummary } =
    useGetEntityListImportSummaryQuery(selectedEntityList?.entityListId ?? skipToken);

  const totalItemCount =
    (selectedEntityList
      ? selectedEntityList.departmentCount +
        selectedEntityList.skuCount +
        selectedEntityList.productCount +
        selectedEntityList.storeCount +
        selectedEntityList.storeDepartmentCount
      : 0) > 0;

  useEffect(() => {
    if (!isFetchingSummary && !activeScreenTypeForced) {
      if (entityListImportSummary && entityListImportSummary.length > 0) {
        dispatch(setActiveScreenType({ entityListType, data: ActiveScreenType.EDIT }));
        const hasItems = entityListImportSummary.find(i => i.itemCount > 0);
        if (hasItems) {
          dispatch(setImportStep({ entityListType, data: IMPORT_STEPS.ITEM_VALIDATION }));
        } else {
          dispatch(setImportStep({ entityListType, data: IMPORT_STEPS.ITEM_IMPORT }));
        }
      }
    }
  }, [
    isFetchingSummary,
    entityListImportSummary,
    // summaryLoaded,
    dispatch,
    entityListType,
    totalItemCount,
    activeScreenTypeForced,
  ]);

  // eslint-disable-next-line @ydistri/react/no-primitive-usememo -- we do not want to iterate over the array on every render
  const hasTemplate = useMemo(
    () => entityListCalculations.find(c => c.type === CalculationType.Template) !== undefined,
    [entityListCalculations],
  );

  /**
   * Delete Product List action handler.
   * Display a confirmation modal.
   */
  const onEntityListDelete = useCallback(() => {
    setShowDeleteEntityListConfirmation(true);
  }, []);

  /**
   * Close the confirmation modal
   */
  const closeEntityListDeleteConfirm = useCallback(() => {
    setShowDeleteEntityListConfirmation(false);
  }, []);

  const onEntityListContentEdit = useCallback(() => {
    dispatch(setActiveScreenType({ entityListType, data: ActiveScreenType.EDIT }));
    dispatch(setImportStep({ entityListType, data: IMPORT_STEPS.ITEM_IMPORT }));
  }, [dispatch, entityListType]);

  const onEntityListArchiveToggle = useCallback(() => {
    if (!selectedEntityList) {
      return;
    }

    if (!isBusy) {
      setIsBusy(true);

      const request: EntityListUpdateRequest = {
        entityListId: selectedEntityList.entityListId,
        entityListType: entityListType,
        isArchived: !selectedEntityList.isArchived,
      };

      updateEntityList(request)
        .unwrap()
        .then(updatedEntityList => {
          if (!showArchivedItems) {
            dispatch(setSelectedEntityList({ entityListType, data: undefined }));
          } else {
            dispatch(setSelectedEntityList({ entityListType, data: updatedEntityList }));
          }

          if (updatedEntityList) {
            dispatch(
              addToast({
                message: `${titleByEntityListType[entityListType]} was ${
                  updatedEntityList.isArchived ? 'archived' : 'unarchived'
                }`,
              }),
            );
          }
        })
        // eslint-disable-next-line @typescript-eslint/use-unknown-in-catch-callback-variable -- we know it's an error
        .catch((error: Error) => {
          dispatch(
            addToast({
              isError: true,
              message: `${titleByEntityListType[entityListType]}'s archive status was not changed`,
              description: error.message,
            }),
          );
        })
        .finally(() => {
          setIsBusy(false);
        });
    }
  }, [selectedEntityList, isBusy, updateEntityList, showArchivedItems, dispatch, entityListType]);

  const onProductListEdit = useCallback(() => setShowEditModal(true), []);
  const onEditProductListModalClose = useCallback(() => setShowEditModal(false), []);

  const onEntityListDeleteConfirm = useCallback(() => {
    closeEntityListDeleteConfirm();

    if (selectedEntityList) {
      setIsBusy(true);
      if (!isBusy) {
        deleteEntityList({ entityListId: selectedEntityList.entityListId, entityListType })
          .unwrap()
          .then(() => {
            dispatch(setSelectedEntityList({ entityListType, data: undefined }));
          })
          // eslint-disable-next-line @typescript-eslint/use-unknown-in-catch-callback-variable -- we know it's an error
          .catch((error: Error) => {
            dispatch(
              addToast({
                isError: true,
                message: `${titleByEntityListType[entityListType]} was not deleted due to an error`,
                description: error.message,
              }),
            );
          })
          .finally(() => {
            setIsBusy(false);
          });
      }
    }
  }, [
    closeEntityListDeleteConfirm,
    deleteEntityList,
    dispatch,
    entityListType,
    isBusy,
    selectedEntityList,
  ]);

  const existingEntityListNames = useMemo(
    () =>
      entityLists
        ?.filter(el => el.entityListId !== selectedEntityList?.entityListId)
        .map(el => el.name ?? ''),
    [entityLists, selectedEntityList],
  );

  /**
   * Actions for the header of the section
   */
  const headerActions = useMemo(() => {
    return (
      <AdministrationItemActions
        entityListSourceType={selectedEntityList?.entityListSourceTypeId}
        itemName={titleByEntityListType[entityListType]}
        deletable={selectedEntityList?.isDeletable}
        archivable={!hasTemplate}
        archived={selectedEntityList?.isArchived}
        onDelete={onEntityListDelete}
        onToggleArchived={onEntityListArchiveToggle}
        onContentEdit={onEntityListContentEdit}
        onEdit={onProductListEdit}
        disabled={isBusy}
      />
    );
  }, [
    entityListType,
    selectedEntityList?.isDeletable,
    selectedEntityList?.isArchived,
    selectedEntityList?.entityListSourceTypeId,
    hasTemplate,
    onEntityListDelete,
    onEntityListArchiveToggle,
    onEntityListContentEdit,
    onProductListEdit,
    isBusy,
  ]);

  if (!selectedEntityList) {
    return null;
  } else {
    return (
      <>
        <Section header={getSectionTitle(selectedEntityList)} headerActions={headerActions}>
          <EntityListDetails entityList={selectedEntityList} />
          <EntityListGrid
            selectedEntityListId={selectedEntityList.entityListId}
            entityListType={entityListType}
          />
        </Section>
        {showDeleteEntityListConfirmation && (
          <GenericConfirmationModal
            data-testid="DeleteEntityListConfirmationDialog"
            title={`Delete ${titleByEntityListType[entityListType]}`}
            message={`${titleByEntityListType[entityListType]} '${
              selectedEntityList.name ?? '-no title-'
            }' will be deleted. Continue?`}
            onConfirmed={onEntityListDeleteConfirm}
            onCanceled={closeEntityListDeleteConfirm}
          />
        )}
        {showEditModal && (
          <CreateEntityListModal
            entityListType={entityListType}
            entityList={selectedEntityList}
            onClose={onEditProductListModalClose}
            restrictedNames={existingEntityListNames}
          />
        )}
      </>
    );
  }
};

export default EntityListItemsSection;
