import React, { useMemo, useState } from 'react';
import StatisticsRequestListsIcon from '../../../../assets/statistics-request-lists.svg';
import StatisticsSection from './components/StatisticsSection';
import { Column, Row, Text, Title } from '@ydistri/ds';
import { Popover, Statistic } from 'antd';
import {
  CalculationStatisticRequestListResponseWithKey,
  useGetRequestListItemsQuery,
} from './apiCalculationRequestListStatistics';
import { useTemplateOrCalculation } from '../../../hooks/useTemplateOrCalculation';
import {
  computePercentage,
  formatMonetaryValue,
  formatNumber,
  shortenNumber,
} from '@ydistri/utils';
import { styled } from 'styled-components';
import TableRequestListItems from './TableRequestListItems';
import TableRequestListItemSources from './TableRequestListItemSources';
import { useCurrency } from '../../../hooks/useCurrency';
import { computeProductStatistics } from './lib/calculationStatsLib';

export const WideStatistic = styled(Statistic)`
  min-width: 20rem;
`;
interface SectionRequestListProps {
  title?: string;
}

const SectionRequestList: React.FC<SectionRequestListProps> = ({ title }) => {
  const templateOrCalculation = useTemplateOrCalculation();
  const [selectedRequestListSku, setSelectedRequestListSku] =
    useState<CalculationStatisticRequestListResponseWithKey>();
  const currency = useCurrency();

  const { data, isFetching } = useGetRequestListItemsQuery(templateOrCalculation.id);

  const overallData = useMemo(() => {
    const base = {
      received: 0,
      requested: 0,
      percentage: 0,
      missing: 0,
      receivedValue: 0,
      requestedValue: 0,
      percentageValue: 0,
    };

    if (data) {
      data.forEach(item => {
        const tmpReceived = Math.min(item.receivedQuantity, item.requestedQuantity);
        base.received += tmpReceived;
        base.receivedValue += tmpReceived * item.stockPrice;
        base.requested += item.requestedQuantity;
        base.requestedValue += item.requestedQuantity * item.stockPrice;
        base.missing +=
          item.requestedQuantity - Math.min(item.receivedQuantity, item.requestedQuantity);
      });
    }

    base.percentage = base.requested > 0 ? Math.round((base.received / base.requested) * 100) : 0;
    base.percentageValue =
      base.requestedValue > 0 ? Math.round((base.receivedValue / base.requestedValue) * 100) : 0;

    return base;
  }, [data]);

  const productStats = useMemo(() => {
    if (data) {
      return computeProductStatistics(data);
    }
  }, [data]);

  const requestedQuantity = selectedRequestListSku?.requestedQuantity ?? 0;
  const receivedQuantity = selectedRequestListSku?.receivedQuantity ?? 0;

  const fullFilledPercentage =
    requestedQuantity > 0
      ? Math.min(Math.round((receivedQuantity / requestedQuantity) * 100), 100)
      : 0;

  const missingQuantity = Math.max(selectedRequestListSku?.quantityNotReceived ?? 0, 0);

  return (
    <Column $padding={1}>
      <StatisticsSection $width="100%" icon={StatisticsRequestListsIcon}>
        <Row $justifyContent="space-between" $gap="1rem">
          <Title>{title}</Title>
          <Row $gap="1rem">
            <WideStatistic
              groupSeparator=" "
              title="Received [MU]"
              value={`${formatNumber(overallData.received)} / ${formatNumber(
                overallData.requested,
              )} MU (${overallData.percentage}%)`}
              loading={isFetching}
            />
            <Popover
              content={`${formatMonetaryValue(
                currency,
                overallData.receivedValue,
                0,
              )} / ${formatMonetaryValue(currency, overallData.requestedValue, 0)}`}
            >
              <WideStatistic
                groupSeparator=" "
                title={`Received [${currency}]`}
                value={`${shortenNumber(overallData.receivedValue, 0)} / ${shortenNumber(
                  overallData.requestedValue,
                  0,
                )} (${overallData.percentageValue}%)`}
                loading={isFetching}
              />
            </Popover>
            {productStats && (
              <WideStatistic
                groupSeparator=" "
                title="Fullfiled products"
                value={`${formatNumber(productStats.fullyFilledProductsCount)} / ${formatNumber(
                  productStats.productsCount,
                )} (${computePercentage(
                  productStats.fullyFilledProductsCount,
                  productStats.productsCount,
                  0,
                )}%)`}
                loading={isFetching}
              />
            )}
          </Row>
        </Row>
      </StatisticsSection>
      <TableRequestListItems
        selectedRequestListSku={selectedRequestListSku}
        setSelectedRequestListSku={setSelectedRequestListSku}
      />
      {selectedRequestListSku && (
        <>
          <StatisticsSection $width="100%" icon={StatisticsRequestListsIcon}>
            <Row $justifyContent="space-between" $gap="1rem">
              <Row $gap="1rem">
                <Title>Product:</Title>
                <Text>
                  {selectedRequestListSku.product.name} ({selectedRequestListSku.product.customerId}
                  )
                </Text>
                <Title>Store:</Title>
                <Text>
                  {selectedRequestListSku.store.name} (
                  {selectedRequestListSku.store.customerStoreId})
                </Text>
              </Row>
              <Row $gap="1rem">
                <WideStatistic
                  groupSeparator=" "
                  title="Received quantity"
                  value={`${formatNumber(receivedQuantity)} / ${formatNumber(
                    requestedQuantity,
                  )} MU`}
                  loading={isFetching}
                />
                <WideStatistic
                  groupSeparator=" "
                  title="Fullfilled percentage"
                  value={fullFilledPercentage}
                  suffix="%"
                  loading={isFetching}
                />
                <WideStatistic
                  groupSeparator=" "
                  title="Missing quantity"
                  value={`${formatNumber(missingQuantity)} MU`}
                  loading={isFetching}
                />
              </Row>
            </Row>
          </StatisticsSection>
          <TableRequestListItemSources selectedRequestListSku={selectedRequestListSku} />
        </>
      )}
    </Column>
  );
};

export default SectionRequestList;
