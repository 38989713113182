import { apiSlice } from '../../apis/api';
import {
  CalculationWithOverviewResponse,
  Condition,
  EntityListSkuResponse,
  NonSaleDailyOverviewResponse,
  Operation,
  ProductResponse,
  SaleDailyOverviewResponse,
  SkuClassResponse,
  SkuResponse,
  SkuResponseCollectionResponse,
  SortDirection,
  WarehouseResponse,
} from '@ydistri/api-sdk';
import {
  forceRefetchForInfiniteScroll,
  InfiniteScrollParams,
  mergeForInfiniteScroll,
  serializeFullApiBasedQueryArgs,
} from '@ydistri/ds';
import {
  ProductsCollection,
  SkuClassesCollection,
  SkusCollection,
  StoresCollection,
} from '../../swagger/collections';
import { getTags } from '../../apis/apiLib';
import { createDebugLog } from '../../lib/utils/logging';
import { NO_VALUE } from '../../lib/utils/utilsTypes';
import { formatDate, subYears } from 'date-fns';

const debugLog = createDebugLog('Detail', 'api');

const { TAGS, TAGS_ARRAY } = getTags('detail');

export interface GetProductsPayload extends InfiniteScrollParams {
  categoryId: number;
  productId?: number;
}

export interface SkuClassesRequest {
  skuClassId?: number;
}

export interface SkuRequest {
  productId: number;
  storeId: number;
}

interface SkuDailyTransactionsRequest {
  skuId: number;
  monthsCount: number;
}

/**
 * Both getCalculationsAsSource and getCalculationsAsTarget
 * use the same conditions - only Production calculations created
 * in the last 12 months.
 */
const getSkuCalculationsConditions = () => {
  const conditionDate = subYears(new Date(), 1);

  return [
    { fieldName: 'Type', operation: Operation.Eq, value: 'Production' },
    {
      fieldName: 'Created',
      operation: Operation.Gt,
      value: formatDate(conditionDate, 'yyyy-MM-dd'),
    },
  ];
};

export const apiDetail = apiSlice.enhanceEndpoints({ addTagTypes: TAGS_ARRAY }).injectEndpoints({
  endpoints: builder => ({
    getProducts: builder.query<ProductResponse[], GetProductsPayload>({
      queryFn: async payload => {
        const { top, skip, search, categoryId, productId } = payload;

        const conditions = [
          { fieldName: 'AllCategoryIds', operation: Operation.Contains, value: categoryId },
        ];
        if (productId) {
          conditions.push({ fieldName: 'Id', operation: Operation.Eq, value: productId });
        }

        debugLog('getProducts', { top, skip, search, categoryId, productId });
        const { data } = await ProductsCollection.productsList({
          top,
          skip,
          search,
          conditions: conditions,
          inlineCount: true,
          sortings: [{ fieldName: 'Name', direction: SortDirection.Asc }],
        });

        return data;
      },
      providesTags: result => {
        if (result) {
          return [
            ...result.map(({ id }) => ({ type: TAGS.products, id })),
            { type: TAGS.products, id: 'LIST' },
          ];
        } else {
          return [];
        }
      },
      serializeQueryArgs: serializeFullApiBasedQueryArgs<GetProductsPayload>(),
      merge: mergeForInfiniteScroll<ProductResponse, GetProductsPayload>(),
      forceRefetch: forceRefetchForInfiniteScroll<GetProductsPayload | undefined>(),
    }),
    getSingleProduct: builder.query<ProductResponse | undefined, number>({
      queryFn: async productId => {
        const conditions = [];
        if (productId !== -1) {
          conditions.push({ fieldName: 'Id', operation: Operation.Eq, value: productId });
        }

        if (conditions.length === 0) {
          return { data: undefined };
        }
        const { data } = await ProductsCollection.productsList({
          conditions,
          inlineCount: true,
        });

        if (data.totalCount === 1) {
          return { data: data.data[0] };
        } else {
          return { data: undefined };
        }
      },
      providesTags: result => {
        if (result) {
          return [{ type: TAGS.products, id: result.id }];
        } else {
          return [];
        }
      },
    }),
    getSingleStore: builder.query<WarehouseResponse | undefined, number>({
      queryFn: async storeId => {
        // eslint-disable-next-line no-console -- debug
        console.log('GETTING SINGLE STORE...', storeId);
        if (storeId === NO_VALUE) {
          return { data: undefined };
        }
        const { data } = await StoresCollection.warehousesList({
          conditions: [{ fieldName: 'Id', operation: Operation.Eq, value: storeId }],
          inlineCount: true,
        });
        if (data.totalCount === 1) {
          return { data: data.data[0] };
        } else {
          return { data: undefined };
        }
      },
      providesTags: result => {
        if (result) {
          return [{ type: TAGS.stores, id: result.id }];
        } else {
          return [];
        }
      },
    }),
    getSkuClasses: builder.query<SkuClassResponse[], SkuClassesRequest | undefined>({
      queryFn: async payload => {
        const { skuClassId } = payload ?? {};

        if (skuClassId === NO_VALUE) {
          return { data: [] };
        }

        const conditions: Condition[] = [];
        if (skuClassId) {
          conditions.push({ fieldName: 'Id', operation: Operation.Eq, value: skuClassId });
        }

        const { data } = await SkuClassesCollection.skuClassesList({
          conditions,
        });

        return { data: data.data };
      },
      providesTags: (result, error, arg) => [
        { type: TAGS.skuClasses, id: arg?.skuClassId ?? 'LIST' },
      ],
    }),
    getSkuList: builder.query<SkuResponseCollectionResponse | undefined, SkuRequest>({
      queryFn: async payload => {
        const { productId, storeId } = payload;
        const conditions: Condition[] = [];
        if (productId !== -1) {
          conditions.push({ fieldName: 'ProductId', operation: Operation.Eq, value: productId });
        }

        if (storeId !== -1) {
          conditions.push({ fieldName: 'StoreId', operation: Operation.Eq, value: storeId });
        }

        if (conditions.length !== 0) {
          const { data } = await SkusCollection.skusList({ conditions, inlineCount: true });
          return { data };
        } else {
          return { data: undefined, message: 'SKU was not found' };
        }
      },
      providesTags: (result, error, arg) => [
        {
          type: TAGS.skus,
          id: `product-${arg.productId === -1 ? 'all' : arg.productId}_store-${
            arg.storeId === -1 ? 'all' : arg.storeId
          }`,
        },
      ],
    }),
    getSku: builder.query<SkuResponse | undefined, number>({
      queryFn: async payload => {
        const { data } = await SkusCollection.getSku(payload);
        return data;
      },
      providesTags: (result, error, arg) => [
        {
          type: TAGS.skus,
          id: arg,
        },
      ],
    }),
    getSkuEntityLists: builder.query<EntityListSkuResponse[] | undefined, number>({
      queryFn: async skuId => {
        const { data } = await SkusCollection.getSkuEntityLists(skuId);
        return { data: data.data };
      },
      providesTags: (result, error, arg) => [{ type: TAGS.skuEntityLists, id: arg }],
    }),
    getCalculationsAsSource: builder.query<CalculationWithOverviewResponse[], number>({
      queryFn: async skuId => {
        const conditions: Condition[] = getSkuCalculationsConditions();
        const { data } = await SkusCollection.getSkuCalculationsBySource(skuId, { conditions });
        return { data: data.data };
      },
      providesTags: (result, error, arg) => [{ type: TAGS.skuCalculationsSource, id: arg }],
    }),
    getCalculationsAsTarget: builder.query<CalculationWithOverviewResponse[], number>({
      queryFn: async skuId => {
        const conditions: Condition[] = getSkuCalculationsConditions();
        const { data } = await SkusCollection.getSkuCalculationsByTarget(skuId, { conditions });
        return { data: data.data };
      },
      providesTags: (result, error, arg) => [{ type: TAGS.skuCalculationsSource, id: arg }],
    }),
    getDailySales: builder.query<SaleDailyOverviewResponse[], SkuDailyTransactionsRequest>({
      queryFn: async args => {
        const response = await SkusCollection.getSkuDailySale(args.skuId, args.monthsCount);
        return { data: response.data.data };
      },
      providesTags: (result, error, arg) => {
        if (result) {
          return [{ type: TAGS.skuDailySales, id: `${arg.skuId}-${arg.monthsCount}` }];
        } else {
          return [];
        }
      },
    }),
    getDailyInbounds: builder.query<NonSaleDailyOverviewResponse[], SkuDailyTransactionsRequest>({
      queryFn: async args => {
        const response = await SkusCollection.getSkuDailyInboundsWithinMonthsRange(
          args.skuId,
          args.monthsCount,
        );
        return { data: response.data.data };
      },
      providesTags: (result, error, arg) => {
        if (result) {
          return [{ type: TAGS.skuDailyInbounds, id: `${arg.skuId}-${arg.monthsCount}` }];
        } else {
          return [];
        }
      },
    }),
    getDailyOutbounds: builder.query<NonSaleDailyOverviewResponse[], SkuDailyTransactionsRequest>({
      queryFn: async args => {
        const response = await SkusCollection.getSkuDailyOutboundsWithinMonthsRange(
          args.skuId,
          args.monthsCount,
        );
        return { data: response.data.data };
      },
      providesTags: (result, error, arg) => {
        if (result) {
          return [{ type: TAGS.skuDailyOutbounds, id: `${arg.skuId}-${arg.monthsCount}` }];
        } else {
          return [];
        }
      },
    }),
  }),
});

export const {
  useGetProductsQuery,
  useGetSingleProductQuery,
  useGetSingleStoreQuery,
  useGetSkuClassesQuery,
  useGetSkuListQuery,
  useGetSkuQuery,
  useGetSkuEntityListsQuery,
  useGetCalculationsAsSourceQuery,
  useGetCalculationsAsTargetQuery,
  useGetDailySalesQuery,
  useGetDailyInboundsQuery,
  useGetDailyOutboundsQuery,
} = apiDetail;
