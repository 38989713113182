import React from 'react';
// import { useTemplateOrCalculation } from '../../../../hooks/useTemplateOrCalculation';
// import { Popover } from 'antd';
import { styled } from 'styled-components';
// import { MdContentPasteSearch, MdQueryStats } from 'react-icons/md';
// import { AvailableModals, openModal } from '../../../Modals/modalSlice';
// import { computeRemSize } from '@ydistri/ds';
import { EntityListResponse } from '@ydistri/api-sdk';

const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

// const ClickableIconWrapper = styled.div`
//   cursor: pointer;
// `;

interface EntityListConfigurationInfoProps {
  entityList: EntityListResponse;
}

const EntityListConfigurationInfo: React.FC<EntityListConfigurationInfoProps> = (/*{
  entityList,
}*/) => {
  /*const templateOrCalculation = useTemplateOrCalculation();
  const dispatch = useAppDispatch();

  const openEntityListModal = useCallback(() => {
    dispatch(
      openModal({
        type: AvailableModals.ENTITY_LIST_CONTENTS,
        data: {
          entityList,
        },
      }),
    );
  }, [dispatch, entityList]);

  const typeIcon = useMemo(() => {
    if (entityList?.entityListSourceTypeId) {
      return null;
      /!*return (
        <EntityListSourceTypeIndicator size="small" type={entityList?.entityListSourceTypeId} />
      );*!/
    }
    return null;
  }, [entityList?.entityListSourceTypeId]);

  const icon: React.ReactNode = useMemo(() => {
    if (templateOrCalculation.type === 'Template') {
      return (
        <Popover content="Open contents of request list">
          <ClickableIconWrapper>
            <MdContentPasteSearch
              size={computeRemSize(20)}
              color="grey"
              onClick={openEntityListModal}
            />
          </ClickableIconWrapper>
        </Popover>
      );
    }
    return (
      <Popover content="Open statistics">
        <ClickableIconWrapper>
          <MdQueryStats size={computeRemSize(20)} color="grey" onClick={openEntityListModal} />
        </ClickableIconWrapper>
      </Popover>
    );
  }, [openEntityListModal, templateOrCalculation.type]);*/

  return (
    <InfoWrapper>{/*{`Item count: ${entityList.skuCount}`} {icon} {typeIcon}{' '}*/}</InfoWrapper>
  );
};

export default EntityListConfigurationInfo;
