import { CalculationsCollection, TemplatesCollection } from '../../swagger/collections';
import { signalrClient } from '../../signalr/client';
import { apiSlice, APITAGS, TemplateOrCalculation, wrongTemplateInHeaders } from '../../apis/api';
import { ApiOperationType, TargetListResponse as RequestListResponse } from '@ydistri/api-sdk';
import { SignalConfigurationChanged } from '../../signalr/signalrInterfaces';
import { apiRequestLists } from '../ProjectAdministration/RequestLists/apiRequestLists';
import { getTags } from '../../apis/apiLib';
import { ReduxState } from '../../store';

const { TAGS, TAGS_ARRAY } = getTags('requestListConfiguration');

interface RequestListConfigurationPayload {
  templateId: number;
  targetListId: number;
}

/**
 * Manages the target list configuration for the current setup.
 * There may be more target lists used in configuration but the UI only allows one to be selected.
 * The API is designed to support multiple target lists in the future.
 *
 * - getTargetListsForConfiguration: returns the list of target lists that are currently configured.
 * - removeTargetListForConfiguration: removes the target list from the configuration. Needs a targetListId that is currently configured.
 * - addTargetListForConfiguration: adds the target list in the configuration. Needs a targetListId that is not currently configured.
 * - replaceTargetListForConfiguration: encapsulates the remove and set operations in a single call to avoid
 * a visible change to "none" and then back to the new target list in the UI.
 */
export const apiRequestListConfiguration = apiSlice
  .enhanceEndpoints({
    addTagTypes: TAGS_ARRAY,
  })
  .injectEndpoints({
    endpoints: builder => ({
      getRequestListsForConfiguration: builder.query<RequestListResponse[], TemplateOrCalculation>({
        queryFn: async ({ type, id }) => {
          if (wrongTemplateInHeaders({ type, id })) return { data: [] };
          const { data } = await (type === 'Calculation' && id
            ? CalculationsCollection.getCalculationTargetLists(id)
            : TemplatesCollection.getTemplatesTargetLists(id));

          return data;
        },
        providesTags: [TAGS.availableRequestLists],
        async onCacheEntryAdded({ id }, api) {
          try {
            await api.cacheDataLoaded;

            const listener = (signal: SignalConfigurationChanged) => {
              if (id !== signal.templateId) return;

              if (signal.entityType === 'TargetListConfiguration') {
                // eslint-disable-next-line no-console -- we need to log this
                console.log('RequestListConfiguration signal', signal);
                api.updateCachedData(data => {
                  switch (signal.operationType) {
                    case ApiOperationType.Create: {
                      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- we know the type
                      const state = api.getState() as ReduxState;
                      const queryKeys = apiRequestLists.util.selectCachedArgsForQuery(
                        state,
                        'getRequestLists',
                      );

                      const response = [...data];
                      const newRequestList = queryKeys.find(e => {
                        const requestList = apiRequestLists.endpoints.getRequestLists
                          .select(e)(state)
                          .data?.find(x => x.targetListId === signal.entityId);

                        if (requestList) {
                          response.push(requestList);
                        }
                        return !!requestList;
                      });

                      if (!newRequestList) {
                        //we should have the cache current most of the time due to signalr subscription
                        //so this is just a fallback in case the cache is out of sync
                        //target list not found in our cache, invalidate the tag to force a reload
                        api.dispatch(
                          apiRequestLists.util.invalidateTags([
                            APITAGS.requestLists.requestListResponse,
                          ]),
                        );
                        api.dispatch(
                          apiRequestListConfiguration.util.invalidateTags([
                            TAGS.requestListConfiguration,
                          ]),
                        );
                      }

                      return response;
                    }
                    case ApiOperationType.Delete:
                      return data.filter(dataItem => dataItem.targetListId !== signal.entityId);
                    case ApiOperationType.Replace:
                    case ApiOperationType.Patch:
                    case ApiOperationType.Read:
                      break;
                  }
                });
              }
            };

            signalrClient.on('TemplateConfigurationChanged', listener);

            api.cacheEntryRemoved.then(() => {
              signalrClient.off('TemplateConfigurationChanged', listener);
            });
          } catch (error) {
            // eslint-disable-next-line no-console -- we want to log this
            console.error(error);
          }
        },
      }),
      removeRequestListForConfiguration: builder.mutation<number, RequestListConfigurationPayload>({
        queryFn: async ({ templateId, targetListId }) => {
          await TemplatesCollection.deleteTemplatesTargetList(templateId, targetListId);
          return { data: targetListId };
        },
        invalidatesTags: [TAGS.requestListConfiguration],
      }),
      addRequestListForConfiguration: builder.mutation<
        RequestListResponse,
        RequestListConfigurationPayload
      >({
        queryFn: async ({ templateId, targetListId }) => {
          const response = await TemplatesCollection.postTemplatesTargetList(
            templateId,
            targetListId,
            {},
          );
          return { data: response.data.data };
        },
        invalidatesTags: [TAGS.requestListConfiguration],
      }),
      replaceRequestListForConfiguration: builder.mutation<
        RequestListResponse,
        RequestListConfigurationPayload
      >({
        queryFn: async ({ templateId, targetListId }) => {
          const response = await TemplatesCollection.patchTemplatesTargetList(
            templateId,
            targetListId,
            {},
          );
          return { data: response.data.data };
        },
        invalidatesTags: [TAGS.requestListConfiguration],
      }),
    }),
  });

export const {
  useGetRequestListsForConfigurationQuery,
  useRemoveRequestListForConfigurationMutation,
  useAddRequestListForConfigurationMutation,
  useReplaceRequestListForConfigurationMutation,
} = apiRequestListConfiguration;
