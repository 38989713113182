import { defaultRequestListParams, useGetRequestListsQuery } from '../apiRequestLists';
import NoAdministrationItemSelected from '../../common/NoAdministrationItemSelected';
import React from 'react';

/**
 * Component for main part of the scrren when no Request List is selected.
 * Displays a message based on whether there are any Request Lists or not.
 * @constructor
 */
const NoRequestListSelected: React.FC = React.memo(() => {
  const { data: requestLists } = useGetRequestListsQuery(defaultRequestListParams);

  return (
    <NoAdministrationItemSelected
      message="No Request List selected"
      secondaryMessage={
        requestLists && requestLists.length > 0
          ? 'Click on a Request List from the list'
          : "Click on the 'Create new request list' button on the left to create a new Request List"
      }
    />
  );
});

NoRequestListSelected.displayName = 'NoRequestListSelected';
export default NoRequestListSelected;
