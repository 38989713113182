import { ProductParameterResponse, ProductResponse } from '@ydistri/api-sdk';
import React from 'react';
import { MdOutlineKeyboardDoubleArrowDown, MdOutlineKeyboardDoubleArrowUp } from 'react-icons/md';
import { computeRemSize } from '@ydistri/ds';

export interface PickingLineHalf {
  skuId?: number;
  showCurrentSupplyInfo: boolean;
  availableSupplyNow: number;
  availableSupplyNowAfterMath: number;
  finalSupply: number;
  thisRoute: number;
  otherRoutes: number;
  minLayer: number;
  incoming: number;
  outgoing: number;
  openPurchaseOrders: number;
}

export const emptyPickingLineHalf = {
  skuId: undefined,
  showCurrentSupplyInfo: false,
  availableSupplyNow: 0,
  availableSupplyNowAfterMath: 0,
  finalSupply: 0,
  thisRoute: 0,
  otherRoutes: 0,
  minLayer: 0,
  incoming: 0,
  outgoing: 0,
  openPurchaseOrders: 0,
};

export interface PickingLineProductGroupInfoRow {
  product: ProductResponse;
  parameters: ProductParameterResponse[];
  isOpenedPickingLine: boolean;
  isThisRoute: boolean;
  source: PickingLineHalf;
  target: PickingLineHalf;
}

export const getBonusIcon = (data: PickingLineHalf): React.ReactNode => {
  if (!data.skuId) return null;
  let bonusIcon: React.ReactNode = null;
  if (data.availableSupplyNowAfterMath > data.finalSupply) {
    bonusIcon = <MdOutlineKeyboardDoubleArrowDown size={computeRemSize(20)} />;
  } else if (data.availableSupplyNowAfterMath < data.finalSupply) {
    bonusIcon = <MdOutlineKeyboardDoubleArrowUp size={computeRemSize(20)} />;
  }
  return bonusIcon;
};
