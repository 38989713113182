import { GenericConfirmationModal, Section } from '@ydistri/ds';
import RequestListSkuGrid from './RequestListSkuGrid';
import React, { useCallback, useMemo, useState } from 'react';
import {
  RequestListSetArchiveRequest,
  useDeleteRequestListMutation,
  useSetArchivedMutation,
} from '../../apiRequestLists';
import { useSelector } from 'react-redux';
import { setActiveScreenType, setSelectedRequestList } from '../../requestListAdministrationSlice';
import { addToast } from '@ydistri/utils';
import { getSectionTitle } from '../../requestListsLib';
import AdministrationItemActions from '../../../common/AdministrationItemActions';
import RequestListDetails from '../../components/RequestListDetails';
import RequestListModal from '../../modals/RequestListModal';
import { ActiveScreenType } from '../../../common/administrationItemsLib';
import { ReduxState, useAppDispatch } from '../../../../../store';

const RequestListItemsSection: React.FC = () => {
  const dispatch = useAppDispatch();

  const selectedRequestList = useSelector(
    (state: ReduxState) => state.requestListAdministration.selectedRequestList,
  );
  const showArchivedItems = useSelector(
    (state: ReduxState) => state.requestListAdministration.showArchivedItems,
  );
  const [isBusy, setIsBusy] = useState(false);
  const [showDeleteRequestListConfirmation, setShowDeleteRequestListConfirmation] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [setArchived] = useSetArchivedMutation();
  const [deleteRequestList] = useDeleteRequestListMutation();

  /**
   * Delete Request List action handler.
   * Display a confirmation modal.
   */
  const onRequestListDelete = useCallback(() => {
    setShowDeleteRequestListConfirmation(true);
  }, []);

  /**
   * Close the confirmation modal
   */
  const closeRequestListDeleteConfirm = useCallback(() => {
    setShowDeleteRequestListConfirmation(false);
  }, []);

  /**
   * Delete request list action was confirmed, perform the delete action.
   */
  const onRequestListDeleteConfirm = useCallback(() => {
    closeRequestListDeleteConfirm();
    if (selectedRequestList) {
      setIsBusy(true);
      if (!isBusy) {
        deleteRequestList(selectedRequestList.targetListId)
          .unwrap()
          .then(() => {
            dispatch(setSelectedRequestList(undefined));
          })
          // eslint-disable-next-line @typescript-eslint/use-unknown-in-catch-callback-variable -- we want to use this type
          .catch((error: Error) => {
            dispatch(
              addToast({
                isError: true,
                message: 'Request List was not deleted due to an error',
                description: error.message,
              }),
            );
          })
          .finally(() => setIsBusy(false));
      }
    }
  }, [deleteRequestList, selectedRequestList, isBusy, dispatch, closeRequestListDeleteConfirm]);

  /**
   * Mark Request List as archived or unarchived
   */
  const onRequestListArchiveToggle = useCallback(() => {
    if (!selectedRequestList) {
      return null;
    }

    if (!isBusy) {
      setIsBusy(true);
      const request: RequestListSetArchiveRequest = {
        requestListId: selectedRequestList.targetListId,
        isArchived: !selectedRequestList.isArchived,
      };
      setArchived(request)
        .unwrap()
        .then(updatedRequestList => {
          //deselect archived request lists if archived product lsits are not displayed
          if (!showArchivedItems) {
            dispatch(setSelectedRequestList(undefined));
          } else {
            dispatch(setSelectedRequestList(updatedRequestList));
          }

          dispatch(
            addToast({
              message: `Request List was ${
                updatedRequestList.isArchived ? 'archived' : 'unarchived'
              }`,
            }),
          );
        })
        // eslint-disable-next-line @typescript-eslint/use-unknown-in-catch-callback-variable -- we want to use this type
        .catch((error: Error) => {
          dispatch(
            addToast({
              isError: true,
              message: `Request List's archive status was not changed ${error.message}`,
            }),
          );
        })
        .finally(() => {
          setIsBusy(false);
        });
    }
  }, [selectedRequestList, dispatch, isBusy, setArchived, showArchivedItems]);

  const onRequestListContentEdit = useCallback(() => {
    dispatch(setActiveScreenType(ActiveScreenType.EDIT));
  }, [dispatch]);

  const onRequestListEdit = useCallback(() => {
    setShowEditModal(true);
  }, []);

  const onEditRequestListModalClose = useCallback(() => {
    setShowEditModal(false);
  }, []);

  /**
   * Actions for the header of the section
   */
  const headerActions = useMemo(() => {
    let deleteTooltip: string | undefined;
    if (selectedRequestList?.isDeletable === false) {
      deleteTooltip =
        'Cannot delete the Request List because it is currently used in a configuration or calculation';
    }

    return (
      <AdministrationItemActions
        itemName="Request List"
        deletable={selectedRequestList?.isDeletable}
        archivable={selectedRequestList?.isArchivable}
        archived={selectedRequestList?.isArchived}
        onDelete={onRequestListDelete}
        onToggleArchived={onRequestListArchiveToggle}
        onEdit={onRequestListEdit}
        onContentEdit={onRequestListContentEdit}
        disabled={isBusy}
        deleteTooltip={deleteTooltip}
      />
    );
  }, [
    selectedRequestList?.isDeletable,
    selectedRequestList?.isArchivable,
    selectedRequestList?.isArchived,
    onRequestListDelete,
    onRequestListArchiveToggle,
    onRequestListEdit,
    onRequestListContentEdit,
    isBusy,
  ]);

  // render the content
  if (!selectedRequestList) {
    return null;
  } else {
    return (
      <>
        <Section header={getSectionTitle(selectedRequestList)} headerActions={headerActions}>
          <>
            <RequestListDetails requestList={selectedRequestList} />
            <RequestListSkuGrid selectedRequestListId={selectedRequestList.targetListId} />
          </>
        </Section>
        {showDeleteRequestListConfirmation && (
          <GenericConfirmationModal
            data-testid="DeleteRequestListConfirmationDialog"
            title="Delete Request List"
            message={`The Request List '${
              selectedRequestList.name ?? '-no title-'
            }' will be deleted. Continue?`}
            onConfirmed={onRequestListDeleteConfirm}
            onCanceled={closeRequestListDeleteConfirm}
          />
        )}
        {showEditModal && (
          <RequestListModal
            requestList={selectedRequestList}
            onClose={onEditRequestListModalClose}
          />
        )}
      </>
    );
  }
};

export default RequestListItemsSection;
