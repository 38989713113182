import React, { useMemo } from 'react';
import { useColumnWidth } from '../../../../../../../../hooks/useColumnWidth';
import {
  EntityColumns,
  EntityColumnsConfig,
  useProductTableColumnsProvider,
} from '../../../../../../../../hooks/useEntityTableColumnsProvider';
import {
  COLORS,
  Column,
  computeRemSize,
  OutlierPanelColorVariant,
  OutlierValue,
  ReadOnlyItem,
  Row,
  SectionHeaderIndicator,
  Text,
  YColumnsType,
} from '@ydistri/ds';
import { formatNumber } from '@ydistri/utils';
import { getBonusIcon, PickingLineProductGroupInfoRow } from './pickingLineProductGroupInfoLib';
import {
  SKU_ID_LABEL,
  SYSTEM_ID_LABEL,
} from '../../../../../../../../components/global/_constants';
import { Table } from 'antd';
import {
  ProductGroupResponse,
  ProductParameterResponse,
  ProductResponse,
  StoreResponse,
} from '@ydistri/api-sdk';
import AvailableSupplyTooltip from './AvailableSupplyTooltip';
import { InfoIconTooltip } from '../../../../../../../../components/icons/InfoIconTooltip';
import { FaRegArrowAltCircleRight } from 'react-icons/fa';
import SkuSalePopover from './SkuSalePopoverChart';

const emptyColumn = {
  title: ' ',
  dataIndex: [],
  width: '3rem',
  render: () => ' ',
};

interface PickingLineProductGroupTableProps {
  data: PickingLineProductGroupInfoRow[];
  loading?: boolean;
  selectedProductGroupId: number;
  productGroups: ProductGroupResponse[];
  product?: ProductResponse;
  sourceStore?: StoreResponse;
  targetStore?: StoreResponse;
}

const PickingLineProductGroupTable: React.FC<PickingLineProductGroupTableProps> = ({
  data,
  loading,
  selectedProductGroupId,
  productGroups,
  product,
  sourceStore,
  targetStore,
}) => {
  const { productIdColumnWidth } = useColumnWidth();
  const productColumnProvider = useProductTableColumnsProvider();
  const selectedProductGroup = productGroups.find(pg => pg.id === selectedProductGroupId);

  const columns = useMemo(() => {
    const productColumnsConfig: EntityColumnsConfig<PickingLineProductGroupInfoRow> = {
      [EntityColumns.ID]: {
        apiFilterable: false,
        width: productIdColumnWidth,
      },
      [EntityColumns.CODE]: {
        apiFilterable: false,
        width: productIdColumnWidth,
      },
      [EntityColumns.NAME]: {
        apiFilterable: false,
      },
    };
    const productColumns = productColumnProvider(productColumnsConfig);

    const result: YColumnsType<PickingLineProductGroupInfoRow>[] = [];
    const resultProductColumns: YColumnsType<PickingLineProductGroupInfoRow>[] = [
      {
        title: ' ',
        dataIndex: ['isOpenedPickingLine'],
        width: '3rem',
        render: (value: boolean) => {
          if (!value) return null;
          return <SectionHeaderIndicator />;
        },
      },
      ...productColumns.filter(c => c.key !== 'keyProductName'),
    ];

    if (selectedProductGroup) {
      selectedProductGroup.parameterTypes.forEach(pg => {
        resultProductColumns.push({
          key: `pg-${pg}`,
          title: pg,
          dataIndex: ['parameters'],
          width: '6rem',
          sorter: (a, b) => {
            const param1 = a.parameters.find(p => p.parameterTypeId === pg)?.parameterName;
            const param2 = b.parameters.find(p => p.parameterTypeId === pg)?.parameterName;
            return (param1 ?? '').localeCompare(param2 ?? '');
          },
          render: (value: ProductParameterResponse[]) => {
            const param = value.find(p => p.parameterTypeId === pg);
            if (!param) return null;

            return {
              props: {
                style: {
                  fontWeight: 'bold',
                  color: param.isPopular ? 'green' : undefined,
                  backgroundColor: param.isPopular ? COLORS.TABLE_HIGHLIGHT_GREEN : undefined,
                },
              },
              children: param.parameterName,
            };
          },
        });
      });
    }

    resultProductColumns.push(emptyColumn);

    result.push({
      title: product?.name ?? '',
      width: '30rem',
      children: resultProductColumns,
    });

    const specialRender =
      (st: 'source' | 'target', highlightZero: boolean = false, addPlus: boolean = false) =>
      (value: number, record: PickingLineProductGroupInfoRow) => ({
        props: {
          style: {
            color: value === 0 && highlightZero ? 'red' : undefined,
          },
        },
        children: record[st].skuId
          ? `${addPlus && value > 0 ? '+' : ''}${formatNumber(value, 0)}`
          : '-',
      });

    const storeRenderer = (store: StoreResponse | undefined) => {
      if (!store) return null;
      return (
        <Column>
          <Text>{store.name}</Text>
          <Row $gap="0.5rem" $justifyContent="space-around">
            <ReadOnlyItem label="Id">
              <Text $type="light">{store.customerStoreId}</Text>
            </ReadOnlyItem>
            <ReadOnlyItem label={SYSTEM_ID_LABEL}>
              <Text $type="light">{store.id}</Text>
            </ReadOnlyItem>
          </Row>
        </Column>
      );
    };

    const addSourceOrTargetColumns = (st: 'source' | 'target') => {
      const newCols: YColumnsType<PickingLineProductGroupInfoRow> = {
        title: (st === 'source' ? storeRenderer(sourceStore) : storeRenderer(targetStore)) ?? '',
        children: [
          {
            key: `pickingLineHalf-${st}`,
            title: SKU_ID_LABEL,
            dataIndex: [st, 'skuId'],
            sorter: (a, b) => (a[st].skuId ?? 0) - (b[st].skuId ?? 0),
            sortDirections: ['descend', 'ascend'],
            width: '9rem',
            render: (value: number) =>
              value ? (
                <Row $gap="0.5rem" $justifyContent="center">
                  <Text>{formatNumber(value, 0, '')}</Text> <SkuSalePopover skuId={value} />
                </Row>
              ) : (
                ' -'
              ),
          },
          {
            title: 'Current Supply',
            dataIndex: [st, 'availableSupplyNowAfterMath'],
            align: 'center',
            sorter: (a, b) => a[st].availableSupplyNowAfterMath - b[st].availableSupplyNowAfterMath,
            sortDirections: ['descend', 'ascend'],
            width: '6rem',
            render: (value: number, record) => {
              if (!record[st].skuId) return '-';

              const tooltipContent: React.ReactNode = record[st].showCurrentSupplyInfo ? (
                <AvailableSupplyTooltip
                  availableSupplyNow={record[st].availableSupplyNow}
                  availableSupplyNowAfterMath={record[st].availableSupplyNowAfterMath}
                  openPurchaseOrders={record[st].openPurchaseOrders}
                  incoming={record[st].incoming}
                  outgoing={record[st].outgoing}
                />
              ) : null;

              const resultComponent = record[st].showCurrentSupplyInfo ? (
                <Row $gap="0.5rem" $justifyContent="center">
                  {formatNumber(value, 0)} <InfoIconTooltip content={tooltipContent} />
                </Row>
              ) : (
                formatNumber(value, 0)
              );

              return value === 0 ? (
                <OutlierValue
                  value={resultComponent}
                  center={true}
                  colorVariant={record[st].availableSupplyNowAfterMath <= 0 ? 'red' : 'orange'}
                />
              ) : (
                resultComponent
              );
            },
          },
          {
            title: 'This route',
            dataIndex: [st, 'thisRoute'],
            align: 'center',
            sorter: (a, b) => a[st].thisRoute - b[st].thisRoute,
            sortDirections: ['descend', 'ascend'],
            width: '6rem',
            render: specialRender(st, false, true),
          },
          {
            title: 'Other routes',
            dataIndex: [st, 'otherRoutes'],
            align: 'center',
            sorter: (a, b) => a[st].otherRoutes - b[st].otherRoutes,
            sortDirections: ['descend', 'ascend'],
            width: '6rem',
            render: specialRender(st, false, true),
          },
          {
            title: 'ML',
            dataIndex: [st, 'minLayer'],
            align: 'center',
            sorter: (a, b) => a[st].minLayer - b[st].minLayer,
            sortDirections: ['descend', 'ascend'],
            width: '6rem',
            render: (value: number, record) => {
              if (!record[st].skuId) return '-';
              return formatNumber(value, 0);
            },
          },
          {
            title: 'Final supply',
            dataIndex: [st, 'finalSupply'],
            align: 'center',
            sorter: (a, b) => a[st].finalSupply - b[st].finalSupply,
            sortDirections: ['descend', 'ascend'],
            width: '6rem',
            render: (value: number, record) => {
              const data = record[st];
              if (!data.skuId) return '-';

              let colorVariant: OutlierPanelColorVariant = 'red';
              if (data.minLayer <= data.finalSupply) {
                colorVariant = 'grey';
              }

              const resultComponent = formatNumber(value, 0);

              if (value === 0) {
                return (
                  <OutlierValue value={resultComponent} center={true} colorVariant={colorVariant} />
                );
              } else {
                return resultComponent;
              }
            },
          },
        ],
      };

      //render column with arrow right
      //to indicate that source sku is moved to target sku
      if (st === 'source' && newCols.children) {
        newCols.children.push({
          title: ' ',
          dataIndex: ['isThisRoute'],
          // width: '3rem',
          render: (value: boolean, record) => {
            return (
              <Row $gap="0.5rem" $justifyContent="space-between">
                <div>{getBonusIcon(record.source)}</div>
                <div>{value ? <FaRegArrowAltCircleRight size={computeRemSize(20)} /> : null}</div>
                <div>{getBonusIcon(record.target)}</div>
              </Row>
            );
          },
        });
      }

      result.push(newCols);
    };

    addSourceOrTargetColumns('source');
    addSourceOrTargetColumns('target');

    return result;
  }, [
    productColumnProvider,
    productIdColumnWidth,
    selectedProductGroup,
    product,
    sourceStore,
    targetStore,
  ]);

  return (
    <Table<PickingLineProductGroupInfoRow>
      loading={loading}
      columns={columns}
      dataSource={data}
      pagination={false}
      size="small"
      id="PickingLineProductGroupTable"
    />
  );
};

export default PickingLineProductGroupTable;
