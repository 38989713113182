import React, { useCallback, useMemo } from 'react';
import {
  AttributeDefinitionType,
  ForecastMonthlyOverviewResponse,
  SaleMonthlyOverviewResponse,
} from '@ydistri/api-sdk';
import { useApplicationDate } from '../../../../../../hooks/useApplicationDate';
import { TParsedTransactions, Units } from '../../../../../../lib/charts/saleChartsTypes';
import {
  addSalesUsedForForecastToSaleTypes,
  FORECAST_CONFIDENCES,
  getForecastTypes,
  parseTransactions,
  saleTypesDefault,
  saleUsedForForecast,
} from '../../../../../../lib/charts/saleChartsLib';
import DetailSalesChart from '../../../components/DetailSalesChart';
import { ActionsBar, Column, computeRemSize, Section } from '@ydistri/ds';
import { useCurrency } from '../../../../../../hooks/useCurrency';
import { useSelector } from 'react-redux';
import UnitSelector from '../UnitSelector/UnitSelector';
import ForecastConfidence from '../ForecastConfidence/ForecastConfidence';
import CumulativeForecastAction from './CumulativeForecastAction';
import useDetailScreenType from '../../../../hooks/useDetailScreenType';
import { DetailType } from '../../../../detailTypes';
import DetailSalesTableSection from './DetailSalesTableSection';
import LoadingDataOverlay from '../../../../../../components/global/LoadingDataOverlay/LoadingDataOverlay';
import { createDebugLog } from '../../../../../../lib/utils/logging';
import {
  setChartForecastConfidence,
  setChartShowCumulativeForecast,
  setChartUnit,
  setSkuChartUnit,
} from '../../../../detailSlice';
import OpenDailySalesAction from '../../../../../CalculationDetail/Redistribution/parts/listing/components/SkuDetailSection/actions/OpenDailySalesAction';
import { useAppDispatch } from '../../../../../../store';

const debugLog = createDebugLog('Detail', 'DetailSales');

interface DetailSalesProps {
  salesData?: SaleMonthlyOverviewResponse[];
  forecastData?: ForecastMonthlyOverviewResponse[];
  loadingSalesData?: boolean;
  hasForecasts?: boolean;
  activeForecastConfidence?: number[];
  onActiveForecastConfidenceChanged?: (forecastConfidence: number[]) => void;
  showAverageValueInChartTooltip?: boolean;
  children?: React.ReactNode;
  skuChart?: boolean;
  forcedDetailType?: DetailType;
}

const salesTableDetailTypesExclusions = [DetailType.PRODUCT, DetailType.SKU];

const DetailSales: React.FC<DetailSalesProps> = ({
  salesData,
  forecastData,
  hasForecasts,
  showAverageValueInChartTooltip,
  children = undefined,
  skuChart = false,
  forcedDetailType,
  loadingSalesData,
}) => {
  const dispatch = useAppDispatch();
  const currency = useCurrency();
  const applicationDate = useApplicationDate();

  const storeId = useSelector(state => state.detailReducer.storeId);
  const skuId = useSelector(state => state.detailReducer.skuId);
  const skuEntity = useSelector(state => state.detailReducer.skuEntity);
  const productId = useSelector(state => state.detailReducer.productId);
  const isLoadingSalesData =
    useSelector(state => state.detailReducer.isLoadingSalesData) || loadingSalesData;

  let detailType = useDetailScreenType(productId, storeId, skuId);
  detailType = forcedDetailType ?? detailType;
  const showSalesTable = !salesTableDetailTypesExclusions.includes(detailType);

  const dateToUse = useMemo(() => {
    if (applicationDate) {
      return new Date(applicationDate);
    } else {
      return new Date();
    }
  }, [applicationDate]);

  const chartSettings = useSelector(state => state.detailReducer.chartSettings);

  const saleTypes = useMemo(
    () =>
      (hasForecasts
        ? addSalesUsedForForecastToSaleTypes(saleUsedForForecast, saleTypesDefault)
        : [...saleTypesDefault]
      ).sort((a, b) => (a.priority > b.priority ? 1 : -1)),
    [hasForecasts],
  );

  const forecastTypes = useMemo(
    () =>
      getForecastTypes(chartSettings.forecastConfidence).sort((a, b) =>
        a.priority > b.priority ? 1 : -1,
      ),
    [chartSettings.forecastConfidence],
  );

  const parsedMonthlyTransactions: TParsedTransactions[] = useMemo(() => {
    if (salesData && ((hasForecasts && forecastData) || !hasForecasts)) {
      debugLog('useEffect: Starting to parse monthly transactions');
      const parsedMonthlyTransactions = parseTransactions({
        type: 'monthly',
        transactions: salesData
          .filter(s => new Date(s.monthInterval.dateFrom) < dateToUse)
          .concat(hasForecasts ? (forecastData ?? []) : []),
        lineTypes: saleTypes.concat(hasForecasts ? forecastTypes : []),
        dateToStartCalculatingCumulativeValues: dateToUse,
      });

      return parsedMonthlyTransactions;
    }
    return [];
  }, [salesData, forecastData, saleTypes, forecastTypes, dateToUse, hasForecasts]);

  const unitChangeHandler = useCallback(
    (newValue: Units) => {
      if (skuChart) {
        dispatch(setSkuChartUnit(newValue));
      } else {
        dispatch(setChartUnit(newValue));
      }
    },
    [dispatch, skuChart],
  );

  const cumulativeForecastChangeHandler = useCallback(
    (newValue: boolean) => {
      dispatch(setChartShowCumulativeForecast(newValue));
    },
    [dispatch],
  );

  const forecastConfidenceChangeHandler = useCallback(
    (newValues: number[]) => {
      dispatch(setChartForecastConfidence(newValues));
    },
    [dispatch],
  );

  const unit = skuChart ? chartSettings.skuChartUnit : chartSettings.unit;

  const headerActions = useMemo(() => {
    //need to transform to numbers, because - The type 'readonly [50, 75, 80]' is 'readonly' and cannot be assigned to the mutable type 'number[]'
    const forecastConfidences: number[] = [...FORECAST_CONFIDENCES];

    return (
      <ActionsBar>
        <UnitSelector
          key={skuChart ? 'SkuUnitSelector' : 'UnitSelector'}
          value={unit}
          onValueChange={unitChangeHandler}
          currency={currency}
        />
        {hasForecasts && (
          <>
            <ForecastConfidence
              values={chartSettings.forecastConfidence}
              options={forecastConfidences}
              onValuesChanged={forecastConfidenceChangeHandler}
            />
            <CumulativeForecastAction
              checked={chartSettings.showCumulativeForecast}
              onChanged={cumulativeForecastChangeHandler}
            />
          </>
        )}
        {skuId > 0 && <OpenDailySalesAction skuId={skuId} />}
      </ActionsBar>
    );
  }, [
    skuChart,
    unit,
    unitChangeHandler,
    currency,
    hasForecasts,
    chartSettings.forecastConfidence,
    chartSettings.showCumulativeForecast,
    forecastConfidenceChangeHandler,
    cumulativeForecastChangeHandler,
    skuId,
  ]);

  return (
    <Column $gap={computeRemSize(2)} $flexGrow={1}>
      <Section
        header={`Monthly sales in ${unit} [${unit === Units.VALUE ? currency : 'MU'}]`}
        headerActions={headerActions}
        $basis={30}
      >
        <LoadingDataOverlay active={isLoadingSalesData}>
          <DetailSalesChart
            monthlyData={parsedMonthlyTransactions}
            saleTypes={saleTypes}
            forecastTypes={forecastTypes}
            dateToUse={dateToUse}
            units={unit}
            setUnits={unitChangeHandler}
            height={380}
            hasForecasts={hasForecasts}
            showCumulativeForecast={chartSettings.showCumulativeForecast}
            showAverageValueInChartTooltip={showAverageValueInChartTooltip}
            vat={parseFloat(skuEntity?.values[AttributeDefinitionType.Vat] ?? '0')}
          />
        </LoadingDataOverlay>
      </Section>
      {showSalesTable && (
        <DetailSalesTableSection
          monthlyData={parsedMonthlyTransactions}
          units={unit}
          loadingSalesData={isLoadingSalesData}
        />
      )}
      {children}
    </Column>
  );
};

export default DetailSales;
