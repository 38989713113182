import React from 'react';
import { useSelector } from 'react-redux';
import { AvailableModals } from './modalSlice';
import { ReduxState } from '../../store';
import SetProductionModal from './SetProductionModal';
import UnsetProductionModal from './UnsetProductionModal';
import CategoryInsightsModal from './CategoryInsightsModal';
import CategoryExceptionsModal from './CategoryExceptionsModal/CategoryExceptionsModal';
import NewAppVersionModal from './NewAppVersionModal';
import RequestListContentsModal from './RequestListContentsModal';
import ReportErrorModal from './ReportErrorModal';
import MultiselectValidationModal from './MultiselectValidationModal';
import StoreOverviewModal from './StoreOverviewModal';
import ScopeEditModal from './ScopeEditModal';
import EntityListContentsModal from './EntityListContentsModal';
import EntityListConfigDeleteModal from './EntityListConfigDeleteModal';
import CalculationsActionModal from './CalculationsActionModal/CalculationsActionModal';
import StoreSaleInfoModal from './StoreSaleInfoModal';
import SkuDailySalesModal from './SkuDailySalesModal/SkuDailySalesModal';
import PickingLineProductGroupsModal from './PickingLineProductGroupsModal/PickingLineProductGroupsModal';

const ModalDisplay: React.FC = () => {
  const modalId = useSelector((state: ReduxState) => state.modal.openedModal);

  if (!modalId) return null;

  switch (modalId) {
    case AvailableModals.CATEGORY_INSIGHTS: {
      return <CategoryInsightsModal />;
    }
    case AvailableModals.CATEGORY_EXCEPTIONS: {
      return <CategoryExceptionsModal />;
    }
    case AvailableModals.REQUEST_LIST_CONTENTS: {
      return <RequestListContentsModal />;
    }
    case AvailableModals.ENTITY_LIST_CONTENTS: {
      return <EntityListContentsModal />;
    }
    case AvailableModals.ENTITY_LIST_CONFIG_DELETE: {
      return <EntityListConfigDeleteModal />;
    }
    case AvailableModals.NEW_APP_VERSION: {
      return <NewAppVersionModal />;
    }
    case AvailableModals.REPORT_ERROR: {
      return <ReportErrorModal />;
    }
    case AvailableModals.MULTISELECT_VALIDATION: {
      return <MultiselectValidationModal />;
    }
    case AvailableModals.SCOPE_EDIT: {
      return <ScopeEditModal />;
    }
    case AvailableModals.STORE_OVERVIEW: {
      return <StoreOverviewModal />;
    }
    case AvailableModals.SET_PRODUCTION: {
      return <SetProductionModal />;
    }
    case AvailableModals.UNSET_PRODUCTION: {
      return <UnsetProductionModal />;
    }
    case AvailableModals.MULTI_CALCULCATION_ACTIONS: {
      return <CalculationsActionModal />;
    }
    case AvailableModals.STORE_SALE_INFO: {
      return <StoreSaleInfoModal />;
    }
    case AvailableModals.SKU_DAILY_SALES: {
      return <SkuDailySalesModal />;
    }
    case AvailableModals.PICKING_LINE_PRODUCT_GROUPS: {
      return <PickingLineProductGroupsModal />;
    }
    default:
      return null;
  }
};

export default ModalDisplay;
