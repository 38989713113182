import React, { useCallback, useMemo } from 'react';
import { IconButton, IconButton_IconSizes, IconButtonSize } from '@ydistri/ds';
import { AvailableModals, ModalDataMap, openModal } from '../../../../../../../Modals/modalSlice';
import { useAppDispatch } from '../../../../../../../../store';
import { TbColorSwatch } from 'react-icons/tb';
import { useGetCalculationSkusQuery } from '../../../../../../../Calculations/apiCalculationsSkus';
import { useTemplateOrCalculation } from '../../../../../../../../hooks/useTemplateOrCalculation';

type PickingLineProductGroupsButtonProps =
  ModalDataMap[AvailableModals.PICKING_LINE_PRODUCT_GROUPS] & {
    size?: IconButtonSize;
  };

const PickingLineProductGroupsButton: React.FC<PickingLineProductGroupsButtonProps> = ({
  size = 'small',
  ...data
}) => {
  const dispatch = useAppDispatch();
  const openModalIcon = useMemo(() => <TbColorSwatch size={IconButton_IconSizes[size]} />, [size]);
  const calculationData = useTemplateOrCalculation();

  const { data: skuConfiguration } = useGetCalculationSkusQuery({
    skuId: data.sourceSkuId,
    calculationId: calculationData.id,
  });

  const productGroupCount = skuConfiguration?.productGroups.length ?? 0;

  const onClickHandler = useCallback(() => {
    dispatch(
      openModal({
        type: AvailableModals.PICKING_LINE_PRODUCT_GROUPS,
        data,
      }),
    );
  }, [dispatch, data]);

  if (productGroupCount === 0) return null;

  return (
    <IconButton
      size={size}
      tooltipPosition="topRight"
      tooltip="Open product groups"
      onClick={onClickHandler}
      icon={openModalIcon}
    />
  );
};

export default PickingLineProductGroupsButton;
