import React, { useMemo } from 'react';
import { ProductGroupResponse } from '@ydistri/api-sdk';
import ButtonOptions from '../../../../../../../../components/global/ButtonOptions/ButtonOptions';

interface ProductGroupSelectorProps {
  value: number;
  setValue: (value: number) => void;
  productGroups: ProductGroupResponse[];
}

const ProductGroupSelector: React.FC<ProductGroupSelectorProps> = ({
  value,
  setValue,
  productGroups,
}) => {
  const options = useMemo(() => {
    return productGroups.map(pg => ({
      label: pg.productGroupTypeId,
      value: pg.id,
    }));
  }, [productGroups]);

  return <ButtonOptions value={value} setValue={setValue} options={options} />;
};

export default ProductGroupSelector;
