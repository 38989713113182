import React, { useMemo } from 'react';
import { WideStatistic } from '../../../../Statistics/SectionRequestList';
import { Popover } from 'antd';
import { formatTimeDuration, FormatTimeDurationFormats } from '@ydistri/utils';

interface DurationStatisticsItemProps {
  title: string;
  value: number;
  tooltip?: string;
  format?: FormatTimeDurationFormats[];
}

const DurationStatisticsItem: React.FC<DurationStatisticsItemProps> = ({
  title,
  value,
  tooltip,
  format = ['hours', 'minutes'],
}) => {
  const body = useMemo(() => {
    return <WideStatistic title={title} value={formatTimeDuration(value, format)} />;
  }, [format, title, value]);

  if (tooltip) {
    return <Popover content={tooltip}>{body}</Popover>;
  } else {
    return body;
  }
};

export default DurationStatisticsItem;
