import React, { useCallback, useMemo, useState } from 'react';
import { ActionsBar, Section } from '@ydistri/ds';
import ShowArchivedItemsToggle from '../../common/ShowArchivedItemsToggle';
import { useSelector } from 'react-redux';
import {
  setSelectedRequestList,
  setShowArchivedItems,
  setSortField,
} from '../requestListAdministrationSlice';
import {
  getArchivedItemsCount,
  getArchivedStateSorter,
  lastUpdatedAtSorter,
  nameSorter,
  onlyActive,
} from '../../common/administrationItemsLib';
import { defaultRequestListParams, useGetRequestListsQuery } from '../apiRequestLists';
import { addToast } from '@ydistri/utils';
import RequestListsList from './RequestListsList';
import CreateIconButton from '../../../../components/buttons/CreateIconButton';
import RequestListModal from '../modals/RequestListModal';
import { ReduxState, useAppDispatch } from '../../../../store';
import SortSelector from '../../common/SortSelector';

const RequestListAdministrationCatalog: React.FC = () => {
  const dispatch = useAppDispatch();

  const showArchivedItems = useSelector(
    (state: ReduxState) => state.requestListAdministration.showArchivedItems,
  );
  const sortField = useSelector((state: ReduxState) => state.requestListAdministration.sortField);
  const selectedRequestList = useSelector(
    (state: ReduxState) => state.requestListAdministration.selectedRequestList,
  );
  const { data: requestLists, isFetching } = useGetRequestListsQuery(defaultRequestListParams, {
    refetchOnMountOrArgChange: false,
  });

  const [modalOpened, setModalOpened] = useState<boolean>(false);

  //Archived request lists are not displayed by default
  //If they are, they are listed after the active ones and sorted by name/recency
  const displayedData = useMemo(() => {
    if (requestLists) {
      const sorter = sortField === 'LastUpdatedAt' ? lastUpdatedAtSorter : nameSorter;
      let result;

      if (!showArchivedItems) {
        result = onlyActive(requestLists).sort(sorter);
      } else {
        result = Array.from(requestLists).sort(getArchivedStateSorter(sorter));
      }

      return result;
    }
  }, [sortField, requestLists, showArchivedItems]);

  // eslint-disable-next-line @ydistri/react/no-primitive-usememo -- we compute the value by processing the list, let's cache it
  const archivedItemsCount = useMemo(
    () => (!requestLists ? 0 : getArchivedItemsCount(requestLists)),
    [requestLists],
  );

  const onCreateRequestListClicked = useCallback(() => {
    setModalOpened(previousModalOpened => !previousModalOpened);
  }, []);

  /**
   * Shows or hides archived items
   */
  const toggleShowArchived = useCallback(() => {
    const newShowArchivedItems = !showArchivedItems;
    dispatch(setShowArchivedItems(newShowArchivedItems));
    if (newShowArchivedItems) {
      dispatch(
        addToast({
          message: `Showing archived Request lists`,
          description: 'Archived Request lists are shown below the active ones',
        }),
      );
    }

    if (selectedRequestList?.isArchived) {
      dispatch(setSelectedRequestList(undefined));
    }
  }, [dispatch, selectedRequestList, showArchivedItems]);

  const onCreateRequestListModalClose = useCallback(() => {
    setModalOpened(false);
  }, []);

  const onSortChange = useCallback(
    (v: string) => {
      dispatch(setSortField(v));
    },
    [dispatch],
  );

  const contentActions = useMemo(() => {
    return (
      <ActionsBar>
        <ShowArchivedItemsToggle
          archivedItemsCount={archivedItemsCount}
          checked={showArchivedItems}
          onClick={toggleShowArchived}
        />
        <SortSelector onSortChange={onSortChange} defaultValue={sortField} />
      </ActionsBar>
    );
  }, [archivedItemsCount, showArchivedItems, toggleShowArchived, onSortChange, sortField]);

  const headerActions = useMemo(() => {
    return (
      <ActionsBar>
        <CreateIconButton
          size="small"
          tooltip="Create Request List"
          onClick={onCreateRequestListClicked}
          data-testid="create_new_request_list"
        />
      </ActionsBar>
    );
  }, [onCreateRequestListClicked]);

  return (
    <>
      <Section
        header="Request lists"
        $scrollableContent={true}
        headerActions={headerActions}
        contentActions={contentActions}
        $ratio={0}
        $shrink={0}
        data-testid="RequestListsSection"
        $width="25rem"
      >
        <RequestListsList
          data={displayedData}
          selectedItem={selectedRequestList}
          loading={isFetching}
        />
      </Section>

      {modalOpened && (
        <RequestListModal onClose={onCreateRequestListModalClose} confirmButtonLabel="Create" />
      )}
    </>
  );
};

export default RequestListAdministrationCatalog;
