import { createBrowserRouter, createRoutesFromElements, Outlet, Route } from 'react-router';
import PageLayout from './PageLayout';
import ForgotPassword from '../screens/NotLoggedIn/ForgotPassword';
import ConfirmAccount from '../screens/NotLoggedIn/ConfirmAccount';
import ResetPassword from '../screens/NotLoggedIn/ResetPassword';
import SignIn from '../screens/NotLoggedIn/SignIn';
import PrivateRoute from './PrivateRoute';
import ProjectRoute from './ProjectRoute';
import Configuration from '../screens/Configuration/Configuration';
import Calculations from '../screens/Calculations/Calculations';
import CalculationDetail from '../screens/CalculationDetail/CalculationDetail';
import ProjectAdministration from '../screens/ProjectAdministration/ProjectAdministration';
import UserSettings from '../screens/UserSettings/UserSettings';
import ProductListsAdministration from '../screens/ProjectAdministration/ProductLists/ProductListsAdministration';
import Detail from '../screens/Detail/Detail';
import NotFound from './NotFound';
import ConfigurationCategories from '../screens/Configuration/ConfigurationCategories/ConfigurationCategories';
import ConfigurationRegions from '../screens/Configuration/ConfigurationRegions/ConfigurationRegions';
import ConfigurationSkuClasses from '../screens/Configuration/ConfigurationSkuClasses/ConfigurationSkuClasses';
import ConfigurationProductLists from '../screens/Configuration/ConfigurationProductLists/ConfigurationProductLists';
import ConfigurationBrands from '../screens/Configuration/ConfigurationBrands/ConfigurationBrands';
import ConfigurationDrillDown from '../screens/Configuration/ConfigurationDrillDown/ConfigurationDrillDown';
import RequestListsAdministration from '../screens/ProjectAdministration/RequestLists/RequestListsAdministration';
import { RoutingPage } from './routerSlice';
import { ProjectAdministrationSubpage } from '../screens/ProjectAdministration/projectAdministrationLib';
import CalculationRedistributionSubpage from '../screens/CalculationDetail/Redistribution/CalculationRedistributionSubpage';
import CalculationDetailSubpage from '../screens/CalculationDetail/CalculationDetailSubpage';
import CalculationStatistics from '../screens/CalculationDetail/Statistics/CalculationStatistics';
import ConfigurationStoresAndDepartments from '../screens/Configuration/ConfigurationStoresAndDepartments/ConfigurationStoresAndDepartments';
import CalculationEvaluation from '../screens/CalculationDetail/Evaluation/CalculationEvaluation';
import ConfigurationBalancing from '../screens/Configuration/ConfigurationBalancing/ConfigurationBalancing';
import CalculationRequestListStatistics from '../screens/CalculationDetail/Statistics/CalculationRequestListStatistics';
import EntityListsAdministration from '../screens/ProjectAdministration/EntityListAdministration/EntityListsAdministration';
import { EntityListTypeName } from '@ydistri/api-sdk';
import CalculationPickingResults from '../screens/CalculationDetail/PickingResults/CalculationPickingResults';

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route errorElement={<NotFound />}>
      <Route path="/account/forgot-pass/" element={<ForgotPassword />} />
      <Route path="/account/confirm/:token" element={<ConfirmAccount />} />
      <Route path="/account/reset/:token" element={<ResetPassword />} />
      <Route path="/login" element={<SignIn />} />
      <Route path="/" element={<PrivateRoute component={ProjectRoute} />}>
        <Route path="/:projectShortName" element={<PageLayout />} errorElement={<NotFound />}>
          <Route path="user-settings" element={<UserSettings />} />
          <Route path={RoutingPage.PROJECT_ADMINISTRATION} element={<ProjectAdministration />}>
            <Route
              path={`${ProjectAdministrationSubpage.MINLAYERLISTS}/:id?`}
              element={<EntityListsAdministration entityListType={EntityListTypeName.MinLayer} />}
            />
            <Route
              path={`${ProjectAdministrationSubpage.PRODUCTLISTS}/:id?`}
              element={<ProductListsAdministration />}
            />
            <Route
              path={`${ProjectAdministrationSubpage.REQUESTLISTS}/:id?`}
              element={<RequestListsAdministration />}
            />
            {/*<Route
              path={`${ProjectAdministrationSubpage.ENTITYLISTS}/:id?`}
              element={<EntityListsAdministration />}
            />*/}
          </Route>
          <Route path="detail/:slug?" element={<Detail />} />
          <Route path="calculation-detail" element={<CalculationDetail />}>
            <Route path=":calculationId" element={<Outlet />}>
              <Route path="redistribution/:slug?" element={<CalculationRedistributionSubpage />} />
              <Route path="statistics/:slug?" element={<CalculationStatistics />} />
              <Route
                path="request-list-statistics/:slug?"
                element={<CalculationRequestListStatistics />}
              />
              <Route path="categories/:slug?" element={<ConfigurationCategories />} />
              <Route path="regions/:slug?" element={<ConfigurationRegions />} />
              <Route path="stores/:slug?" element={<ConfigurationStoresAndDepartments />} />
              <Route path="skuclasses/:slug?" element={<ConfigurationSkuClasses />} />
              <Route path="productlists/:slug?" element={<ConfigurationProductLists />} />
              <Route path="brands/:slug?" element={<ConfigurationBrands />} />
              <Route path="advanced/:slug?" element={<ConfigurationBalancing />} />
              <Route path="drilldown/:slug?" element={<ConfigurationDrillDown />} />
              <Route path="evaluation/:slug?" element={<CalculationEvaluation />} />
              <Route
                path="picking-results/:pickingResultsPart/:slug?"
                element={<CalculationPickingResults />}
              />
              <Route path=":subpage" element={<CalculationDetailSubpage />}>
                <Route path=":slug" element={<Outlet />} />
              </Route>
            </Route>
          </Route>
          <Route path="configuration" element={<Configuration />}>
            <Route path=":templateId" element={<Outlet />}>
              <Route path="categories/:slug?" element={<ConfigurationCategories />} />
              <Route path="regions/:slug?" element={<ConfigurationRegions />} />
              <Route path="stores/:slug?" element={<ConfigurationStoresAndDepartments />} />
              <Route path="skuclasses/:slug?" element={<ConfigurationSkuClasses />} />
              <Route path="productlists/:slug?" element={<ConfigurationProductLists />} />
              <Route path="brands/:slug?" element={<ConfigurationBrands />} />
              <Route path="advanced/:slug?" element={<ConfigurationBalancing />} />
              <Route path="drilldown/:slug?" element={<ConfigurationDrillDown />} />
            </Route>
          </Route>
          <Route path="calculations" element={<Calculations />} />
          <Route path="" element={<Calculations />} />
        </Route>
      </Route>
    </Route>,
  ),
);
