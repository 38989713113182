import React, { useCallback } from 'react';
import { IdentificationType } from '@ydistri/api-sdk';
import { useSelector } from 'react-redux';
import { setProductItemsContent } from '../../requestListAdministrationSlice';
import { getParseableStringFromPastedData, selectRequestListParser } from '../../requestListsLib';
import ListItemsTextArea from '../../../common/edit/ListItemsTextArea';
import { RequestListInputData } from '../../requestListsTypes';
import { AdministrationItemRow } from '../../../common/administrationItemsTypes';
import { ReduxState, useAppDispatch } from '../../../../../store';

interface RequestListItemsTextAreaProps {
  id?: string;
  label?: string;
}

const RequestListItemsTextArea: React.FC<RequestListItemsTextAreaProps> = ({ label, id }) => {
  const warehouseIdentificationType = useSelector(
    (state: ReduxState) => state.requestListAdministration.warehouseIdentificationType,
  );
  const productIdentificationType = useSelector(
    (state: ReduxState) => state.requestListAdministration.productIdentificationType,
  );
  const productItemsContent = useSelector(
    (state: ReduxState) => state.requestListAdministration.productItemsContent,
  );

  const dispatch = useAppDispatch();

  const itemsPlaceholder = `Paste store ${
    warehouseIdentificationType === IdentificationType.Code ? 'code' : 'ID'
  }, product ${
    productIdentificationType === IdentificationType.Code ? 'code' : 'ID'
  } and quantity from Excel (or separated by semicolons)`;

  /**
   * Saves the content of the text area to state
   * Called on text area's change event
   */
  const requestListItemsHandler = useCallback(
    (newValue: string) => {
      dispatch(setProductItemsContent(newValue));
    },
    [dispatch],
  );

  const skuSerializer = useCallback((data: AdministrationItemRow<RequestListInputData>[]) => {
    return getParseableStringFromPastedData(data, ';');
  }, []);

  return (
    <ListItemsTextArea<RequestListInputData>
      label={label}
      id={id ?? 'RequestListItemsInput'}
      value={productItemsContent}
      placeholder={itemsPlaceholder}
      onValueChanged={requestListItemsHandler}
      parserProvider={selectRequestListParser}
      serializer={skuSerializer}
    />
  );
};

export default RequestListItemsTextArea;
