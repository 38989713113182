import React, { useEffect, useState } from 'react';
import { ForecastMonthlyOverviewResponse, SaleMonthlyOverviewResponse } from '@ydistri/api-sdk';
import { useSelector } from 'react-redux';
import { DEFAULT_SALE_MONTH_COUNT } from '../../../../detailTypes';
import { SkusCollection } from '../../../../../../swagger/collections';
import { setIsLoadingSalesData } from '../../../../detailSlice';
import DetailSales from '../DetailSales/DetailSales';
import { NO_VALUE } from '../../../../../../lib/utils/utilsTypes';
import { useAppDispatch } from '../../../../../../store';

const DetailSkuBody: React.FC = () => {
  const skuId = useSelector(state => state.detailReducer.skuId);
  const skuEntity = useSelector(state => state.detailReducer.skuEntity);
  const productId = useSelector(state => state.detailReducer.productId);
  const regionId = useSelector(state => state.detailReducer.regionId);
  const isLoadingSalesData = useSelector(state => state.detailReducer.isLoadingSalesData);

  const dispatch = useAppDispatch();

  const [salesData, setSalesData] = useState<SaleMonthlyOverviewResponse[]>();
  const [forecastData, setForecastData] = useState<ForecastMonthlyOverviewResponse[]>([]);

  useEffect(() => {
    if (skuId === NO_VALUE) {
      return;
    }

    Promise.all([
      SkusCollection.getSkuMonthlySaleWithinMonthsRange(skuId, DEFAULT_SALE_MONTH_COUNT),
      SkusCollection.getSkuMonthlyForecast(skuId),
    ])
      .then(([{ data: salesData }, { data: forecastData }]) => {
        setSalesData(salesData.data);
        setForecastData(forecastData.data);
      })
      .catch(() => {
        //no data found, we must erase previous data so we do not show data from different sku
        setSalesData([]);
        setForecastData([]);
      })
      .finally(() => {
        dispatch(setIsLoadingSalesData(false));
      });
  }, [skuId, productId, regionId, dispatch]);

  if (skuId !== NO_VALUE && skuEntity) {
    return (
      <DetailSales
        salesData={salesData}
        forecastData={forecastData}
        loadingSalesData={isLoadingSalesData}
        hasForecasts={true}
        skuChart={true}
        showAverageValueInChartTooltip={true}
      />
    );
  }
};

export default DetailSkuBody;
