import { EntityListImportStep, setImportStepRunning } from './entityListAdministrationSlice';
import { useCallback } from 'react';
import { useAppDispatch } from '../../../store';
import { EntityListTypeSupported } from './entityListsLib';

type SetImportStepRunningCallback = (step: EntityListImportStep, v: boolean) => void;

export const useSetImportStepRunning = (
  entityListType: EntityListTypeSupported,
): SetImportStepRunningCallback => {
  const dispatch = useAppDispatch();

  return useCallback(
    (x: EntityListImportStep, v: boolean) => {
      dispatch(
        setImportStepRunning({
          entityListType,
          data: {
            step: x,
            value: v,
          },
        }),
      );
    },
    [dispatch, entityListType],
  );
};
