import {
  MIN_SIZE_TO_DISPLAY_VERTICAL_CONTENT,
  ParsedCategoryRedistribution,
} from '../categoryInsightsLib';
import React, { useMemo } from 'react';
import { BarPartitionContent, BarLineProps, SimpleTooltipContent } from '@ydistri/ds';
import BarRedistribution from './BarRedistribution';
import { computePercentage } from '@ydistri/utils';

const TITLE_ROWS = ['Target', 'by reason'];

interface BarTargetByReasonProps {
  data: ParsedCategoryRedistribution;
}

const BarTargetByReason: React.FC<BarTargetByReasonProps> = ({ data }) => {
  const barLineData: BarLineProps['data'] = useMemo(() => {
    const percentageOthers = computePercentage(
      data.byRedistributionType.TargetOthersValue.total,
      data.total,
    );
    const percentageMinLayers = computePercentage(
      data.byRedistributionType.TargetMinLayersValue.total,
      data.total,
    );
    const percentageTargetList = computePercentage(
      data.byRedistributionType.TargetTargetListValue.total,
      data.total,
    );
    const percentageForecast = 100 - percentageOthers - percentageMinLayers - percentageTargetList;

    return [
      {
        percentage: percentageOthers,
        tooltip: (
          <SimpleTooltipContent
            title="Others"
            value={data.byRedistributionType.TargetOthersValue.total}
          />
        ),
        content: (
          <BarPartitionContent
            title="Others"
            percentage={percentageOthers}
            value={data.byRedistributionType.TargetOthersValue.total}
          />
        ),
        contentAlignment: 'flex-end',
        minSizeToDisplayContent: MIN_SIZE_TO_DISPLAY_VERTICAL_CONTENT,
      },
      {
        percentage: percentageTargetList,
        tooltip: (
          <SimpleTooltipContent
            title="Request lists"
            value={data.byRedistributionType.TargetTargetListValue.total}
          />
        ),
        content: (
          <BarPartitionContent
            title="Request lists"
            percentage={percentageTargetList}
            value={data.byRedistributionType.TargetTargetListValue.total}
          />
        ),
        contentAlignment: 'flex-end',
        minSizeToDisplayContent: MIN_SIZE_TO_DISPLAY_VERTICAL_CONTENT,
      },
      {
        percentage: percentageMinLayers,
        tooltip: (
          <SimpleTooltipContent
            title="Min layers"
            value={data.byRedistributionType.TargetMinLayersValue.total}
          />
        ),
        content: (
          <BarPartitionContent
            title="Min layers"
            percentage={percentageMinLayers}
            value={data.byRedistributionType.TargetMinLayersValue.total}
          />
        ),
        contentAlignment: 'flex-end',
        minSizeToDisplayContent: MIN_SIZE_TO_DISPLAY_VERTICAL_CONTENT,
      },
      {
        percentage: percentageForecast,
        tooltip: (
          <SimpleTooltipContent
            title="Forecast"
            value={data.byRedistributionType.TargetForecastValue.total}
          />
        ),
        content: (
          <BarPartitionContent
            title="Forecast"
            percentage={percentageForecast}
            value={data.byRedistributionType.TargetForecastValue.total}
          />
        ),
        contentAlignment: 'flex-end',
        minSizeToDisplayContent: MIN_SIZE_TO_DISPLAY_VERTICAL_CONTENT,
      },
    ];
  }, [
    data.byRedistributionType.TargetOthersValue.total,
    data.byRedistributionType.TargetForecastValue.total,
    data.byRedistributionType.TargetMinLayersValue.total,
    data.byRedistributionType.TargetTargetListValue.total,
    data.total,
  ]);

  return <BarRedistribution barLineData={barLineData} title={TITLE_ROWS} dividers={true} />;
};

export default BarTargetByReason;
