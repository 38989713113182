import React from 'react';
import EntityListEditor from '../edit/EntityListEditor';
import { EntityListTypeSupported } from '../../entityListsLib';

interface EntityListStepImportProps {
  entityListType: EntityListTypeSupported;
}

const EntityListStepImport: React.FC<EntityListStepImportProps> = ({ entityListType }) => {
  return <EntityListEditor entityListType={entityListType} />;
};

export default EntityListStepImport;
