import React from 'react';
import { Popover } from 'antd';
import { formatMonetaryValue, shortenNumber } from '@ydistri/utils';
import { WideStatistic } from '../../Statistics/SectionRequestList';

interface CalculationOverallPickingStatisticsItemProps {
  title: string;
  value: number;
  totalValue: number;
  percentage: number;
  unit: string;
}

/**
 * Component to display a single item in the overall picking statistics.
 * Each item has a title, current value and total value. Percentage is displayed in brackets.
 * @param title
 * @param value
 * @param totalValue
 * @param unit Unit of the value used for formatting of the popover
 * @param percentage precomputed percentage of value to totalValue
 * @constructor
 */
const StatisticsItem: React.FC<CalculationOverallPickingStatisticsItemProps> = ({
  title,
  value,
  totalValue,
  unit,
  percentage,
}) => {
  return (
    <Popover
      content={`${formatMonetaryValue(
        unit,
        value,
        0,
      )} / ${formatMonetaryValue(unit, totalValue, 0)}`}
    >
      <WideStatistic
        groupSeparator=" "
        title={title}
        value={`${shortenNumber(value, 0)} / ${shortenNumber(totalValue, 0)} (${percentage}%)`}
      />
    </Popover>
  );
};

export default StatisticsItem;
