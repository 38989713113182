import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { AvailableModals, closeModal } from '../modalSlice';
import { ReduxState, useAppDispatch } from '../../../store';
import { Modal } from 'antd';
import { useGetSkuQuery } from '../../Detail/apiDetail';
import { Row, Title } from '@ydistri/ds';
import PickingLineProductGroupInfo from '../../CalculationDetail/Redistribution/parts/listing/components/SkuDetailSection/PickingLineProductGroupInfo/PickingLineProductGroupInfo';

const modalId = AvailableModals.PICKING_LINE_PRODUCT_GROUPS;

const PickingLineProductGroupsModal: React.FC = () => {
  const dispatch = useAppDispatch();

  const open = useSelector((state: ReduxState) => state.modal.openedModal === modalId);
  const modalData = useSelector((state: ReduxState) => state.modal.modalData[modalId]);
  const { sourceSkuId } = modalData ?? { sourceSkuId: 0, targetSkuId: 0 };

  const { data: sku, isFetching: isSkuFetching } = useGetSkuQuery(sourceSkuId);

  const handleClose = useCallback(() => {
    dispatch(closeModal());
  }, [dispatch]);

  const title = useMemo(
    () => (isSkuFetching ? 'Loading...' : null),
    [isSkuFetching, sku?.product?.name, sku?.store?.name],
  );

  if (!open) return null;
  return (
    <Modal title={title} centered open={true} width="80%" onCancel={handleClose} footer={null}>
      <PickingLineProductGroupInfo />
    </Modal>
  );
};

export default PickingLineProductGroupsModal;
