import React from 'react';
import { Popover } from 'antd';
import { Button, IconButton_IconSizes } from '@ydistri/ds';
import SkuSalePopoverChart from './SkuSalePopover';
import { ImStatsDots } from 'react-icons/im';

interface SkuSalePopoverProps {
  skuId: number;
}

const SkuSalePopover: React.FC<SkuSalePopoverProps> = ({ skuId }) => {
  return (
    <Popover trigger="click" content={<SkuSalePopoverChart skuId={skuId} />} arrow={false}>
      <Button $colorVariant="ghost" $sizeVariant="xSmall">
        <ImStatsDots size={IconButton_IconSizes.xSmall} />
      </Button>
    </Popover>
  );
};

export default SkuSalePopover;
