import { TargetListResponse as RequestListResponse } from '@ydistri/api-sdk';
import { styled } from 'styled-components';
import { Form, Input, InputRef, Modal } from 'antd';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  defaultRequestListParams,
  RequestListUpdateRequest,
  useCreateRequestListMutation,
  useGetRequestListsQuery,
  useUpdateRequestListMutation,
} from '../apiRequestLists';
import { setSelectedRequestList } from '../requestListAdministrationSlice';
import { addToast } from '@ydistri/utils';
import { ModalMode } from '../../common/administrationItemsTypes';
import { computeRemSize } from '@ydistri/ds';
import { Rule } from 'rc-field-form/lib/interface';
import { ErrorType } from '../../../../apis/api';
import { useAppDispatch } from '../../../../store';

interface RequestListModalProps {
  requestList?: RequestListResponse;
  onClose: () => void;
  confirmButtonLabel?: string;
}

interface FormType {
  name: string;
}

/**
 * Needed to override default height of select component to be the same
 * as the Input field on the modal.
 */
const StyledModal = styled(Modal)`
  #CreateRequestListForm .ant-select-selector,
  #EditRequestListForm .ant-select-selector {
    height: ${computeRemSize(28)};
    align-items: center;
  }

  #RequestListTypeSelect {
    height: ${computeRemSize(24)};
  }
`;

const RequestListModal: React.FC<RequestListModalProps> = ({
  onClose,
  requestList,
  confirmButtonLabel,
}) => {
  const [isBusy, setIsBusy] = useState(false);
  const [addRequestList] = useCreateRequestListMutation();
  const [updateRequestList] = useUpdateRequestListMutation();
  const [form] = Form.useForm<FormType>();
  const { data: requestLists } = useGetRequestListsQuery(defaultRequestListParams);
  const inputRef = useRef<InputRef>(null);

  const modalMode = useMemo(() => {
    if (requestList) {
      return ModalMode.EDIT;
    } else {
      return ModalMode.CREATE;
    }
  }, [requestList]);

  const dispatch = useAppDispatch();

  const closeModal = useCallback(() => {
    onClose();
  }, [onClose]);

  const createRequestList = useCallback(
    (values: FormType) => {
      setIsBusy(true);

      addRequestList({ name: values.name })
        .unwrap()
        .then(result => {
          dispatch(setSelectedRequestList(result));
        })
        // eslint-disable-next-line @typescript-eslint/use-unknown-in-catch-callback-variable -- we know it's the right type
        .catch((error: ErrorType) => {
          dispatch(
            addToast({
              isError: true,
              message: `Request List was not created: ${error.response.data.Messages.join(', ')}`,
            }),
          );
        })
        .finally(() => {
          setIsBusy(false);
          closeModal();
        });
    },
    [addRequestList, closeModal, dispatch],
  );

  const editRequestList = useCallback(
    (values: FormType) => {
      if (requestList) {
        setIsBusy(true);
        const request: RequestListUpdateRequest = {
          requestListId: requestList.targetListId,
        };

        //only include values in the request that have changed
        if (values.name !== requestList.name) {
          request.name = values.name;
        }

        updateRequestList(request)
          .unwrap()
          .then(result => {
            if (result) {
              dispatch(setSelectedRequestList(result));
            }
          })
          .catch(() => {
            dispatch(
              addToast({ message: 'Error occurred when updating a Request List', isError: true }),
            );
          })
          .finally(() => {
            setIsBusy(false);
            closeModal();
          });
      }
    },
    [closeModal, dispatch, requestList, updateRequestList],
  );

  const onSubmit = useCallback(
    (values: FormType) => {
      if (modalMode === ModalMode.CREATE) {
        createRequestList(values);
      } else {
        editRequestList(values);
      }
    },
    [modalMode, createRequestList, editRequestList],
  );

  const processForm = useCallback(() => {
    form
      .validateFields()
      .then(values => {
        onSubmit(values);
      })
      .catch((error: unknown) => {
        // eslint-disable-next-line no-console -- we want the output
        console.log('Validation Error:', error);
      });
  }, [form, onSubmit]);

  const okHandler = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      processForm();
    },
    [processForm],
  );

  /**
   * Autofocus the input element when mounted for better user experience
   * as there is only one input element where the user needs to write something.
   */
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef]);

  const onEnterPressed = useCallback(() => {
    processForm();
  }, [processForm]);

  const nameItemRules = useMemo(
    () => [
      { required: true, message: 'Enter the name of the request list' },
      {
        validator: async (rule: Rule, value: string) => {
          const otherRequestLists = requestList
            ? requestLists?.filter(item => item.targetListId !== requestList.targetListId)
            : requestLists;

          const sameNameRequestListExists = otherRequestLists?.find(item => item.name === value);
          if (sameNameRequestListExists) {
            return Promise.reject(
              new Error('Request List with this name already exists, choose different name'),
            );
          }
        },
      },
    ],
    [requestList, requestLists],
  );

  const buttonProps = useMemo(() => {
    return {
      disabled: isBusy,
    };
  }, [isBusy]);

  const initialValues = useMemo(() => {
    if (requestList) {
      return {
        name: requestList.name,
      };
    }
  }, [requestList]);

  return (
    <StyledModal
      data-testid={`${modalMode === ModalMode.CREATE ? 'Create' : 'Edit'}RequestListModal`}
      onOk={okHandler}
      open={true}
      onCancel={closeModal}
      title={
        modalMode === ModalMode.CREATE
          ? 'Create Request List'
          : `Edit Request List "${requestList?.name ?? ''}"`
      }
      okButtonProps={buttonProps}
      cancelButtonProps={buttonProps}
      okText={confirmButtonLabel}
    >
      <Form
        name={`${modalMode === ModalMode.CREATE ? 'Create' : 'Edit'}RequestListForm`}
        form={form}
        layout="vertical"
        autoComplete="off"
        disabled={isBusy}
        initialValues={initialValues}
      >
        <Form.Item name="name" label="Name" rules={nameItemRules}>
          <Input
            placeholder="Name of Request List"
            ref={inputRef}
            onPressEnter={onEnterPressed}
            disabled={isBusy}
          />
        </Form.Item>
      </Form>
    </StyledModal>
  );
};

export default RequestListModal;
