import React, { CSSProperties, useMemo } from 'react';
import { TargetPotentialInfo } from '../../categoryInsightsLib';
import { Column, Title, BarLine, BarLineProps, BarLineSizes } from '@ydistri/ds';
import { computePercentage, shortenNumber } from '@ydistri/utils';
import { useCurrency } from '../../../../../hooks/useCurrency';
import TargetPotentialBarTooltip from './TargetPotentialBarTooltip';

interface TargetPotentialBarProps {
  data: TargetPotentialInfo;
  title: string;
  baseColor?: CSSProperties['backgroundColor'];
  highlightedColor?: CSSProperties['backgroundColor'];
}

const TargetPotentialBar: React.FC<TargetPotentialBarProps> = ({ data, title, baseColor }) => {
  const currency = useCurrency();

  const sum = data.byRequestList + data.byForecast + data.byMinLayers;

  const barData: BarLineProps['data'] = useMemo(() => {
    const byRequestList = data.byRequestList;
    const byForecast = data.byForecast;
    const byMinLayers = data.byMinLayers;
    const inventory = data.inventory;
    const openPurchaseOrders = data.openPurchaseOrders;
    const incoming = data.incoming;

    const sum =
      data.byRequestList +
      data.byForecast +
      data.byMinLayers +
      data.inventory +
      data.openPurchaseOrders +
      data.incoming;
    const byRequestListPercentage = computePercentage(byRequestList, sum);
    const byForecastPercentage = computePercentage(byForecast, sum);
    const byMinLayersPercentage = computePercentage(byMinLayers, sum);
    const inventoryPercentage = computePercentage(inventory, sum);
    const openPurchaseOrdersPercentage = computePercentage(openPurchaseOrders, sum);
    const incomingPercentage = computePercentage(incoming, sum);

    return [
      {
        percentage: inventoryPercentage,
        tooltip: <TargetPotentialBarTooltip title="Inventory" value={inventory} />,
        minSizeToDisplayContent: 16,
      },
      {
        percentage: openPurchaseOrdersPercentage,
        tooltip: (
          <TargetPotentialBarTooltip title="Open purchase orders" value={openPurchaseOrders} />
        ),
        minSizeToDisplayContent: 16,
      },
      {
        percentage: incomingPercentage,
        tooltip: (
          <TargetPotentialBarTooltip title="Incoming from redistributions" value={incoming} />
        ),
        minSizeToDisplayContent: 16,
      },
      {
        percentage: byForecastPercentage,
        highlightedPercentage: 100,
        color: '#87B65F',
        tooltip: <TargetPotentialBarTooltip title="Forecast" value={byForecast} />,
        minSizeToDisplayContent: 16,
      },
      {
        percentage: byMinLayersPercentage,
        highlightedPercentage: 100,
        color: '#87B65F',
        tooltip: <TargetPotentialBarTooltip title="Min layers" value={byMinLayers} />,
        minSizeToDisplayContent: 16,
      },
      {
        percentage: byRequestListPercentage,
        highlightedPercentage: 100,
        color: '#87B65F',
        tooltip: <TargetPotentialBarTooltip title="Request list" value={byRequestList} />,
        minSizeToDisplayContent: 16,
      },
    ];
  }, [
    data.byRequestList,
    data.byForecast,
    data.byMinLayers,
    data.inventory,
    data.openPurchaseOrders,
    data.incoming,
  ]);

  return (
    <Column $gap="0.5rem">
      <Title>
        {title} ({shortenNumber(sum)} {currency})
      </Title>
      <BarLine
        size={BarLineSizes.MEDIUM}
        data={barData}
        dividers={true}
        backgroundColor={baseColor}
      />
    </Column>
  );
};

export default TargetPotentialBar;
