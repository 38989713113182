import {
  CategoryResponse,
  ConfigurationFieldType,
  EntityListSkuResponse,
  SkuAttributeResponse,
  SkuCalculationBaseConfigurationResponse,
  SkuCalculationConfigurationResponse,
  SkuFieldConfigurationResponse,
  SkuType,
} from '@ydistri/api-sdk';
import {
  SkuData,
  SkuFieldConfiguration,
  SkuRedistributionPaneType,
  SkuRedistributionSubset,
} from './redistributionTypes';
import { parseSkuAttributes } from '../../../lib/sku/skuLib';

/**
 * Extract category titles from categories to an array sorted by category level
 * with category level 0 removed.
 * @param categories
 * @returns array of category titles
 */
export const extractCategoryTitles = (categories: CategoryResponse[]): string[] => {
  return categories
    .filter(category => category.level > 0)
    .sort((a: CategoryResponse, b: CategoryResponse) => a.level - b.level)
    .map(category => category.title);
};

const parseConfig = (
  skuConfiguration: SkuCalculationBaseConfigurationResponse,
): SkuFieldConfiguration => {
  const result: SkuFieldConfiguration = {
    isForced: skuConfiguration.isForced,
    isClosing: skuConfiguration.isClosing,
    outgoingQuantity: skuConfiguration.outgoingQuantity,
    incomingQuantity: skuConfiguration.incomingQuantity,
  };

  if (skuConfiguration.fieldConfigurations) {
    skuConfiguration.fieldConfigurations.forEach(
      fieldConfiguration => (result[fieldConfiguration.fieldType] = fieldConfiguration.value),
    );

    // if configurations are empty, it is most likely manual calculation
    // in that case, we just fake-display "Dynamic" (50) forecast
    if (skuConfiguration.fieldConfigurations.length === 0) {
      result[ConfigurationFieldType.PsMaxForecastConfidence] = '50';
      result[ConfigurationFieldType.PtMinForecastConfidence] = '50';
    }
  }

  return result;
};

const parseAndMergeConfig = (
  skuConfigurations: SkuCalculationBaseConfigurationResponse[],
): { config: SkuFieldConfiguration; skuType: SkuType } => {
  const calculationIds: number[] = [];
  const calcConfigs: Record<number, SkuFieldConfiguration | undefined> = {};
  const calcSkuTypes: Record<number, SkuType> = {};
  skuConfigurations.map(sc => {
    calcConfigs[sc.calculation.id] = parseConfig(sc);
    calcSkuTypes[sc.calculation.id] = sc.skuType;
    calculationIds.push(sc.calculation.id);
  });

  const findMax = (c: ConfigurationFieldType) => {
    return Math.max(
      ...calculationIds.map(cid =>
        calcConfigs[cid]?.[c] ? parseInt(calcConfigs[cid][c]) : -Infinity,
      ),
    ).toString();
  };

  const findMin = (c: ConfigurationFieldType) => {
    return Math.min(
      ...calculationIds.map(cid =>
        calcConfigs[cid]?.[c] ? parseInt(calcConfigs[cid][c]) : Infinity,
      ),
    ).toString();
  };

  const mergeLists = (c: ConfigurationFieldType) => {
    return JSON.stringify(
      Array.from(
        new Set(
          calculationIds
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- parsed min layer list IDs from JSON to number[]
            .map(cid => (calcConfigs[cid]?.[c] ? JSON.parse(calcConfigs[cid][c]) : []) as number[])
            .flat(),
        ),
      ),
    );
  };

  const findHighestForcingLevelType = (): string => {
    /* eslint-disable @typescript-eslint/naming-convention -- keys come from API */
    const order: Record<string, number | undefined> = {
      NoIncrease: 100,
      SlightlyIncrease: 200,
      SignificantlyIncrease: 300,
    };
    /* eslint-enable @typescript-eslint/naming-convention -- rule re-enabled */

    const c = ConfigurationFieldType.PtForcingLevelTypeId;
    const calculationIdWithHighestForcingType = [...calculationIds].sort(
      (a, b) =>
        (order[calcConfigs[b]?.[c] ?? 'NoIncrease'] ?? 0) -
        (order[calcConfigs[a]?.[c] ?? 'NoIncrease'] ?? 0),
    )[0];
    return calcConfigs[calculationIdWithHighestForcingType]?.[c] ?? 'NoIncrease';
  };

  const findWorstSkuType = (): SkuType => {
    /* eslint-disable @typescript-eslint/naming-convention -- keys come from API */
    const order: Record<string, number | undefined> = {
      [SkuType.DeadStock]: 100,
      [SkuType.SlowMover]: 200,
      [SkuType.FastMover]: 300,
    };
    /* eslint-enable @typescript-eslint/naming-convention -- rule re-enabled */

    const calculationIdWithWorstSkuType = [...calculationIds].sort(
      (a, b) =>
        (order[calcSkuTypes[a] ?? SkuType.DeadStock] ?? 0) -
        (order[calcSkuTypes[b] ?? SkuType.DeadStock] ?? 0),
    )[0];
    return calcSkuTypes[calculationIdWithWorstSkuType] ?? SkuType.DeadStock;
  };

  const result: SkuFieldConfiguration = {
    [ConfigurationFieldType.CatDeadStock]: findMin(ConfigurationFieldType.CatDeadStock),
    [ConfigurationFieldType.CatSlowMoverMonths]: findMin(ConfigurationFieldType.CatSlowMoverMonths),
    [ConfigurationFieldType.CatSlowMoverSales]: findMin(ConfigurationFieldType.CatSlowMoverSales),
    [ConfigurationFieldType.PsEnableWholeSection]: findMax(
      ConfigurationFieldType.PsEnableWholeSection,
    ),
    [ConfigurationFieldType.PsMonthsFromFirstPurchase]: findMin(
      ConfigurationFieldType.PsMonthsFromFirstPurchase,
    ),
    [ConfigurationFieldType.PsMonthsFromLastPurchase]: findMin(
      ConfigurationFieldType.PsMonthsFromLastPurchase,
    ),
    [ConfigurationFieldType.PsMinRowValue]: findMin(ConfigurationFieldType.PsMinRowValue),
    [ConfigurationFieldType.PsUseProductWithPromo]: findMax(
      ConfigurationFieldType.PsUseProductWithPromo,
    ),
    [ConfigurationFieldType.PsMonthsOfSupplyToKeep]: findMin(
      ConfigurationFieldType.PsMonthsOfSupplyToKeep,
    ),
    [ConfigurationFieldType.PsMaxForecastConfidence]: findMax(
      ConfigurationFieldType.PsMaxForecastConfidence,
    ),
    [ConfigurationFieldType.PtMonthsOfSupplyToGetByForecast]: findMax(
      ConfigurationFieldType.PtMonthsOfSupplyToGetByForecast,
    ),
    [ConfigurationFieldType.PtMinForecastConfidence]: findMin(
      ConfigurationFieldType.PtMinForecastConfidence,
    ),
    [ConfigurationFieldType.PtMinimal6MonthsFrequencyOfSales]: findMin(
      ConfigurationFieldType.PtMinimal6MonthsFrequencyOfSales,
    ),
    [ConfigurationFieldType.PtAddOpenPurchaseOrdersToAvailableSupply]: findMin(
      ConfigurationFieldType.PtAddOpenPurchaseOrdersToAvailableSupply,
    ),
    [ConfigurationFieldType.PtTargetStockoutsOnly]: findMin(
      ConfigurationFieldType.PtTargetStockoutsOnly,
    ),
    [ConfigurationFieldType.PsUseForcedRedistribution]: findMin(
      ConfigurationFieldType.PsUseForcedRedistribution,
    ),
    [ConfigurationFieldType.PtForcingLevelTypeId]: findHighestForcingLevelType(),
    [ConfigurationFieldType.PsMinLayerList]: mergeLists(ConfigurationFieldType.PsMinLayerList),
    [ConfigurationFieldType.PtMinLayerList]: mergeLists(ConfigurationFieldType.PtMinLayerList),
    [ConfigurationFieldType.PtTargetList]: findMax(ConfigurationFieldType.PtTargetList),
    isForced: !!calculationIds.find(cid => calcConfigs[cid]?.isForced),
    isClosing: !!calculationIds.find(cid => calcConfigs[cid]?.isClosing),
    // outgoing/incoming quantity must be from the main, merged calculation, because subcalculations affect each other's out/incoming quantities
    outgoingQuantity: 0,
    incomingQuantity: 0,
  };

  return { config: result, skuType: findWorstSkuType() };
};

export const mergeMinLayerLists = (el: EntityListSkuResponse[][]): EntityListSkuResponse[] => {
  const allLists = el.flat();
  return (
    [...new Set(allLists.map(e => e.entityListId))]
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- undefined values are filtered out with .filter(Boolean), so Entity lists are always present but TS has problem
      .map(id => allLists.find(el => el.entityListId === id)!)
      .filter(Boolean)
  );
};

export const parseSku = (
  skuConfiguration: SkuCalculationBaseConfigurationResponse | SkuCalculationConfigurationResponse,
  skuAttributes: SkuAttributeResponse[],
  merged: boolean,
): SkuData => {
  const hasSubordinateConfigurations = 'subordinateCalculationSkus' in skuConfiguration;
  if (merged && hasSubordinateConfigurations) {
    const subCalculations = skuConfiguration.subordinateCalculationSkus ?? [];

    const { config, skuType } = parseAndMergeConfig(subCalculations);

    const parsedMergedSku: SkuData = {
      id: skuConfiguration.skuId,
      type: skuType,
      categories: extractCategoryTitles(skuConfiguration.categories),
      skuClass: skuConfiguration.skuClass ?? undefined,
      attributes: parseSkuAttributes(skuConfiguration.skuId, skuAttributes),
      config,
      requestList: skuConfiguration.targetList ?? undefined,
      sourceMinLayerLists: mergeMinLayerLists(
        subCalculations.map(s => s.sourceMinLayerLists ?? []),
      ),
      targetMinLayerLists: mergeMinLayerLists(
        subCalculations.map(s => s.targetMinLayerLists ?? []),
      ),
    };

    // outgoing/incoming quantity must be from the main, merged calculation, because subcalculations affect each other's out/incoming quantities
    parsedMergedSku.config.outgoingQuantity = skuConfiguration.outgoingQuantity;
    parsedMergedSku.config.incomingQuantity = skuConfiguration.incomingQuantity;

    return parsedMergedSku;
  }

  return {
    id: skuConfiguration.skuId,
    type: skuConfiguration.skuType,
    categories: extractCategoryTitles(skuConfiguration.categories),
    skuClass: skuConfiguration.skuClass ?? undefined,
    attributes: parseSkuAttributes(skuConfiguration.skuId, skuAttributes),
    config: parseConfig(skuConfiguration),
    requestList: skuConfiguration.targetList ?? undefined,
    sourceMinLayerLists: skuConfiguration.sourceMinLayerLists ?? [],
    targetMinLayerLists: skuConfiguration.targetMinLayerLists ?? [],
  };
};

export const computeFinalSupply = (
  skuConfig: SkuData,
  redistributionData: SkuRedistributionSubset,
  infoType: SkuRedistributionPaneType,
): number => {
  let availableSupply = skuConfig.attributes.values.AvailableSupply
    ? parseFloat(skuConfig.attributes.values.AvailableSupply)
    : 0;

  availableSupply -= skuConfig.config.outgoingQuantity;
  availableSupply += skuConfig.config.incomingQuantity;

  const tmpTotalMovedQuantity = redistributionData.totalMovedQuantity;

  if (infoType === 'Source') {
    return parseInt((availableSupply - tmpTotalMovedQuantity).toFixed(0));
  } else {
    let openPurchaseOrdersQuantity = 0;

    const addOpenPurchaseOrdersToAvailableSupply = skuConfig.config
      .PtAddOpenPurchaseOrdersToAvailableSupply
      ? parseInt(skuConfig.config.PtAddOpenPurchaseOrdersToAvailableSupply)
      : 0;

    if (addOpenPurchaseOrdersToAvailableSupply === 1) {
      openPurchaseOrdersQuantity = skuConfig.attributes.values.OpenPurchaseOrdersQuantity
        ? parseFloat(skuConfig.attributes.values.OpenPurchaseOrdersQuantity)
        : 0;
    }

    return parseInt(
      (availableSupply + openPurchaseOrdersQuantity + tmpTotalMovedQuantity).toFixed(0),
    );
  }
};

export const trimArray = <T>(items: T[], maxItems: number, replacement: T): T[] => {
  const result = [...items];
  if (result.length > maxItems) {
    const lastIndexToKeep = result.length - (maxItems - 1);
    result.splice(1, lastIndexToKeep - 1, replacement);
  }

  return result;
};

export const getSkuFieldConfigurationValue = (
  fieldConfigurations: SkuFieldConfigurationResponse[],
  fieldConfigurationName: ConfigurationFieldType,
): string | undefined => {
  const fieldConfiguration = fieldConfigurations.find(
    fieldConfiguration => fieldConfiguration.fieldType === fieldConfigurationName,
  );
  if (fieldConfiguration) {
    return fieldConfiguration.value;
  } else {
    return undefined;
  }
};

export const getSkuFieldConfigurationNumericValue = (
  fieldConfigurations: SkuFieldConfigurationResponse[],
  fieldConfigurationName: ConfigurationFieldType,
): number | undefined => {
  const txtValue = getSkuFieldConfigurationValue(fieldConfigurations, fieldConfigurationName);
  if (txtValue) {
    return parseInt(txtValue);
  } else {
    return undefined;
  }
};
