import React, { useCallback, useEffect, useMemo } from 'react';
import { useGetCalculationSkusQuery } from '../../../../../../../Calculations/apiCalculationsSkus';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../../../../../../store';
import { AvailableModals } from '../../../../../../../Modals/modalSlice';
import { useTemplateOrCalculation } from '../../../../../../../../hooks/useTemplateOrCalculation';
import ProductGroupSelector from './ProductGroupSelector';
import { Label, Text } from '@ydistri/ds';
import { Space } from 'antd';
import { useGetPickingPositionsWithinProductGroupQuery } from '../../../../../../apiCalculationDetail';
import { skipToken } from '@reduxjs/toolkit/query';
import PickingLineProductGroupTable from './PickingLineProductGroupTable';
import {
  emptyPickingLineHalf,
  PickingLineHalf,
  PickingLineProductGroupInfoRow,
} from './pickingLineProductGroupInfoLib';
import { ProductGroupResponse } from '@ydistri/api-sdk';

const PickingLineProductGroupInfo: React.FC = () => {
  const calculationData = useTemplateOrCalculation();
  const calculationId = calculationData.id;
  const modalData = useSelector(
    (state: ReduxState) => state.modal.modalData[AvailableModals.PICKING_LINE_PRODUCT_GROUPS],
  );
  const {
    sourceSkuId,
    targetSkuId,
    sourceStoreId: sourceStoreIdParam,
    targetStoreId: targetStoreIdParam,
  } = modalData ?? { sourceSkuId: 0, targetSkuId: 0, sourceStoreId: 0, targetStoreId: 0 };
  const [productGroup, setProductGroup] = React.useState<ProductGroupResponse | null>(null);
  const productGroupId = productGroup?.id ?? null;

  const { data: skuConfiguration, isFetching: isFetchingSkuConfiguration } =
    useGetCalculationSkusQuery({
      skuId: sourceSkuId,
      calculationId,
    });
  const { data: pickingPositions, isFetching: isFetchingPickingPositions } =
    useGetPickingPositionsWithinProductGroupQuery(
      productGroupId
        ? {
            calculationId,
            productGroupId,
            sourceSkuId,
            targetSkuId,
            sourceStoreId: sourceStoreIdParam,
            targetStoreId: targetStoreIdParam,
          }
        : skipToken,
    );

  const productGroups = useMemo(
    () => skuConfiguration?.productGroups ?? [],
    [skuConfiguration?.productGroups],
  );

  useEffect(() => {
    if (!productGroup && productGroups.length > 0) {
      setProductGroup(productGroups[0]);
    }
  }, [productGroup, productGroups]);

  const selectedPickingLine = useMemo(
    () =>
      pickingPositions?.find(
        pp => pp.sourceSkuId === sourceSkuId && pp.targetSkuId === targetSkuId,
      ),
    [pickingPositions, sourceSkuId, targetSkuId],
  );

  const product = selectedPickingLine?.product;
  const sourceStore = selectedPickingLine?.sourceStore;
  const targetStore = selectedPickingLine?.targetStore;
  const sourceStoreId = sourceStore?.id;
  const targetStoreId = targetStore?.id;

  const data: PickingLineProductGroupInfoRow[] = useMemo(() => {
    const productData: Record<number, PickingLineProductGroupInfoRow | undefined> = {};
    const productIds = new Set<number>();

    (pickingPositions ?? []).forEach(p => {
      const pp = { ...p };

      const isThisRoute =
        pp.sourceStore.id === sourceStoreId && pp.targetStore.id === targetStoreId;
      const productId = pp.product.id;
      productIds.add(productId);

      if (!productData[productId]) {
        productData[productId] = {
          product: pp.product,
          parameters: pp.parameters ?? [],
          isOpenedPickingLine: false,
          isThisRoute: false,
          source: { ...emptyPickingLineHalf },
          target: { ...emptyPickingLineHalf },
        };
      }

      productData[productId].source.otherRoutes = -pp.totalQuantityFromSourceSku;
      productData[productId].target.otherRoutes = pp.totalQuantityToTargetSku;

      if (pp.quantity) {
        if (isThisRoute) {
          productData[productId].isThisRoute = true;
          productData[productId].source.thisRoute = -pp.quantity;
          productData[productId].target.thisRoute = pp.quantity;

          productData[productId].source.otherRoutes += pp.quantity;
          productData[productId].target.otherRoutes -= pp.quantity;

          if (sourceSkuId === pp.sourceSkuId && targetSkuId === pp.targetSkuId) {
            productData[productId].isOpenedPickingLine = true;
          }
        }
      }

      productData[productId].source.skuId = pp.sourceSkuId ?? undefined;
      productData[productId].source.availableSupplyNow = pp.sourceAvailableSupply;
      productData[productId].source.minLayer = pp.sourceStrictMinLayerQuantity;
      productData[productId].source.incoming = pp.sourceIncomingQuantity;
      productData[productId].source.outgoing = pp.sourceOutgoingQuantity;
      productData[productId].source.openPurchaseOrders = pp.sourceOpenPurchaseOrdersQuantity;

      productData[productId].target.skuId = pp.targetSkuId ?? undefined;
      productData[productId].target.availableSupplyNow = pp.targetAvailableSupply;
      productData[productId].target.minLayer = pp.targetStrictMinLayerQuantity;
      productData[productId].target.incoming = pp.targetIncomingQuantity;
      productData[productId].target.outgoing = pp.targetOutgoingQuantity;
      productData[productId].target.openPurchaseOrders = pp.targetOpenPurchaseOrdersQuantity;
    });

    const processPickingLineHalf = (h: PickingLineHalf) => {
      h.availableSupplyNowAfterMath =
        h.availableSupplyNow + h.openPurchaseOrders + h.incoming - h.outgoing;
      h.finalSupply = h.availableSupplyNowAfterMath + h.thisRoute + h.otherRoutes;
      h.showCurrentSupplyInfo = h.openPurchaseOrders !== 0 || h.incoming !== 0 || h.outgoing !== 0;
    };

    [...productIds].forEach(pid => {
      if (!productData[pid]) return;
      processPickingLineHalf(productData[pid].source);
      processPickingLineHalf(productData[pid].target);
    });

    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- data should be fine
    return Object.values(productData).filter(Boolean) as PickingLineProductGroupInfoRow[];
  }, [pickingPositions, sourceSkuId, sourceStoreId, targetSkuId, targetStoreId]);

  const setProductGroupById = useCallback(
    (pgId: number) => {
      const g = productGroups.find(pg => pg.id === pgId);
      setProductGroup(g ?? null);
    },
    [productGroups],
  );

  return (
    <div>
      {productGroupId && (
        <Space direction="vertical" size="large">
          <Space>
            <Label>Product group: </Label>
            <ProductGroupSelector
              value={productGroupId}
              setValue={setProductGroupById}
              productGroups={productGroups}
            />
            <Text>Y&apos;id: {productGroup?.id}</Text>
          </Space>
          <PickingLineProductGroupTable
            data={data}
            loading={isFetchingPickingPositions || isFetchingSkuConfiguration}
            selectedProductGroupId={productGroupId}
            productGroups={productGroups}
            product={product}
            sourceStore={sourceStore}
            targetStore={targetStore}
          />
        </Space>
      )}
    </div>
  );
};

export default PickingLineProductGroupInfo;
