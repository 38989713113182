import StatisticsSection from '../../../Statistics/components/StatisticsSection';
import { Column, computeRemSize, Row, Title } from '@ydistri/ds';
import ListIcon from '../../../../../../assets/statistics-request-lists.svg';
import React from 'react';

const CalculationPickingIssuesHeader: React.FC = () => {
  return (
    <StatisticsSection $width="100%" icon={ListIcon}>
      <Row $justifyContent="space-between" $gap="1rem">
        <Column $gap="1rem" $width={computeRemSize(450)}>
          <Title $size="large">Store picking issues</Title>
        </Column>
      </Row>
    </StatisticsSection>
  );
};

export default CalculationPickingIssuesHeader;
