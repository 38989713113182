import { EntityListInfo } from '../../../../ProjectAdministration/EntityLists/entityListsTypes';
import React, { useCallback } from 'react';
import { styled } from 'styled-components';
import { Button, Popover } from 'antd';
import { AvailableModals, openModal } from '../../../../Modals/modalSlice';
import { CategoryConfigurationDefinitionResponse } from '@ydistri/api-sdk';
import { useTemplateOrCalculation } from '../../../../../hooks/useTemplateOrCalculation';
import { ReduxState, useAppDispatch } from '../../../../../store';
import { useSelector } from 'react-redux';
import { TbX } from 'react-icons/tb';

const ClearButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
`;

const removeIcon = <TbX />;

interface EntityListDeleteButtonProps {
  entityListInfo: EntityListInfo;
  configurationDefinition: CategoryConfigurationDefinitionResponse;
}

const EntityListDeleteButton: React.FC<EntityListDeleteButtonProps> = ({
  entityListInfo: { entityList, categoryId },
  configurationDefinition,
}) => {
  const dispatch = useAppDispatch();
  const { type } = useTemplateOrCalculation();
  const openedModalId = useSelector((state: ReduxState) => state.modal.openedModal);
  const modalEntityList = useSelector(
    (state: ReduxState) =>
      state.modal.modalData[AvailableModals.ENTITY_LIST_CONFIG_DELETE]?.entityList,
  );

  const openEntityListDeletionModal = useCallback(() => {
    dispatch(
      openModal({
        type: AvailableModals.ENTITY_LIST_CONFIG_DELETE,
        data: {
          entityList,
          configurationDefinition,
        },
      }),
    );
  }, [configurationDefinition, dispatch, entityList]);

  const disabled = categoryId > 1 || type === 'Calculation';
  const loading =
    openedModalId === AvailableModals.ENTITY_LIST_CONFIG_DELETE &&
    modalEntityList?.entityListId === entityList.entityListId;

  return (
    <Popover
      content="Removing lists is possible only from root category."
      open={categoryId > 1 && type === 'Template' ? undefined : false}
    >
      <ClearButton
        type="default"
        danger
        icon={removeIcon}
        onClick={openEntityListDeletionModal}
        loading={loading}
        disabled={disabled}
      />
    </Popover>
  );
};

export default EntityListDeleteButton;
