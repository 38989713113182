import React, { useCallback, useMemo } from 'react';
import { ColumnsType } from 'antd/es/table';
import { Column, computeRemSize, Label } from '@ydistri/ds';
import { SimpleTable } from '../../../../../../Statistics/components/SimpleTable';
import { styled } from 'styled-components';
import { COLORS } from '../../../../../../../../styles/style';
import {
  SkuClassResponse,
  TargetListSkuResponse as RequestListSkuResponse,
} from '@ydistri/api-sdk';
import NoData, {
  NO_DATA_PLACEHOLDER,
} from '../../../../../../../../components/global/NoData/NoData';
import SkuAttributesTable, { SkuAttribute } from './SkuAttributesTable';

interface SkuInfoTableWrapperProps {
  $showActualData?: boolean;
}

const SkuInfoTableWrapper = styled.div<SkuInfoTableWrapperProps>`
  flex-grow: 1;
  min-height: 350px;

  padding: ${computeRemSize(8)};
  border: ${computeRemSize(1)} solid lightgrey;
  box-shadow: inset 0 ${computeRemSize(2)} ${computeRemSize(10)} #f5f5f5;
  background-color: ${COLORS.GREY_LIGHT};
  border-radius: 0.4em;

  td.ant-table-cell {
    vertical-align: top;
  }

  .ant-table-thead .ant-table-cell {
    background-color: transparent;
    font-size: 1.2rem;
    font-weight: bold;
  }

  .ant-table {
    background: transparent;
  }

  .ant-table-thead
    .ant-table-cell:not(:last-child):not(.ant-table-selection-column):not(
      .ant-table-row-expand-icon-cell
    ):not([colspan])::before {
    width: 0;
  }

  #SkuInfoHeaderCell-value {
    ${props => props.$showActualData && `color: ${COLORS.PRIMARY};`}
  }
`;

const ActualDataWrapper = styled.div`
  display: inline-block;
  color: ${COLORS.PRIMARY};
`;

export interface SkuInfoData {
  label: string;
  configuration: React.ReactNode;
  value?: React.ReactNode;
}

interface SkuInfoProps {
  data: SkuInfoData[];
  skuClass?: SkuClassResponse;
  requestList?: RequestListSkuResponse;
  showActualData?: boolean;
}

const SkuInfo: React.FC<SkuInfoProps> = ({ data, skuClass, requestList, showActualData }) => {
  const rowKeyProvider = useCallback((record: SkuInfoData) => record.label, []);

  const columnsAttributes: ColumnsType<SkuInfoData> = useMemo(
    () => [
      {
        dataIndex: 'label',
        key: 'label',
        title: 'Settings',
        width: '18rem',
        render: (value: string) => <Label>{value}</Label>,
      },
      {
        dataIndex: 'configuration',
        key: 'configuration',
        title: 'Configuration',
        width: '11rem',
      },
      {
        dataIndex: 'value',
        key: 'value',
        title: 'Value',
        render: (value: React.ReactNode) =>
          showActualData ? <ActualDataWrapper>{value}</ActualDataWrapper> : value,
        onHeaderCell: () => ({
          id: 'SkuInfoHeaderCell-value',
        }),
      },
    ],
    [showActualData],
  );

  const attributes = useMemo(() => {
    const result: SkuAttribute[] = [
      {
        label: 'SKU class',
        value: skuClass?.name ?? <NoData />,
        secondaryValue: skuClass?.customerSkuClassId?.toString() ?? <NoData />,
      },
    ];

    if (requestList) {
      result.push({
        label: 'Request List',
        value: requestList.name ?? <NoData />,
        secondaryValue: `${requestList.quantity ?? NO_DATA_PLACEHOLDER} MU`,
      });
    }

    return result;
  }, [skuClass?.customerSkuClassId, skuClass?.name, requestList]);

  return (
    <SkuInfoTableWrapper $showActualData={showActualData} data-type="SkuInfoWrapper">
      <Column $gap="1rem">
        <SkuAttributesTable attributes={attributes} />
        <SimpleTable
          id="SkuInfo"
          dataSource={data}
          columns={columnsAttributes}
          showHeader={true}
          rowKey={rowKeyProvider}
        />
      </Column>
    </SkuInfoTableWrapper>
  );
};

export default SkuInfo;
