import {
  ApiOperationType,
  CalculationResponse,
  Condition,
  Operation,
  SortDirection,
  Sorting,
  TargetListCreateRequest as RequestListCreateRequest,
  TargetListItemResponse as RequestListItemResponse,
  TargetListResponse as RequestListResponse,
} from '@ydistri/api-sdk';
import { apiSlice, ErrorType } from '../../../apis/api';
import { RequestListsCollections } from '../../../swagger/collections';
import { signalrClient } from '../../../signalr/client';
import {
  refreshRequestListContent,
  setSelectedRequestList,
} from './requestListAdministrationSlice';
import { ReduxState } from '../../../store';
import { addToast } from '@ydistri/utils';
import { SignalProjectConfigurationChanged } from '../common/administrationItemsTypes';
import {
  forceRefetchForInfiniteScroll,
  InfiniteScrollParams,
  mergeForInfiniteScroll,
  serializeQueryArgsForInfiniteScroll,
} from '@ydistri/ds';
import { getTags } from '../../../apis/apiLib';
import { SignalConfigurationChanged } from '../../../signalr/signalrInterfaces';

const { TAGS, TAGS_ARRAY } = getTags('requestLists');

export type RequestListSetArchiveRequest = { requestListId: number } & Pick<
  RequestListResponse,
  'isArchived'
>;
export type RequestListUpdateRequest = { requestListId: number } & Partial<
  Pick<RequestListResponse, 'name'>
>;

export interface RequestListRequest {
  sortings?: Sorting[];
  conditions?: Condition[];
}

export const defaultRequestListParams: RequestListRequest = {
  sortings: [{ fieldName: 'CreatedAt', direction: SortDirection.Desc }],
};

export interface RequestListSkusRequest extends InfiniteScrollParams {
  requestListId: number;
}

export const apiRequestLists = apiSlice
  .enhanceEndpoints({
    addTagTypes: TAGS_ARRAY,
  })
  .injectEndpoints({
    endpoints: builder => ({
      getRequestLists: builder.query<RequestListResponse[], RequestListRequest>({
        queryFn: async ({ sortings, conditions }) => {
          const data = await RequestListsCollections.targetListsList({
            sortings,
            conditions,
          });
          return data.data;
        },
        providesTags: (result, error, arg) => [
          {
            type: TAGS.requestListResponse,
            id: `${JSON.stringify(arg.conditions)};${JSON.stringify(arg.sortings)}`,
          },
        ],
        async onCacheEntryAdded(arg, api) {
          const signalerListener = (signal: SignalProjectConfigurationChanged) => {
            if (signal.entityType !== 'TargetList') return;

            api.dispatch(apiRequestLists.util.invalidateTags([TAGS.requestListResponse]));

            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- we can't type it better
            const state = api.getState() as ReduxState;

            //if the currently selected request list was deleted,
            //we need to clear the selected request list
            const selectedRequestList = state.requestListAdministration.selectedRequestList;
            if (selectedRequestList && signal.entityId === selectedRequestList.targetListId) {
              if (signal.operationType === ApiOperationType.Delete) {
                api.dispatch(setSelectedRequestList(undefined));
                api.dispatch(
                  addToast({
                    message: 'Request list has been deleted',
                  }),
                );
              }
            }

            if (signal.operationType === ApiOperationType.Patch) {
              api.dispatch(refreshRequestListContent());
            }
          };

          /**
           * React to changes in the template configuration when request list is set to or removed from a template.
           * Based on this change the request list might be marked as archivable or not and list of templates
           * where it is used will change.
           * The listener fetches the request list from the server and updates the cache.
           * @param signal
           */
          const templateConfigurationListener = (signal: SignalConfigurationChanged) => {
            if (signal.entityType === 'TargetListConfiguration') {
              const requestListId = signal.entityId;
              RequestListsCollections.targetListsList({
                conditions: [
                  {
                    fieldName: 'TargetListId',
                    operation: Operation.Eq,
                    value: requestListId,
                  },
                ],
              })
                .then(response => {
                  try {
                    const tmpRequestList = response.data.data[0];
                    api.updateCachedData(data => {
                      const requestListIndex = data.findIndex(
                        tl => tl.targetListId === tmpRequestList.targetListId,
                      );
                      if (requestListIndex !== -1) {
                        data[requestListIndex] = tmpRequestList;
                        return data;
                      }
                    });
                  } catch (error) {
                    // eslint-disable-next-line no-console -- we want the output here
                    console.error(error);
                    api.dispatch(
                      addToast({
                        message: `Failed to update the Request List, refresh the page.`,
                        isError: true,
                      }),
                    );
                  }
                })
                // eslint-disable-next-line @typescript-eslint/use-unknown-in-catch-callback-variable -- we want the type here
                .catch((error: ErrorType) => {
                  api.dispatch(
                    addToast({
                      message: `Failed to load Request List: ${error.response.data.Messages.join(
                        ', ',
                      )}`,
                      isError: true,
                    }),
                  );
                });
            }
          };

          try {
            await api.cacheDataLoaded;
            signalrClient.on('ProjectConfigurationChanged', signalerListener);
            signalrClient.on('TemplateConfigurationChanged', templateConfigurationListener);

            api.cacheEntryRemoved.then(() => {
              signalrClient.off('ProjectConfigurationChanged', signalerListener);
              signalrClient.off('TemplateConfigurationChanged', templateConfigurationListener);
            });
            await api.cacheEntryRemoved;
          } catch (error) {
            // eslint-disable-next-line no-console -- we want the output here
            console.error(error);
          }
        },
      }),
      createRequestList: builder.mutation<RequestListResponse, RequestListCreateRequest>({
        queryFn: async payload => {
          try {
            const response = await RequestListsCollections.createTargetList({
              name: payload.name,
            });
            const newRequestList = response.data.data;

            return { data: newRequestList };
          } catch (error) {
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- cannot be typed differently
            const err = error as ErrorType;
            return { error: err };
          }
        },
        invalidatesTags: [TAGS.requestListResponse],
      }),
      deleteRequestList: builder.mutation<number, number>({
        queryFn: async (requestListId: number) => {
          await RequestListsCollections.deleteTargetList(requestListId);
          return { data: requestListId };
        },
        invalidatesTags: [TAGS.requestListResponse],
      }),
      setArchived: builder.mutation<RequestListResponse, RequestListSetArchiveRequest>({
        queryFn: async (request: RequestListSetArchiveRequest) => {
          const response = await RequestListsCollections.updateTargetList(request.requestListId, {
            isArchived: request.isArchived,
          });
          return { data: response.data.data };
        },
        invalidatesTags: [TAGS.requestListResponse],
      }),
      updateRequestList: builder.mutation<
        RequestListResponse | undefined,
        RequestListUpdateRequest
      >({
        queryFn: async (request: RequestListUpdateRequest) => {
          let result: RequestListResponse | undefined = undefined;

          if (request.name) {
            const response = await RequestListsCollections.updateTargetList(request.requestListId, {
              name: request.name,
            });
            result = response.data.data;
          }

          return { data: result };
        },
        invalidatesTags: [TAGS.requestListResponse],
      }),
      getRequestListSkus: builder.query<RequestListItemResponse[], RequestListSkusRequest>({
        queryFn: async args => {
          const query = {
            top: args.top,
            skip: args.skip,
            inlineCount: true,
            sortings: args.sortings,
            conditions: args.conditions,
            search: args.search,
          };
          const response = await RequestListsCollections.getSkus(args.requestListId, query);
          return { data: response.data.data };
        },
        providesTags: [TAGS.requestListSkuResponse],
        serializeQueryArgs: serializeQueryArgsForInfiniteScroll<RequestListSkusRequest>(),
        merge: mergeForInfiniteScroll<RequestListItemResponse, InfiniteScrollParams>(),
        forceRefetch: forceRefetchForInfiniteScroll<InfiniteScrollParams | undefined>(),
      }),
      getRequestListCalculations: builder.query<CalculationResponse[], number>({
        queryFn: async requestListId => {
          const response = await RequestListsCollections.getTargetListCalculations(requestListId, {
            sortings: [
              {
                fieldName: 'Title',
                direction: SortDirection.Asc,
              },
            ],
          });

          return { data: response.data.data };
        },
        providesTags: [TAGS.requestListCalculations],
      }),
    }),
  });

export const {
  useGetRequestListsQuery,
  useCreateRequestListMutation,
  useDeleteRequestListMutation,
  useSetArchivedMutation,
  useUpdateRequestListMutation,
  useGetRequestListSkusQuery,
  useGetRequestListCalculationsQuery,
} = apiRequestLists;
