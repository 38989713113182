import React, { useEffect } from 'react';
import { TargetListResponse as RequestListResponse } from '@ydistri/api-sdk';
import { Column, ReadOnlyItem } from '@ydistri/ds';
import { useGetRequestListCalculationsQuery } from '../apiRequestLists';
import ListCalculationsWrapper from '../../common/usage/ListCalculationsWrapper';

interface RequestListDetailsProps {
  requestList: RequestListResponse;
}

/** Common width for the labels to have the data aligned */
const LABEL_WIDTH = '6rem';

/**
 * Details of a request list like title, id etc.
 * @param requestList
 * @constructor
 */
const RequestListDetails: React.FC<RequestListDetailsProps> = ({ requestList }) => {
  const {
    data: requestListCalculations = [],
    isFetching,
    refetch: refetchRequestListCalculations,
  } = useGetRequestListCalculationsQuery(requestList.targetListId);

  useEffect(() => {
    refetchRequestListCalculations();
  }, [refetchRequestListCalculations, requestList]);

  return (
    <Column $gap="1rem" data-type="RequestListDetails">
      <ReadOnlyItem
        label="System Id"
        value={requestList.customerProductListId}
        labelWidth={LABEL_WIDTH}
      />
      <ListCalculationsWrapper
        calculations={requestListCalculations}
        fetching={isFetching}
        labelWidth={LABEL_WIDTH}
      />
    </Column>
  );
};

export default RequestListDetails;
