import { css, styled } from 'styled-components';
import React, { CSSProperties, useCallback } from 'react';
import { Table, TableProps } from 'antd';
import { COLORS, computeRemSize, STYLE } from '@ydistri/ds';

export interface SimpleTableDivider {
  divider: boolean;
  key: string;
}

const TableWrapper = styled.div<{
  $id: string;
  $showBorder: boolean;
  $hideSelectionColumn?: boolean;
  $height?: CSSProperties['height'];
  $minRowHeight?: CSSProperties['height'];
}>`
  ul.ant-table-pagination-left {
    padding: 0.5rem;
    background-color: ${COLORS.GREY_BORDER};

    .ant-pagination-total-text {
      width: calc(50% - ${computeRemSize(200)});
    }
  }

  #${props => props.$id} {
    pre {
      margin: 0;
    }

    .ant-table-body,
    .ant-table-content {
      ${props =>
        props.$height !== undefined
          ? css`
              overflow-y: scroll;
              max-height: ${props.$height};
              ${STYLE.SCROLLBAR}
            `
          : css`
              overflow-y: auto;
            `}
      .selected {
        background-color: ${props => props.theme.item.selected.backgroundColor};
      }
    }

    .highlight-selected {
      background-color: ${props => props.theme.item.hovered.backgroundColor};

      & td:first-child {
        border-left: ${computeRemSize(3)} solid ${COLORS.PRIMARY};
      }
    }

    td {
      ${props =>
        props.$minRowHeight !== undefined &&
        css`
          height: ${props.$minRowHeight};
          min-height: ${props.$minRowHeight};
        `}
    }

    .bold td {
      font-weight: bold;
    }

    tr.ant-table-expanded-row {
      & > td.ant-table-cell {
        padding-right: 0 !important;
      }
    }

    .add-left-border td:first-child {
      border-left: ${computeRemSize(3)} solid transparent;
    }

    td.ant-table-cell:not(.ant-table-cell-scrollbar),
    th.ant-table-cell {
      padding: 0.25rem !important;
      padding-right: 0.75rem !important;
      ${p =>
        !p.$showBorder &&
        css`
          border-bottom: none !important;
        `};
    }

    td.ant-table-selection-column {
      ${props => props.$hideSelectionColumn && 'width: 0; padding: 0 !important;'}
    }

    tr.green:not(.ant-table-row-selected) td,
    tr:not(.ant-table-row-selected) td.green {
      background-color: ${COLORS.TABLE_HIGHLIGHT_GREEN};
    }

    tr.blue:not(.ant-table-row-selected) td,
    tr:not(.ant-table-row-selected) td.blue {
      background-color: ${COLORS.TABLE_HIGHLIGHT_BLUE};
    }

    tr.orange:not(.ant-table-row-selected) td,
    tr:not(.ant-table-row-selected) td.orange {
      background-color: ${COLORS.TABLE_HIGHLIGHT_ORANGE};
    }

    tr.red:not(.ant-table-row-selected) td,
    tr:not(.ant-table-row-selected) td.red {
      background-color: ${COLORS.TABLE_HIGHLIGHT_RED};
    }

    tr.grey:not(.ant-table-row-selected) td,
    tr:not(.ant-table-row-selected) td.grey {
      background-color: ${COLORS.TABLE_HIGHLIGHT_GREY};
    }

    tr.green:hover td,
    tr.orange:hover td,
    tr.red:hover td {
      filter: brightness(0.95);
    }

    .divider td {
      border-bottom: ${computeRemSize(1)} solid #e3e3e3 !important;
    }
  }
`;

interface SimpleTableProps<T> extends TableProps<T> {
  id: string;
  showBorder?: boolean;
  showHeader?: boolean;
  hideSelectionColumn?: boolean;
  height?: CSSProperties['height'];
  minRowHeight?: CSSProperties['height'];
}

export const SimpleTable = <T extends object>({
  id,
  showBorder = false,
  showHeader = false,
  hideSelectionColumn = false,
  height,
  minRowHeight,
  ...rest
}: SimpleTableProps<T>): React.ReactElement => {
  const getRowClassName = useCallback((data: T) => {
    if ('divider' in data) {
      return `divider`;
    }
    return '';
  }, []);

  return (
    <TableWrapper
      $id={id}
      $showBorder={showBorder}
      $hideSelectionColumn={hideSelectionColumn}
      $height={height}
      $minRowHeight={minRowHeight}
    >
      <Table<T>
        id={id}
        pagination={false}
        showHeader={showHeader}
        rowClassName={getRowClassName}
        {...rest}
      />
    </TableWrapper>
  );
};
