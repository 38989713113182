import { ColumnsType } from 'antd/es/table';
import {
  CalculationPickingResponse,
  EvaluationRatesResponse,
  PapProgressStatus,
  RoutesEvaluationRatesResponse,
  SourceStorePickingResultResponse,
  StorePickingInsightsResponse,
} from '@ydistri/api-sdk';
import { computeRemSize, OutlierValue, YColumnsType } from '@ydistri/ds';
import { formatNumber, formatTimeDuration } from '@ydistri/utils';

export enum PickingResultsPart {
  EXECUTION = 'execution',
  PERFORMANCE = 'performance',
  ISSUES = 'issues',
}

export type PickingResultsExtended = Partial<EvaluationRatesResponse> &
  Partial<RoutesEvaluationRatesResponse> & {
    executedQuantityPercentage: number;
    executedValuePercentage: number;
    executedSkuCountPercentage: number;
  };

export type SourceStorePickingResultExtendedResponse = Pick<
  SourceStorePickingResultResponse,
  'store' | 'progressStatus' | 'department'
> & {
  pickingResults: PickingResultsExtended;
};

export type CalculationPickingExtendedResponse = Pick<
  CalculationPickingResponse,
  'calculationCreatedAt' | 'pickingStatus' | 'finishExecutionUntil'
> & {
  pickingResults: PickingResultsExtended;
};

const statusMap: Record<PapProgressStatus, string> = {
  [PapProgressStatus.NotStarted]: 'Not started',
  [PapProgressStatus.PickingStarted]: 'Started',
  [PapProgressStatus.PickingDone]: 'Picking completed',
  [PapProgressStatus.Finalized]: 'Finalized',
};

export const translatePapProgressStatus = (status: PapProgressStatus): string => {
  return statusMap[status] || 'Unknown status';
};

export const evaluationsRowClassName = (
  rowObject: SourceStorePickingResultExtendedResponse,
): string => {
  if (rowObject.progressStatus === PapProgressStatus.Finalized) return 'green';
  if (rowObject.progressStatus === PapProgressStatus.NotStarted) return 'red';
  return 'orange';
};

const PICKING_RESULTS_PROPERTY = 'pickingResults';

function papProgressStatusRank(status: PapProgressStatus): number {
  switch (status) {
    case PapProgressStatus.NotStarted:
      return 0;
    case PapProgressStatus.PickingStarted:
      return 1;
    case PapProgressStatus.PickingDone:
      return 2;
    case PapProgressStatus.Finalized:
      return 3;
    default:
      return 4;
  }
}

export const getBasePickResultsColumns = (
  currency: string,
  storeColumns: YColumnsType<SourceStorePickingResultExtendedResponse>[],
  includeRoutes: boolean = true,
  colWidth: number = 150,
): ColumnsType<SourceStorePickingResultExtendedResponse> => {
  const columns: ColumnsType<SourceStorePickingResultExtendedResponse> = [...storeColumns];

  columns.push(
    {
      title: 'Picking Status',
      dataIndex: ['progressStatus'],
      key: 'progressStatus',
      width: computeRemSize(100),
      render: (value: PapProgressStatus) => translatePapProgressStatus(value),
      sorter: (a, b) =>
        papProgressStatusRank(a.progressStatus) - papProgressStatusRank(b.progressStatus),
      filters: [
        {
          text: 'Not started',
          value: PapProgressStatus.NotStarted,
        },
        {
          text: 'Started',
          value: PapProgressStatus.PickingStarted,
        },
        {
          text: 'Picking completed',
          value: PapProgressStatus.PickingDone,
        },
        {
          text: 'Finalized',
          value: PapProgressStatus.Finalized,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => {
        return record.progressStatus === value;
      },
    },
    {
      title: `Total Value [${currency}]`,
      dataIndex: [PICKING_RESULTS_PROPERTY, 'totalValue'],
      key: 'totalValue',
      width: computeRemSize(colWidth),
      sorter: (a, b) => (a.pickingResults.totalValue ?? 0) - (b.pickingResults.totalValue ?? 0),
      render: (value: number) => formatNumber(value, 0),
      align: 'right',
    },
    {
      title: `Executed Value [${currency}]`,
      dataIndex: [PICKING_RESULTS_PROPERTY, 'executedValue'],
      key: 'executedValue',
      width: computeRemSize(160),
      sorter: (a, b) =>
        (a.pickingResults.executedValue ?? 0) - (b.pickingResults.executedValue ?? 0),
      render: (value: number) => formatNumber(value, 0),
      align: 'right',
    },
    {
      title: 'Executed Value [%]',
      dataIndex: [PICKING_RESULTS_PROPERTY, 'executedValuePercentage'],
      key: 'executedValuePercentage',
      width: computeRemSize(colWidth),
      sorter: (a, b) =>
        a.pickingResults.executedValuePercentage - b.pickingResults.executedValuePercentage,
      defaultSortOrder: 'ascend',
      render: (value: number) => formatNumber(value, 0),
      align: 'right',
    },
    {
      title: `Total Picking Positions`,
      dataIndex: [PICKING_RESULTS_PROPERTY, 'totalSkuCount'],
      key: 'totalSkuCount',
      width: computeRemSize(colWidth),
      sorter: (a, b) =>
        (a.pickingResults.totalSkuCount ?? 0) - (b.pickingResults.totalSkuCount ?? 0),
      align: 'right',
    },
    {
      title: `Executed Picking Positions`,
      dataIndex: [PICKING_RESULTS_PROPERTY, 'executedSkuCount'],
      key: 'executedSkuCount',
      width: computeRemSize(colWidth),
      sorter: (a, b) =>
        (a.pickingResults.executedSkuCount ?? 0) - (b.pickingResults.executedSkuCount ?? 0),
      align: 'right',
    },
    {
      title: 'Executed Pick. Pos. [%]',
      dataIndex: [PICKING_RESULTS_PROPERTY, 'executedSkuCountPercentage'],
      key: 'executedSkuCountPercentage',
      width: computeRemSize(110),
      sorter: (a, b) =>
        a.pickingResults.executedSkuCountPercentage - b.pickingResults.executedSkuCountPercentage,
      align: 'right',
    },
  );

  if (includeRoutes) {
    columns.push(
      {
        title: `Total Routes`,
        dataIndex: [PICKING_RESULTS_PROPERTY, 'totalRouteCount'],
        key: 'totalRouteCount',
        width: computeRemSize(colWidth),
        sorter: (a, b) =>
          'totalRouteCount' in a.pickingResults && 'totalRouteCount' in b.pickingResults
            ? (a.pickingResults.totalRouteCount ?? 0) - (b.pickingResults.totalRouteCount ?? 0)
            : 0,
        render: (value: number) => formatNumber(value, 0),
        align: 'right',
      },
      {
        title: `Executed Routes`,
        dataIndex: [PICKING_RESULTS_PROPERTY, 'executedRouteCount'],
        key: 'executedRouteCount',
        width: computeRemSize(colWidth),
        sorter: (a, b) =>
          'executedRouteCount' in a.pickingResults && 'executedRouteCount' in b.pickingResults
            ? (a.pickingResults.executedRouteCount ?? 0) -
              (b.pickingResults.executedRouteCount ?? 0)
            : 0,
        render: (value: number) => formatNumber(value, 0),
        align: 'right',
      },
    );
  }

  return columns;
};

export const getPickInsightsColumns = (
  storeColumns: YColumnsType<StorePickingInsightsResponse>[],
): ColumnsType<StorePickingInsightsResponse> => {
  const columns: YColumnsType<StorePickingInsightsResponse>[] = [...storeColumns];

  columns.push(
    {
      title: 'Executed Quantity',
      dataIndex: 'executedQuantity',
      key: 'executedQuantity',
      width: computeRemSize(160),
      align: 'right',
      sorter: (a, b) => a.executedQuantity - b.executedQuantity,
    },
    {
      title: 'Executed Value',
      dataIndex: 'executedValue',
      key: 'executedValue',
      width: computeRemSize(160),
      align: 'right',
      render: (value: number) => formatNumber(value),
      sorter: (a, b) => a.executedValue - b.executedValue,
    },
    {
      title: 'Picking Positions',
      dataIndex: 'pickingPositionCount',
      key: 'pickingPositionCount',
      width: computeRemSize(100),
      align: 'right',
      sorter: (a, b) => a.pickingPositionCount - b.pickingPositionCount,
    },
    {
      title: 'Total Picking Time',
      dataIndex: 'totalPickingTime',
      key: 'totalPickingTime',
      width: computeRemSize(120),
      align: 'right',
      render: (value: number, record) => {
        const renderedValue = formatTimeDuration(value, ['hours', 'minutes']);
        if (value !== record.trimmedPickingTime) {
          return (
            <OutlierValue
              value={renderedValue}
              tooltip="Some of the pickings took more than 20 minutes and were removed in the trimmed column"
            />
          );
        } else {
          return renderedValue;
        }
      },
      sorter: (a, b) => a.totalPickingTime - b.totalPickingTime,
    },
    {
      title: 'Total Picking Time (trimmed)',
      dataIndex: 'trimmedPickingTime',
      key: 'trimmedPickingTime',
      width: computeRemSize(160),
      align: 'right',
      render: (value: number) => formatTimeDuration(value, ['hours', 'minutes']),
      sorter: (a, b) => a.trimmedPickingTime - b.trimmedPickingTime,
    },
    {
      title: 'Average Picking Time (trimmed)',
      dataIndex: 'trimmedMeanPickingTime',
      key: 'trimmedMeanPickingTimePerSku',
      width: computeRemSize(230),
      align: 'right',
      render: (value: number) => formatTimeDuration(value),
      sorter: (a, b) => a.trimmedMeanPickingTime - b.trimmedMeanPickingTime,
    },
    {
      title: 'Median Picking Time (trimmed)',
      dataIndex: 'trimmedMedianPickingTime',
      key: 'trimmedMedianPickingTime',
      width: computeRemSize(230),
      align: 'right',
      render: (value: number) => formatTimeDuration(value),
      sorter: (a, b) => a.trimmedMedianPickingTime - b.trimmedMedianPickingTime,
    },
  );

  return columns;
};
