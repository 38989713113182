import React, { useEffect } from 'react';
import { ProductListResponse } from '@ydistri/api-sdk';
import { Column, ReadOnlyItem } from '@ydistri/ds';
import ListCalculationsWrapper from '../../common/usage/ListCalculationsWrapper';
import { useGetProductListCalculationsQuery } from '../apiProductLists';

interface ProductListDetailsProps {
  productList: ProductListResponse;
}

/** Common width for the labels to have the data aligned */
const LABEL_WIDTH = '6rem';

/**
 * Details of a target list like title, id etc.
 * @param targetList
 * @constructor
 */
const ProductListDetails: React.FC<ProductListDetailsProps> = ({ productList }) => {
  const {
    data: productListCalculations = [],
    isFetching,
    refetch: refetchTargetListCalculations,
  } = useGetProductListCalculationsQuery(productList.productListId);

  useEffect(() => {
    refetchTargetListCalculations();
  }, [refetchTargetListCalculations, productList]);

  return (
    <Column $gap="1rem" data-type="RequestListDetails">
      <ReadOnlyItem
        label="System Id"
        value={productList.customerProductListId}
        labelWidth={LABEL_WIDTH}
      />
      <ListCalculationsWrapper
        calculations={productListCalculations}
        fetching={isFetching}
        labelWidth={LABEL_WIDTH}
      />
    </Column>
  );
};

export default ProductListDetails;
