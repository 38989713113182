import { Column, MainContent, MainContentWrapper } from '@ydistri/ds';
import { Helmet } from 'react-helmet-async';
import CategoryTree from '../../components/global/CategoryTree/CategoryTree';
import React, { useCallback, useEffect } from 'react';
import { URLSearchParamsInit, useLocation, useSearchParams } from 'react-router';
import { UrlParams } from './detailTypes';
import { RoutingPage, setPage } from '../../routes/routerSlice';
import { useAppDispatch } from '../../store';
import DetailProductsListSection from './sections/DetailProductListSection/DetailProductsListSection';
import { ProductResponse } from '@ydistri/api-sdk';
import { useDetail } from './hooks/useDetail';
import { getSku, getSkuById, makeSKUAttributesStorable } from './detailLib';
import {
  resetBusyStates,
  resetSku,
  resetStateWithBusy,
  setDetailBusy,
  setIsLoadingSalesData,
  setProductId,
  setRegionId,
  setSkuEntity,
  setSkuId,
  setStoreId,
  skuFound,
  skuNotFound,
} from './detailSlice';
import { NO_VALUE } from '../../lib/utils/utilsTypes';
import { createDebugLog } from '../../lib/utils/logging';
import DetailContent from './sections/components/DetailContent';
import { SkusCollection } from '../../swagger/collections';
import { parseSkuAttributes } from '../../lib/sku/skuLib';
import { addToast } from '@ydistri/utils';
import { SkuAttrsStored } from '../../lib/sku/skuTypes';
import LoadingIndicator from '../../components/global/LoadingIndicator/LoadingIndicator';

const searchParamModifier = (searchString: string) => {
  const searchParams = new URLSearchParams(searchString);
  searchParams.delete(UrlParams.PRODUCT);
  searchParams.delete(UrlParams.STORE);
  searchParams.delete(UrlParams.REGION);
  searchParams.delete(UrlParams.SKU);
  return `?${searchParams.toString()}`;
};

const debugLog = createDebugLog('Detail');

/**
 * Returns true if the productID and storeId in the skuEntity are the same as the given values
 * @param skuEntity
 * @param productId
 * @param storeId
 */
const isSameSkuEntity = (
  skuEntity: SkuAttrsStored | undefined,
  productId: number,
  storeId: number,
): boolean => {
  if (skuEntity) {
    return (
      skuEntity.values.ProductId === productId.toString() &&
      skuEntity.values.WarehouseId === storeId.toString()
    );
  } else {
    return false;
  }
};

/**
 * Detail screen component.
 * Displays the category tree, product list and detail content.
 * There are event handlers from child components that set search params in the URL
 * and one useEffect that reacts to changes in the URL. This is done so because other parts
 * of the app link to this screen in the form of URL parameters and the screen must read the URL
 * parameters and act accordingly.
 * Also, users are free to bookmark URL for specific product, sku, store or region.
 * @constructor
 */
const Detail: React.FC = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();

  // current store, sku and region values from redux store
  const { productId, storeId, skuId, regionId, isDetailBusy, skuEntity } = useDetail(
    'productId',
    'storeId',
    'skuId',
    'regionId',
    'isDetailBusy',
    'skuEntity',
  );

  // URL search params (the part after the ? in the URL)
  const [searchParams, setSearchParams] = useSearchParams();
  const searchQuery = searchParams.get(UrlParams.SEARCH) ?? undefined;

  useEffect(() => {
    dispatch(setPage(RoutingPage.DETAIL));
    dispatch(resetStateWithBusy(true));
  }, [dispatch]);

  /**
   * This effect is reads the URL search params and acts accordingly.
   * There are various search params that can be present in the URL -
   * product id, sku id, region, store and search query.
   * The individual id values are stored in the redux store, where they are
   * picked up by individual components to display data.
   */
  useEffect(() => {
    const hasProduct = searchParams.has(UrlParams.PRODUCT);
    const hasSku = searchParams.has(UrlParams.SKU);
    const hasStore = searchParams.has(UrlParams.STORE);

    let controller: AbortController | null = null;
    let signal: AbortSignal | null = null;

    if (hasProduct && !hasSku) {
      //when clicking on a product or getting here from Redistribution by clicking on Product's link
      debugLog('has product and no SKU', searchParams.get(UrlParams.PRODUCT));
      dispatch(setProductId(parseInt(searchParams.get(UrlParams.PRODUCT) ?? '')));
      dispatch(setDetailBusy(false));
    }

    // if SKU is present in the URL and is different from current skuID,
    // fetch the SKU data and store it in the redux store
    // when searching for SKU or getting here from Redistribution by clicking on source or target SKU's link
    if (hasSku) {
      const querySkuId = parseInt(searchParams.get(UrlParams.SKU) ?? '');
      if (querySkuId !== skuId) {
        debugLog('Fetching SKU for skuId', querySkuId);
        controller = new AbortController();
        signal = controller.signal;
        dispatch(setIsLoadingSalesData(true));
        getSkuById(querySkuId, signal).then(sku => {
          debugLog('Fetch finished');
          if (sku) {
            dispatch(skuFound(sku));
          } else {
            dispatch(skuNotFound(querySkuId));
          }
        });
      }
    } else if (hasStore && hasProduct) {
      const queryStoreId = parseInt(searchParams.get(UrlParams.STORE) ?? '');
      const queryProductId = parseInt(searchParams.get(UrlParams.PRODUCT) ?? '');

      controller = new AbortController();
      signal = controller.signal;
      if (!isSameSkuEntity(skuEntity, queryProductId, queryStoreId)) {
        dispatch(setIsLoadingSalesData(true));

        getSku(productId, storeId, signal).then(sku => {
          if (sku) {
            if (skuId !== sku.skuId) {
              SkusCollection.getSkuAttributes(sku.skuId)
                .then(({ data }) => {
                  const attributes = parseSkuAttributes(sku.skuId, data.data);
                  dispatch(setSkuEntity(makeSKUAttributesStorable(attributes)));
                  dispatch(setSkuId(sku.skuId));
                  dispatch(setProductId(sku.productId));
                  dispatch(setStoreId(sku.storeId));
                })
                .catch(() => {
                  dispatch(addToast({ message: 'SKU was not found', isError: true }));
                })
                .finally(() => dispatch(resetBusyStates()));
            } else {
              dispatch(resetBusyStates());
            }
          } else {
            dispatch(skuNotFound());
            dispatch(resetBusyStates());
          }
        });
      }
    }

    if (!hasSku && !hasProduct) {
      dispatch(setProductId(NO_VALUE));
    }

    // read region from URL and set in redux store or reset it if not present in URL
    if (searchParams.has(UrlParams.REGION)) {
      const paramRegion = searchParams.get(UrlParams.REGION);
      if (paramRegion) {
        const regionId = parseInt(paramRegion);
        if (!Number.isNaN(regionId)) {
          dispatch(setRegionId(regionId));
          dispatch(setDetailBusy(false));
          dispatch(setIsLoadingSalesData(true));
        }
      }
    } else {
      if (regionId !== NO_VALUE) {
        dispatch(setRegionId(NO_VALUE));
      }
    } // end of region reading

    // read store from URL and set in redux store or reset it if not present in URL
    if (searchParams.has(UrlParams.STORE)) {
      const paramStore = searchParams.get(UrlParams.STORE);
      if (paramStore) {
        const storeId = parseInt(paramStore);
        if (!Number.isNaN(storeId)) {
          dispatch(setStoreId(storeId));
          dispatch(setDetailBusy(false));
          dispatch(setIsLoadingSalesData(true));
        }
      }
    } else {
      if (storeId !== NO_VALUE && !hasSku) {
        dispatch(setStoreId(NO_VALUE));
      }
    } // end of store reading

    // other cases to reset busy states are handled above
    if (
      searchParams.size === 0 ||
      (searchParams.size === 1 && searchParams.has(UrlParams.SEARCH))
    ) {
      dispatch(resetBusyStates());
    }

    // abort the sku fetch request if the component is unmounted
    return () => {
      if (controller !== null) {
        debugLog('Aborting request');
        controller.abort();
      }
    };
  }, [dispatch, productId, regionId, searchParams, skuEntity, skuId, storeId]);

  /**
   * Callback from the Category list component.
   * If the selected category is the same as current category,
   * prevent the default behavior of the link and do not execute it.
   */
  const categoryChangeHandler = useCallback(
    (event: React.MouseEvent, newCategorySlug: string) => {
      const pathParts = location.pathname.split('/');
      const currentSlug = pathParts.reverse()[0];
      // Link by default pushes the same location to the history stack
      // If the category is the same as the current one, prevent that default behavior
      if (currentSlug === newCategorySlug) {
        event.preventDefault();
      }
    },
    [location.pathname],
  );

  /**
   * Callback to search for a product by name.
   * This function modifies the URL search params to reflect the search query.
   * When a search query is empty, the search param is removed from the URL.
   */
  const onProductSearch = useCallback(
    (query: string): void => {
      setSearchParams(prevParams => {
        if (query.length > 0) {
          prevParams.set(UrlParams.SEARCH, query);
        } else {
          prevParams.delete(UrlParams.SEARCH);
        }
        return prevParams;
      });
    },
    [setSearchParams],
  );

  /**
   * Callback to search for a SKU by its ID.
   * This function modifies the URL search params to reflect the search query.
   * When a SKU is set, other search params must be removed from URL (PRODUCT, STORE, SEARCH).
   */
  const onSkuSearch = useCallback(
    (skuId: number): void => {
      dispatch(resetStateWithBusy(true));

      setSearchParams((prevParams): URLSearchParamsInit => {
        if (prevParams.has(UrlParams.PRODUCT)) {
          prevParams.delete(UrlParams.PRODUCT);
        }
        if (prevParams.has(UrlParams.STORE)) {
          prevParams.delete(UrlParams.STORE);
        }

        //to avoid having both sku and product in the url at once
        //thus causing multiple renders
        if (prevParams.has(UrlParams.SEARCH)) {
          prevParams.delete(UrlParams.SEARCH);
        }

        prevParams.set(UrlParams.SKU, skuId.toString());
        return prevParams;
      });
    },
    [dispatch, setSearchParams],
  );

  /**
   * Called when a product is selected/deselected from the list.
   * This function modifies the URL search parameters to reflect the selected product.
   * When a product is set, other search params must be removed from URL (SKU, REGION).
   */
  const onProductChanged = useCallback(
    (product: ProductResponse | null): void => {
      dispatch(setIsLoadingSalesData(true));

      if (product !== null) {
        // product is selected,
        setSearchParams((prevParams): URLSearchParamsInit => {
          const newProductValue = product.id.toString();
          prevParams.set(UrlParams.PRODUCT, newProductValue);
          if (prevParams.has(UrlParams.SKU)) {
            prevParams.delete(UrlParams.SKU);
            dispatch(resetSku());
          }

          //when product is selected, deselect the region as the region selector is hidden
          if (prevParams.has(UrlParams.REGION)) {
            prevParams.delete(UrlParams.REGION);
          }
          return prevParams;
        });
      } else {
        // product is deselected, remove product and SKU from URL
        setSearchParams((prevParams): URLSearchParamsInit => {
          if (prevParams.has(UrlParams.PRODUCT)) {
            prevParams.delete(UrlParams.PRODUCT);
          }
          if (prevParams.has(UrlParams.SKU)) {
            prevParams.delete(UrlParams.SKU);
          }
          return prevParams;
        });
        dispatch(setProductId(NO_VALUE));
      }
    },
    [dispatch, setSearchParams],
  );

  return (
    <>
      <Helmet title="Detail" />
      <MainContentWrapper>
        <CategoryTree
          displayExceptions={false}
          onCategoryChange={categoryChangeHandler}
          searchParamModifier={searchParamModifier}
        />
        <DetailProductsListSection
          onProductSearch={onProductSearch}
          onSkuSearch={onSkuSearch}
          onProductSelected={onProductChanged}
          searchQuery={searchQuery}
        />
        <MainContent>
          {!isDetailBusy ? (
            <DetailContent />
          ) : (
            <Column $padding={4}>
              <LoadingIndicator />
            </Column>
          )}
        </MainContent>
      </MainContentWrapper>
    </>
  );
};

export default Detail;
