import React, { useEffect, useMemo } from 'react';
import { useTemplateOrCalculation } from '../../../hooks/useTemplateOrCalculation';
import { useGetRequestListsForConfigurationQuery } from '../../Configuration/apiRequestListConfiguration';
import { setSubpage } from '../../../routes/routerSlice';
import { CalculationDetailSubpages } from '../../Calculations/calculationsLib';
import { Helmet } from 'react-helmet-async';
import { MainContent, MainContentWrapper, Section } from '@ydistri/ds';
import { Spin } from 'antd';
import SectionRequestList from './SectionRequestList';
import { useAppDispatch } from '../../../store';

const CalculationRequestListStatistics: React.FC = () => {
  const dispatch = useAppDispatch();
  const templateOrCalculation = useTemplateOrCalculation();

  const { data: requestListConfig, isFetching } =
    useGetRequestListsForConfigurationQuery(templateOrCalculation);

  const requestList = useMemo(() => {
    if (requestListConfig && requestListConfig.length > 0) {
      return requestListConfig[0];
    }
  }, [requestListConfig]);

  useEffect(() => {
    dispatch(setSubpage(CalculationDetailSubpages.REQUEST_LIST_STATISTICS));
  }, [dispatch]);

  return (
    <>
      <Helmet title={`Calculation ${templateOrCalculation.id} - Request List Statistics`} />
      <MainContentWrapper $flexDirection="column">
        <MainContent>
          {requestList && (
            <Section data-id="StatisticsRequestLists" id="StatisticsRequestListsSection">
              <Spin spinning={isFetching}>
                <SectionRequestList title={`Request list statistics - ${requestList.name ?? ''}`} />
              </Spin>
            </Section>
          )}
        </MainContent>
      </MainContentWrapper>
    </>
  );
};

export default CalculationRequestListStatistics;
