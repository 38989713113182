import React, { useCallback, useMemo } from 'react';
import { useTemplateOrCalculation } from '../../../../hooks/useTemplateOrCalculation';
import { useGetRequestListsForConfigurationQuery } from '../../apiRequestListConfiguration';
import { Popover, Spin } from 'antd';
import { styled } from 'styled-components';
import { MdContentPasteSearch, MdQueryStats } from 'react-icons/md';
import { AvailableModals, openModal } from '../../../Modals/modalSlice';
import { computeRemSize } from '@ydistri/ds';
import { useAppDispatch } from '../../../../store';

const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const ClickableIconWrapper = styled.div`
  cursor: pointer;
`;

const RequestListConfigurationInfo: React.FC = () => {
  const templateOrCalculation = useTemplateOrCalculation();
  const dispatch = useAppDispatch();

  const { data: requestListConfig, isFetching } =
    useGetRequestListsForConfigurationQuery(templateOrCalculation);

  const requestList = useMemo(() => {
    if (requestListConfig && requestListConfig.length > 0) {
      return requestListConfig[0];
    }
  }, [requestListConfig]);

  const openRequestListModal = useCallback(() => {
    if (requestList) {
      dispatch(
        openModal({
          type: AvailableModals.REQUEST_LIST_CONTENTS,
          data: {
            requestList: requestList,
          },
        }),
      );
    }
  }, [dispatch, requestList]);

  const icon: React.ReactNode = useMemo(() => {
    if (templateOrCalculation.type === 'Template') {
      return (
        <Popover content="Open contents of request list">
          <ClickableIconWrapper>
            <MdContentPasteSearch
              size={computeRemSize(20)}
              color="grey"
              onClick={openRequestListModal}
            />
          </ClickableIconWrapper>
        </Popover>
      );
    }
    return (
      <Popover content="Open statistics">
        <ClickableIconWrapper>
          <MdQueryStats size={computeRemSize(20)} color="grey" onClick={openRequestListModal} />
        </ClickableIconWrapper>
      </Popover>
    );
  }, [openRequestListModal, templateOrCalculation.type]);

  if (isFetching) {
    return <Spin spinning />;
  }
  if (!requestList) return null;

  return (
    <InfoWrapper>
      {`Item count: ${requestList.skuCount}`} {icon}{' '}
    </InfoWrapper>
  );
};

export default RequestListConfigurationInfo;
