import { Column, Row } from '@ydistri/ds';
import DetailContentHeaderSection from '../DetailContentHeaderSection/DetailContentHeaderSection';
import React, { useEffect } from 'react';
import { setIsLoadingSalesData } from '../../detailSlice';
import DetailContentBodySection from '../DetailContentBodySection/DetailContentBodySection';
import useCategoryId from '../../../../hooks/useCategoryId';
import { useAppDispatch } from '../../../../store';

const DetailContent: React.FC = () => {
  const dispatch = useAppDispatch();
  const selectedCategoryId = useCategoryId();

  useEffect(() => {
    dispatch(setIsLoadingSalesData(true));
  }, [dispatch, selectedCategoryId]);

  return (
    <Row $flexGrow={1} data-testid="DetailContent" $flexWrap="nowrap">
      <Column $flexGrow={1}>
        <DetailContentHeaderSection />
        <DetailContentBodySection />
      </Column>
    </Row>
  );
};

export default DetailContent;
