import { EntityListImportStep, setImportStep } from './entityListAdministrationSlice';
import { useCallback } from 'react';
import { useAppDispatch } from '../../../store';
import { EntityListTypeSupported } from './entityListsLib';

type SetImportStepCallback = (step: EntityListImportStep) => void;

export const useSetImportStep = (
  entityListType: EntityListTypeSupported,
): SetImportStepCallback => {
  const dispatch = useAppDispatch();

  return useCallback(
    (x: EntityListImportStep) => {
      dispatch(
        setImportStep({
          entityListType,
          data: x,
        }),
      );
    },
    [dispatch, entityListType],
  );
};
