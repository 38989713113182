import React from 'react';

import RequestListItemsSection from './detail/RequestListItemsSection';
import RequestListEditModeSection from './edit/RequestListEditModeSection';
import RequestListValidationSection from './validation/RequestListValidationSection';
import { ActiveScreenType } from '../../common/administrationItemsLib';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../../store';
import NoRequestListSelected from '../components/NoRequestListSelected';

/**
 * Main part of the screen for Request Lists. Shows different content based on current screen type.
 * @constructor
 */
const RequestListAdministrationContent: React.FC = () => {
  const activeScreenType = useSelector(
    (state: ReduxState) => state.requestListAdministration.activeScreenType,
  );

  switch (activeScreenType) {
    case ActiveScreenType.NO_SELECTION:
      return <NoRequestListSelected />;
    case ActiveScreenType.DETAIL:
      return <RequestListItemsSection />;
    case ActiveScreenType.EDIT:
      return <RequestListEditModeSection />;
    case ActiveScreenType.VALIDATION: {
      return <RequestListValidationSection />;
    }
    case ActiveScreenType.DONE: {
      throw new Error('Not implemented yet: ActiveScreenType.DONE case');
    }
  }
};

export default RequestListAdministrationContent;
