import {
  filterValidItems,
  getParseableString,
  getParserForContentType,
  validateRequestListContent,
} from './requestListsLib';
import {
  setHandlingMethod,
  setIsLoading,
  setRequestListItems,
  validationFinished,
} from './requestListAdministrationSlice';
import { useRequestListAdministrationState } from './useRequestListAdministrationState';
import { useCallback } from 'react';
import { IdentificationType } from '@ydistri/api-sdk';
import { AdministrationItemListHandlingMethod } from '../common/administrationItemsTypes';
import { markDuplicates } from '../common/administrationItemsLib';
import { addToast } from '@ydistri/utils';
import { RequestListItemsCompared, RequestListRow } from './requestListsTypes';
import { ErrorType } from '../../../apis/api';
import { useAppDispatch } from '../../../store';

export type ValidateItems = (newHandlingMethod?: AdministrationItemListHandlingMethod) => void;

const isAllParsed = (
  parsedTargetListItems: RequestListRow[],
  validation: RequestListItemsCompared,
) => {
  return filterValidItems(parsedTargetListItems).every(item => {
    const exactItemValidated = validation.items.findIndex(
      validatedItem =>
        validatedItem.inputData.productId === item.inputData.productId &&
        validatedItem.inputData.storeId === item.inputData.storeId &&
        validatedItem.inputData.count === item.inputData.count,
    );

    return exactItemValidated !== -1;
  });
};

const useValidateItems = (): ValidateItems => {
  const dispatch = useAppDispatch();
  const {
    validation,
    selectedRequestList,
    handlingMethod,
    warehouseIdentificationType,
    productIdentificationType,
    productItemsContent,
  } = useRequestListAdministrationState();

  return useCallback(
    (newHandlingMethod?: AdministrationItemListHandlingMethod) => {
      if (productItemsContent.length === 0) {
        return;
      }

      const parser = getParserForContentType('text/plain');
      if (!parser) {
        return;
      }

      const parsedRows = parser.parse(productItemsContent);
      if (!(parsedRows.length > 0 && selectedRequestList)) {
        return;
      }

      markDuplicates(parsedRows, (left, right) => {
        return (
          left.inputData.productId === right.inputData.productId &&
          left.inputData.storeId === right.inputData.storeId
        );
      });

      if (validation) {
        if (isAllParsed(parsedRows, validation)) {
          return;
        }
      }

      dispatch(setIsLoading(true));
      dispatch(setRequestListItems(parsedRows));

      validateRequestListContent(
        selectedRequestList,
        parsedRows,
        productIdentificationType,
        warehouseIdentificationType,
      )
        .then(data => {
          const errorItems = data.items.filter(validatedItem => validatedItem.isError);
          const plainErrorData = getParseableString(
            errorItems,
            data.result?.warehouseIdentificationTypeId ?? IdentificationType.CustomerId,
            data.result?.productIdentificationTypeId ?? IdentificationType.CustomerId,
            ';',
          );

          if (!handlingMethod) {
            dispatch(setHandlingMethod(AdministrationItemListHandlingMethod.ADD));
          }

          dispatch(validationFinished({ validation: data, errors: plainErrorData }));
          if (newHandlingMethod) {
            dispatch(setHandlingMethod(newHandlingMethod));
          }
        })
        .catch((error: unknown) => {
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- we know it's the right type
          const err = error as ErrorType;
          dispatch(
            addToast({
              message: `Validation failed: ${err.response.data.Messages.join(', ')}`,
              isError: true,
            }),
          );
        })
        .finally(() => {
          dispatch(setIsLoading(false));
        });
    },

    [
      dispatch,
      handlingMethod,
      productIdentificationType,
      productItemsContent,
      selectedRequestList,
      validation,
      warehouseIdentificationType,
    ],
  );
};

export default useValidateItems;
