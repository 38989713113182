import React, { useCallback, useMemo } from 'react';
import { Button, Modal } from 'antd';
import { useSelector } from 'react-redux';
import { AvailableModals, closeModal } from './modalSlice';
import { ReduxState, useAppDispatch } from '../../store';
import RequestListSkuGrid from '../ProjectAdministration/RequestLists/content/detail/RequestListSkuGrid';
import { styled } from 'styled-components';
import { Link, useParams } from 'react-router';
import { useTemplateOrCalculation } from '../../hooks/useTemplateOrCalculation';
import SectionRequestList from '../CalculationDetail/Statistics/SectionRequestList';

const TableWrapper = styled.div`
  height: 70vh;
`;

const modalId = AvailableModals.REQUEST_LIST_CONTENTS;

const RequestListContentsModal: React.FC = () => {
  const { projectShortName } = useParams();
  const templateOrCalculation = useTemplateOrCalculation();
  const dispatch = useAppDispatch();
  const requestList = useSelector(
    (state: ReduxState) => state.modal.modalData[modalId]?.requestList,
  );

  const handleClose = useCallback(() => dispatch(closeModal()), [dispatch]);

  const footer = useMemo(
    () => (
      <>
        {projectShortName && (
          <Button onClick={handleClose}>
            <Link
              to={`/${projectShortName}/project-administration/requestlists/${requestList?.targetListId}`}
            >
              Open in administration
            </Link>
          </Button>
        )}
        <Button onClick={handleClose}>Close</Button>
      </>
    ),
    [projectShortName, requestList?.targetListId, handleClose],
  );

  if (requestList) {
    return (
      <Modal
        title={`Request list - ${requestList.name ?? 'undefined'}`}
        centered
        open={true}
        width="80%"
        onCancel={handleClose}
        footer={footer}
      >
        {templateOrCalculation.type === 'Template' && (
          <TableWrapper>
            <RequestListSkuGrid selectedRequestListId={requestList.targetListId} />
          </TableWrapper>
        )}
        {templateOrCalculation.type === 'Calculation' && <SectionRequestList />}
      </Modal>
    );
  }
};

export default RequestListContentsModal;
