import React, { useEffect } from 'react';
import { Section } from '@ydistri/ds';
import { useSelector } from 'react-redux';
import { ReduxState, useAppDispatch } from '../../../../../store';
import EntityListSteps from '../steps/EntityListSteps';
import { setVerificationTimeStamp } from '../../entityListAdministrationSlice';
import { EntityListTypeSupported } from '../../entityListsLib';

interface EntityListEditModeSectionProps {
  entityListType: EntityListTypeSupported;
}

const EntityListEditModeSection: React.FC<EntityListEditModeSectionProps> = ({
  entityListType,
}) => {
  const dispatch = useAppDispatch();

  const selectedEntityList = useSelector(
    (state: ReduxState) => state.entityListsAdministration[entityListType].selectedEntityList,
  );

  useEffect(() => {
    dispatch(
      setVerificationTimeStamp({
        entityListType,
        data: selectedEntityList?.entityListImport?.verificationTimeStamp,
      }),
    );
  }, [dispatch, entityListType, selectedEntityList?.entityListImport?.verificationTimeStamp]);

  if (selectedEntityList) {
    return (
      <Section header={selectedEntityList.name}>
        <EntityListSteps entityListType={entityListType} />
      </Section>
    );
  }
};

export default EntityListEditModeSection;
